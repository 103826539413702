import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useConnection, useUserInfo } from '../../connection/Application';
import { Grid } from 'antd';
import Router from 'next/router';

export type SearchQuery = {
  from?: number;
  size?: number;
  search?: string;
};
export type LeaderBoardUserData = {
  userImage: string;
  points: number;
  userId: string;
  userName: string;
  percentage: number;
};

export type RedemptionHistoryData = {
  date: string;
  prize: string;
  pointsUsed: number;
};

export type MyReferralStatus = 'Done' | 'Reminded' | 'Remind';

export type LeaderBoardResponseData = {
  users: LeaderBoardUserData[];
  redemptionHistory: RedemptionHistoryData[];
  shareUrl: string;
  total: number;
  from: number;
  points: number;
  profileComplete: boolean;
  mobileVerified: boolean;
  addText: string;
  addImage: string;
  myReferrals: {
    userId: string;
    userName: string;
    mobileVerified: boolean;
    profileCompleted: boolean;
    signup: boolean;
    status: MyReferralStatus;
  }[];
};

export type LeaderBoardContextData = {
  type: string;
  setType: (type: string) => void;
  results?: LeaderBoardResponseData;
  setResults: (results?: LeaderBoardResponseData) => void;
  selected?: LeaderBoardUserData;
  setSelected: (selected?: LeaderBoardUserData) => void;
  query: SearchQuery;
  setQuery: (query: SearchQuery) => void;
  search: (searchQuery?: SearchQuery, reset?: boolean) => Promise<void>;
  loading: boolean;
  changeMyReferralStatus: (userId: string, status: MyReferralStatus) => void;
};

const data1 = [
  {
    date: '07/06/2021',
    prize: '2021 January Gift - Iphone 11',
    pointsUsed: 50000,
  },
  {
    date: '07/03/2021',
    prize: '2021 December Gift - Iphone 11',
    pointsUsed: 50000,
  },
];

export const LeaderBoardContext = React.createContext<LeaderBoardContextData>({} as any);

export type Props = { results: LeaderBoardResponseData; userId: string };

export const LeaderBoardContextProvider: FC<PropsWithChildren<Props>> = (props) => {
  const [type, setType] = useState<string>('jobs');
  const user = useUserInfo();
  const [results, setResults] = useState<LeaderBoardResponseData | undefined>(props.results);
  const bp = Grid.useBreakpoint();
  const [selected, setSelected] = useState<LeaderBoardUserData | undefined>(
    props.results.users.find((e: any) => e.userId === props.userId) ?? undefined,
  );
  const [query, setQuery] = useState<SearchQuery>({ from: props.results.from });
  const [loading, setLoading] = useState<boolean>(false);
  const connection = useConnection();

  const search = useCallback(
    async (searchQuery?: SearchQuery, reset?: boolean) => {
      // if (searchQuery && searchQuery.from === undefined) {
      //   searchQuery.from = 0;
      // }
      const newQuery: any = reset ? {} : { ...query };
      if (searchQuery) {
        for (const entry of Object.entries(searchQuery)) {
          newQuery[entry[0]] = entry[1];
        }
      }
      // setQuery(newQuery);
      setLoading(true);
      if (!bp.lg) {
        setSelected(undefined);
      }
      if (user?.userId) {
        await connection
          .get(`profiles/${user?.userId}/leader-board`, newQuery)
          .then((res) => {
            setResults(res);
            setSelected(res.users.find((e: any) => e.userId === user?.userId) ?? undefined);
            setQuery({ from: res.from });
          })
          .finally(() => setLoading(false));
      } else {
        Router.push({
          pathname: `login`,
        });
      }
    },
    [bp.lg, connection, query, user?.userId],
  );

  useEffect(() => {
    if (bp.lg && selected === undefined && (results?.users ?? []).length > 0) {
      setSelected(results!.users.find((e: any) => e.userId === user?.userId) ?? undefined);
    }
  }, [bp.lg, results, selected, user?.userId]);

  const changeMyReferralStatus = useCallback(
    (userId: string, status: MyReferralStatus) => {
      if (results) {
        const res: LeaderBoardResponseData = {
          ...results,
          myReferrals: results.myReferrals.map((m) => (m.userId === userId ? { ...m, status } : m)),
        };
        setResults(res);
      }
    },
    [results],
  );

  return (
    <LeaderBoardContext.Provider
      value={{
        type,
        setType,
        results,
        setResults,
        selected,
        setSelected,
        query,
        setQuery,
        search,
        loading,
        changeMyReferralStatus,
      }}>
      {props.children}
    </LeaderBoardContext.Provider>
  );
};

export const useLeaderBoardContext = () => useContext(LeaderBoardContext);
