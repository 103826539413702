/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import stylesModule from './CandidateLayout.module.less';
import styles1Module from './../recruiter/RecruiterLayout.module.less';
import CommonStylesModule from '../../styles/Common.module.less';
import Logo from '../../public/logo.svg';
import Image from 'next/image';
import { Avatar, Button, Divider, Dropdown, Grid, Menu, Select, Space } from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  MenuOutlined,
  PlusOutlined,
  RightOutlined,
  UpOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Utils from '../../utils/Utils';
import Router, { useRouter } from 'next/router';
import { useConnection, useUserInfo } from '../../connection/Application';
import ChangePasswordModal, {
  ChangePasswordModalInstance,
} from '../../page-components/UserManagement/ChangePasswordModal';
import { ContentBlock, TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';
import { FlexBox } from '../../components/FlexBox';
import { useRecruiter } from '../../page-components/Recruiiter/RecruiterContext';
import { NotificationButton } from '../../components/NotificationButton/NotificationButton';
import Link from 'next/link';
import { SearchAutocomplete } from '../../page-components/search/components/SearchInputBar';
import QuickProfileBuildModal, {
  QuickProfileBuildInstance,
} from '../../components/OneStep/QuickProfileBuildModal';
import { useCookies } from 'react-cookie';
import signInIcon from '../../public/user-sign-in.svg';
import { useCachedData } from '../../connection/CacheContext';
import { useSearchContext } from '../../page-components/search/SearchContext';
import LogoLite from '../../public/recruiter/become-recruiter.svg';
const styles: any = stylesModule;
const styles1: any = styles1Module;
const CommonStyles: any = CommonStylesModule;

type Props = {
  showSearch?: boolean;
  showBanner?: boolean;
  bordered: boolean;
  hideRecruiterButton?: boolean;
  showLogin: boolean;
  onShowLogin: () => void;
};

export const UserMenu: FC<{
  onClick: () => void;
  hideRecruiterButton?: boolean;
  onClickQuick?: () => void;
  onClickForeignJobs?: () => void;
}> = (props) => {
  const connection = useConnection();
  const history = useRouter();
  const user = useUserInfo();
  const role = user?.role;
  const pwModal = useRef<ChangePasswordModalInstance>(null);
  const recruiters = useRecruiter()?.recruiters;
  const bp = Grid.useBreakpoint();

  const recruiterText = useMemo(
    () => (role && role.indexOf('recruiter') !== -1 ? 'Recruiter dashboard' : 'Become a recruiter'),
    [role],
  );

  return (
    <>
      <Menu
        className={styles.userMenu}
        id={'navbar_user_menu'}
        selectedKeys={[]}
        style={{ minWidth: '250px', overflow: 'none' }}>
        {!recruiters && (
          <Menu.Item
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            onClick={() => {
              history.push(`/profile/${user?.userId}`);
              props.onClick();
            }}
            style={{ minWidth: '100%' }}>
            My profile
          </Menu.Item>
        )}
        {recruiters && (
          <Menu.Item
            id={'navbar-user-profile-icon'}
            onClick={() => {
              history.push(`/profile/${user?.userId}`);
              props.onClick();
            }}
            style={{ minWidth: '100%' }}>
            <>
              <ContentBlock
                style={{ marginTop: 4 }}
                align={'center'}
                left={
                  <Avatar icon={user?.picture ? undefined : <UserOutlined />} src={user?.picture} />
                }>
                <ContentBlock
                  style={{ maxWidth: 300, whiteSpace: 'nowrap' }}
                  ellipsis={true}
                  align="left">
                  {`${user?.firstName} ${user?.lastName?.charAt(0) || ''}`}
                </ContentBlock>
              </ContentBlock>
            </>
          </Menu.Item>
        )}
        {!recruiters && user?.userId && (
          <Menu.Item
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            onClick={() => {
              history.push('/dashboard');
            }}
            style={{ minWidth: '100%' }}>
            My dashboard
          </Menu.Item>
        )}
        {!recruiters && (
          <Menu.Item
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            onClick={() => {
              if (recruiterText === 'Recruiter dashboard') {
                history.push('/recruiter');
              } else {
                history.push('/guest-recruiter');
              }
            }}
            style={{
              minWidth: '100%',
              color: recruiterText === 'Become a recruiter' ? '#009E52' : undefined,
            }}>
            <div
              style={{ color: recruiterText === 'Become a recruiter' ? '#009E52' : undefined }}
              className={'flex item-center gap-[7px]'}>
              {recruiterText}
              {recruiterText === 'Become a recruiter' && (
                <Image
                  style={{ marginTop: 1 }}
                  src={LogoLite}
                  width={19}
                  height={19}
                  alt={'Careers360 Logo'}
                  id={'recruiter-c360-logo'}
                />
              )}
            </div>
          </Menu.Item>
        )}
        {!recruiters && user?.quick && !bp.lg && (
          <Menu.Item
            onClick={() => props.onClickQuick?.()}
            className={styles.profileItemQuick}
            id={'navbar-user-profile-icon'}
            style={{ minWidth: '100%' }}>
            Quick Profile Build
          </Menu.Item>
        )}
        {!recruiters && !bp.lg && (
          <Menu.Item
            onClick={() => props.onClickForeignJobs?.()}
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            style={{ minWidth: '100%' }}>
            Foreign Job
          </Menu.Item>
        )}
        {!recruiters && !bp.lg && (
          <Menu.Item
            onClick={() => {
              props.onClick?.();
              window.open('https://blog.careers360.lk');
            }}
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            style={{ minWidth: '100%' }}>
            Career Advice
          </Menu.Item>
        )}
        {!recruiters && !bp.lg && (
          <Menu.Item
            onClick={() => {
              props.onClick?.();
              history.push('/profile');
            }}
            className={styles.profileItem1}
            id={'navbar-user-profile-icon'}
            style={{ minWidth: '100%' }}>
            Upload/Build CV
          </Menu.Item>
        )}
        {!recruiters && user && !user.social && (
          <Menu.Item
            onClick={() => {
              props.onClick();
              pwModal.current?.open();
            }}
            style={{ minWidth: '100%' }}>
            Change password
          </Menu.Item>
        )}
        <Divider />
        {recruiters && (
          <div style={{ maxHeight: '42vh', overflow: 'scroll' }}>
            <Menu className={styles.recruiterList}>
              {recruiters.map((item) => (
                <>
                  <Menu.Item
                    className={styles.recruiterListItem}
                    key={item.name}
                    onClick={() => {
                      props.onClick();
                      let part = window.location.pathname.split('/').slice(3).join('/');
                      if (part === 'jobs') {
                        part = 'jobs/All';
                      }
                      history.replace(`/recruiter/${item.recruiterId}/${part}`).then();
                    }}
                    style={{ minHeight: '48px', minWidth: '300px' }}>
                    <ContentBlock
                      style={{ marginTop: 4 }}
                      align={'center'}
                      left={
                        <Avatar
                          size={40}
                          icon={item?.logo ? undefined : <UserOutlined style={{ fontSize: 20 }} />}
                          src={item?.logo}
                        />
                      }>
                      <ContentBlock
                        style={{ maxWidth: 300, whiteSpace: 'nowrap' }}
                        className={styles.recruiterNameItem}
                        ellipsis={true}
                        align="left">
                        {item.name}
                      </ContentBlock>
                    </ContentBlock>
                  </Menu.Item>
                  <hr style={{ marginTop: 0, maxWidth: 300, marginBottom: 0 }} />
                </>
              ))}
            </Menu>
            <div style={{ paddingTop: '15px' }} />
          </div>
        )}
        {recruiters && user?.userId && (
          <Menu.Item
            onClick={() => {
              history.push('/dashboard');
            }}
            style={{ minWidth: '100%' }}>
            My dashboard
          </Menu.Item>
        )}
        {recruiters && role && role.indexOf('recruiter') !== -1 && (
          <Menu.Item
            onClick={() => {
              history.push('/recruiter');
            }}
            style={{ minWidth: '100%' }}>
            Recruiter dashboard
          </Menu.Item>
        )}
        {recruiters && user && !user.social && (
          <Menu.Item
            onClick={() => {
              props.onClick();
              pwModal.current?.open();
            }}
            style={{ minWidth: '100%' }}>
            Change password
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            connection.setToken(undefined);
            history.replace('/');
            props.onClick();
          }}
          style={{ minWidth: '100%' }}>
          Sign out
        </Menu.Item>
      </Menu>
      <ChangePasswordModal ref={pwModal} />
    </>
  );
};

export const NavbarUserInfo: FC<{
  theme?: string;
  hideRecruiterButton?: boolean;
  onClickQuick?: () => void;
  onClickForeignJobs?: () => void;
}> = (props) => {
  const user = useUserInfo();
  const recruiter = useRecruiter()?.recruiter;
  const bp = Grid.useBreakpoint();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  return (
    <div className={styles.navbarUserInfo}>
      <ContentBlock
        align={'center'}
        left={<NotificationButton darkMode={props.theme === 'dark'} />}
        right={
          <Dropdown
            trigger={['click']}
            open={dropdownVisible}
            placement={'bottomRight'}
            onOpenChange={setDropdownVisible}
            overlayClassName={styles.userPopover}
            overlay={
              <UserMenu
                onClick={() => setDropdownVisible(false)}
                onClickQuick={() => {
                  setDropdownVisible(false);
                  props.onClickQuick?.();
                }}
                onClickForeignJobs={() => {
                  setDropdownVisible(false);
                  props.onClickForeignJobs?.();
                }}
              />
            }>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: props.theme === 'dark' ? 'white' : 'black',
              }}>
              {/*<CaretDownOutlined id={'recruiter-dropdown-menu'} />*/}
              <i
                className="icon-chevron-down-1 text-[26px]"
                style={{ color: props.theme === 'dark' ? '#FFFFFF' : '#697281' }}
              />
            </div>
          </Dropdown>
        }>
        <Space
          style={{ textDecoration: 'none' }}
          id={'nav-link-user-name'}
          onClick={() => setDropdownVisible(true)}>
          <ContentBlock
            align={'center'}
            left={
              <Avatar icon={user?.picture ? undefined : <UserOutlined />} src={user?.picture} />
            }>
            <FlexBox direction={'vertical'} verticalAlign={'center'} style={{ overflow: 'hidden' }}>
              {!bp.xs && (
                <TextBlock
                  className={Utils.mergeClasses({
                    [styles.userInfo]: true,
                    [styles.userNameX]: props.theme !== 'dark',
                  })}
                  style={{ color: props.theme === 'dark' ? 'white' : 'black' }}>
                  {`${user?.firstName} ${user?.lastName?.charAt(0) || ''}`}
                </TextBlock>
              )}
              <TextBlock
                className={styles1.itemSubName}
                style={{
                  color: props.theme === 'dark' ? 'white' : 'black',
                }}>
                {recruiter?.name}
              </TextBlock>
            </FlexBox>
            {/*<FlexBox direction={'vertical'} verticalAlign={'center'} style={{ overflow: 'hidden' }}>*/}
            {/*  <TextBlock*/}
            {/*    className={styles.userInfo}*/}
            {/*    style={{ color: props.theme === 'dark' ? 'white' : 'black' }}>*/}
            {/*    {`${user?.firstName} ${user?.lastName.charAt(0)}`}*/}
            {/*  </TextBlock>*/}
            {/*  <TextBlock*/}
            {/*    className={styles1.itemSubName}*/}
            {/*    style={{*/}
            {/*      color: props.theme === 'dark' ? 'white' : 'black',*/}
            {/*    }}>*/}
            {/*    {recruiter?.name}*/}
            {/*  </TextBlock>*/}
            {/*</FlexBox>*/}
          </ContentBlock>
        </Space>
      </ContentBlock>
    </div>
  );
};

export const CandidateNavBar: FC<PropsWithChildren<Props>> = (props) => {
  const navbarClasses = [styles.navbar];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookies] = useCookies(['banner-closed']);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  // const [showLogin, setShowLogin] = useState<boolean>(true);
  const [searchType, setSearchType] = useState<string>('jobs');
  const history = useRouter();
  const router = useRouter();
  const user = useUserInfo();
  const role = user?.role;
  const search = useSearchContext();
  const bp = Grid.useBreakpoint();
  const quick = useRef<QuickProfileBuildInstance>(null);
  const [servicesOpen, setServicesOpen] = useState<boolean>(false);
  const { masterdata, mainType } = useCachedData();

  const foreignJobs = useMemo(() => {
    const typeId = mainType['Country'];
    const countries = masterdata[typeId] ?? [];

    const countriesX = countries
      .filter((v) => v.value !== 'Sri Lanka')
      .map((t) => `&country=${t.id}`)
      .join('');

    return `/jobs?results=show${countriesX}`;
  }, [mainType, masterdata]);

  const foriegnJobX = useMemo(() => {
    const typeId = mainType['Country'];
    const countries = masterdata[typeId] ?? [];

    return countries.filter((v) => v.value !== 'Sri Lanka').map((t) => `${t.id}`);
  }, [mainType, masterdata]);

  if (props.bordered) {
    navbarClasses.push(styles.navbarScrolled);
  }
  const postJobText = useMemo(
    () =>
      role && role.indexOf('recruiter') !== -1 ? 'Recruiter Dashboard' : 'Start Recruiting Today',
    [role],
  );
  const menu = () => {
    return (
      <Menu>
        {/*{user?.userId && history.pathname !== '/' ? (*/}
        {/*{user?.userId && (*/}
        {/*  <Menu.Item>*/}
        {/*    <div className={styles.hamburgerItem}>*/}
        {/*      <Link href={'/dashboard'}>*/}
        {/*        <Button size={'small'} style={{ width: '100%' }} type="primary">*/}
        {/*          My Dashboard*/}
        {/*        </Button>*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </Menu.Item>*/}
        {/*)}*/}
        {/*// ) : (*/}
        {/*//   ''*/}
        {/* // )}*/}
        {user?.quick && (
          <Menu.Item>
            <div className={styles.hamburgerItem}>
              <>
                <Button
                  onClick={() => quick.current?.show()}
                  size={'small'}
                  style={{ width: '100%' }}
                  className={CommonStyles.borderedPrimaryButton}>
                  Quick Profile Build
                </Button>
                {/*<QuickProfileBuildModal ref={quick} />*/}
              </>
            </div>
          </Menu.Item>
        )}
        <Menu.Item>
          <div className={styles.hamburgerItem}>
            <Link
              className="text-black"
              href={foreignJobs}
              onClick={() => search.search({ country: foriegnJobX }, true)}>
              Foreign Job
            </Link>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className={styles.hamburgerItem}>
            <Link className="text-black" href={'https://blog.careers360.lk'}>
              Career Advice
            </Link>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className={styles.hamburgerItem}>
            <Link className="text-black" href={`/profile`}>
              Upload/Build CV
            </Link>
          </div>
        </Menu.Item>
        {/*<Menu.Item>*/}
        {/*  <div className={styles.hamburgerItem}>*/}
        {/*    <a href={'https://blog.careers360.lk'}>*/}
        {/*      <Button*/}
        {/*        size={'small'}*/}
        {/*        style={{ width: '100%' }}*/}
        {/*        id={'nav-m-btn-careers-blog'}*/}
        {/*        className={CommonStyles.borderedPrimaryButton}>*/}
        {/*        Careers Blog*/}
        {/*      </Button>*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</Menu.Item>*/}
        {user?.agentId && (
          <Link href={'/field-agent/dashboard'}>
            <a>
              <Button size={'small'} className={CommonStyles.borderedPrimaryButton}>
                Field Agent
              </Button>
            </a>
          </Link>
        )}
        <Menu.Item>
          <div className={styles.hamburgerItem}>
            <Link
              href={postJobText === 'Recruiter Dashboard' ? '/recruiter' : '/guest-recruiter'}
              className="gtm-track">
              {postJobText === 'Recruiter Dashboard' ? (
                <Button
                  size={'small'}
                  style={{
                    width: '100%',
                    color: 'white',
                  }}
                  id={'nav-m-btn-recruiter-dashbord'}
                  className={CommonStyles.borderedPrimaryButtonBlack}
                  icon={postJobText === 'Recruiter Dashboard' ? undefined : <PlusOutlined />}>
                  {postJobText}
                </Button>
              ) : (
                <Button
                  size={'small'}
                  style={{
                    width: '100%',
                  }}
                  id={'nav-m-btn-start-recruiting'}
                  className={CommonStyles.borderedPrimaryButton}
                  icon={<PlusOutlined />}>
                  {postJobText}
                </Button>
              )}
            </Link>
          </div>
        </Menu.Item>
        <Menu.Item>
          {!user && (
            <div className={styles.hamburgerItem}>
              <Link href={'/sign-up'} className="gtm-track">
                <Button
                  size={'small'}
                  style={{ width: '100%' }}
                  type={'primary'}
                  id={'nav-m-btn-sign-up'}>
                  Sign up free
                </Button>
              </Link>
            </div>
          )}
        </Menu.Item>
        <Menu.Item>
          {!user && (
            <div className={styles.hamburgerItem}>
              <Link href={'/login'} className="gtm-track">
                <Button size={'small'} style={{ width: '100%' }} id={'nav-m-btn-sign-in'}>
                  Sign in
                </Button>
              </Link>
            </div>
          )}
        </Menu.Item>
      </Menu>
    );
  };
  const query = router?.query;

  useEffect(() => {
    if (query.quick === 'true') {
      delete query?.quick;
      Router.push({
        query: {
          ...query,
        },
      });
      quick.current?.show();
    }
  }, [query.quick]);

  return (
    <nav className={Utils.mergeClasses(...navbarClasses)}>
      <>
        {showBanner && (
          <div className={styles.navbarBanner}>
            <div className={styles.navbarBannerContent}>
              <div className="grow pl-4">
                REFER a friend and WIN a brand new iPhone 13 Pro Max!{' '}
                <Link href={'/leaderboard'} className="font-bold underline">
                  Click here
                </Link>{' '}
                for more details
              </div>
              <Button
                type={'link'}
                className={`text-white font-bold text-lg ${styles.navbarBannerButton}`}
                onClick={() => {
                  setCookies('banner-closed', 'yes');
                }}>
                <CloseOutlined />
              </Button>
            </div>
          </div>
        )}
        <div className={styles.navbarContent}>
          <Link href={'/'} style={{ marginRight: 8 }} id={'nav-link-home'}>
            <Image
              className={styles.logo}
              src={Logo}
              alt={'Careers360 Logo'}
              width={185}
              height={43}
            />
          </Link>
          <div className={`flex grow shrink gap-4 ${user?.quick ? 'lg:gap-4' : 'lg:gap-8'}`}>
            <Link
              className="text-[#5B6272] no-underline hover:text-primaryLight"
              href={foreignJobs}
              onClick={() => search.search({ country: foriegnJobX }, true)}>
              Foreign Jobs
            </Link>
            <Link
              className="text-[#5B6272] no-underline hover:text-primaryLight"
              href={'https://blog.careers360.lk'}>
              Career Advice
            </Link>
            <Dropdown
              overlayClassName={styles.navDropdown}
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <Link className={'text-[#5B6272] no-underline'} href={`/profile`}>
                        Upload/Build CV
                      </Link>
                    ),
                  },
                ],
              }}
              placement="bottomLeft"
              open={servicesOpen}
              onOpenChange={setServicesOpen}
              trigger={['click']}>
              <div className=" cursor-pointer text-[#5B6272] no-underline mr-7 hover:text-primaryLight">
                Services {servicesOpen ? <UpOutlined /> : <DownOutlined />}
              </div>
            </Dropdown>
          </div>
          <div className={styles.navbarButtons}>
            {user?.quick && bp.lg && (
              <>
                <a>
                  <Button
                    onClick={() => quick.current?.show()}
                    size={'small'}
                    className={CommonStyles.borderedPrimaryButton}>
                    Quick Profile Build
                  </Button>
                </a>
              </>
            )}
            {/*<div>*/}
            {/*  {user?.userId ? (*/}
            {/*    <Link href={'/dashboard'}>*/}
            {/*      <Button*/}
            {/*        size={'small'}*/}
            {/*        className={CommonStyles.borderedPrimaryButtonGreen}*/}
            {/*        id={'nav-btn-my-dashboard'}*/}
            {/*        type="primary">*/}
            {/*        My Dashboard*/}
            {/*      </Button>*/}
            {/*    </Link>*/}
            {/*  ) : null}*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<a href={'https://blog.careers360.lk'}>*/}
            {/*  <Button*/}
            {/*    size={'small'}*/}
            {/*    className={CommonStyles.borderedPrimaryButtonGreenBlock}*/}
            {/*    id={'nav-btn-careers-blog'}>*/}
            {/*    Careers Blog*/}
            {/*  </Button>*/}
            {/*</a>*/}
            {/*</div>*/}

            {user?.agentId && bp.lg && (
              <Link href={'/field-agent/dashboard'}>
                <Button size={'small'} className={CommonStyles.borderedPrimaryButton}>
                  Field Agent
                </Button>
              </Link>
            )}
            {!props.hideRecruiterButton && !user?.userId && (
              <Link
                href={postJobText === 'Recruiter Dashboard' ? '/recruiter' : '/guest-recruiter'}
                className="gtm-track">
                <Button
                  size={'small'}
                  className={CommonStyles.borderedPrimaryButton}
                  icon={<PlusOutlined />}
                  id={'nav-btn-start-recruiting'}
                  // icon={postJobText === 'Recruiter Dashboard' ? undefined : <PlusOutlined/>}
                >
                  {postJobText}
                </Button>
              </Link>
            )}
            {!user && (
              <Link href={'/sign-up'} className="gtm-track">
                <Button size={'small'} type={'primary'} id={'nav-btn-sign-up'}>
                  Sign up free
                </Button>
              </Link>
            )}
            {!user && (
              <Link href={'/login'} className="gtm-track">
                <Button
                  className="hover:bg-black hover:!text-white hover:!border-black"
                  size={'small'}
                  id={'nav-btn-sign-in'}>
                  Sign in
                </Button>
              </Link>
            )}
            {/*</div>*/}
            {user && (
              <NavbarUserInfo
                hideRecruiterButton={props.hideRecruiterButton}
                onClickQuick={() => {
                  quick?.current?.show();
                }}
                onClickForeignJobs={() => {
                  history.push(foreignJobs);
                }}
              />
            )}
          </div>
        </div>
        {props.showSearch && (
          <div className={styles.navbarContent}>
            {bp.md && (
              <Select
                value={searchType}
                onChange={(value) => setSearchType(value)}
                className={styles.searchInputBarSelect}
                options={[
                  { label: 'Jobs', value: 'jobs' },
                  // { label: 'Courses', value: 'courses' },
                ]}
                getPopupContainer={(props1) => props1.parentNode}
              />
            )}
            <SearchAutocomplete
              className={styles.searchInputBarSearch}
              showButton={true}
              triggerOnEnter={true}
              getPopupContainer={(props1) => props1.parentNode}
              onChange={(val) =>
                history.push({ pathname: '/jobs', query: { search: val, results: 'show' } })
              }
            />
          </div>
        )}
        <div className={styles.navbarContentMobile}>
          <div className={styles.navbarSubContentMobile}>
            <div>
              <Link href={'/'} style={{ marginRight: 8 }}>
                <Image
                  className={props.showLogin ? styles.logoX : styles.logo}
                  src={Logo}
                  alt={'Careers360 Logo'}
                />
              </Link>
            </div>
            <div className={styles.bellContainer}>
              {!user && (
                <div className={styles.signinShowIconContainer}>
                  <Button
                    className={props.showLogin ? styles.signinHideIcon : styles.signinShowIcon}
                    onClick={props.onShowLogin}>
                    <Image alt="Sign in" src={signInIcon} height={30} />
                  </Button>
                </div>
              )}
              {/*{bp.md && user && <NotificationButton />}*/}
              {user && (
                <div style={{ alignItems: 'center' }} className={styles.navbarRow}>
                  {/*<div>{bp.xs && <NotificationButton />}</div>*/}
                  <NavbarUserInfo
                    hideRecruiterButton={props.hideRecruiterButton}
                    onClickQuick={() => {
                      quick?.current?.show();
                    }}
                    onClickForeignJobs={() => {
                      history.push(foreignJobs);
                    }}
                  />
                </div>
              )}
              {!user?.userId && (
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  destroyPopupOnHide={true}
                  placement="bottomRight">
                  <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <Button type={'primary'} className={styles.hamburgerBtn}>
                      {' '}
                      <MenuOutlined />{' '}
                    </Button>
                  </a>
                </Dropdown>
              )}
            </div>
          </div>

          {props.showLogin && (
            <div className={styles.signupButtonShortcut}>
              <Link href={'/login'} className="gtm-track">
                <Button className={Utils.mergeClasses(styles.loginOptionBtn, 'mr-4')}>
                  Sign in
                </Button>
              </Link>
              <Link href={'/sign-up'} className="gtm-track">
                <Button className={styles.loginOptionBtn} type={'primary'}>
                  Sign up free <RightOutlined />
                </Button>
              </Link>
            </div>
          )}

          {props.showSearch && (
            <div className={styles.navbarRow} style={{ marginTop: 8 }}>
              {bp.md && (
                <Select
                  value={searchType}
                  onChange={(value) => setSearchType(value)}
                  className={styles.searchInputBarSelect}
                  options={[
                    { label: 'Jobs', value: 'jobs' },
                    // { label: 'Courses', value: 'courses' },
                  ]}
                  getPopupContainer={(props1) => props1.parentNode}
                />
              )}
              <SearchAutocomplete
                className={styles.searchInputBarSearch}
                showButton={true}
                triggerOnEnter={true}
                getPopupContainer={(props1) => props1.parentNode}
                onChange={(val) =>
                  history.push({ pathname: '/jobs', query: { search: val, results: 'show' } })
                }
              />
            </div>
          )}
        </div>
        <QuickProfileBuildModal ref={quick} />
      </>
      {props.children}
    </nav>
  );
};
