import React, { FC, useCallback, useState } from 'react';
import { Badge, Button, Grid } from 'antd';
import { useConnection, useNotificationInfo } from '../../connection/Application';
import { BellOutlined } from '@ant-design/icons';
import styles from './notificationButton.module.less';
import { useQuery } from 'react-query';
import { NotificationDrawer } from '../../page-components/notificationDrawer/NotificationDrawer';

type Props = {
  darkMode?: boolean;
};

export const NotificationButton: FC<Props> = (props) => {
  const notificationInfo = useNotificationInfo();
  const connection = useConnection();
  const bp = Grid.useBreakpoint();
  const [visible, setVisible] = useState<boolean>(false);

  useQuery('notification-count', () => {
    return connection.get('notification/count').then((response) => {
      if (
        notificationInfo.notificationData.alertCount !== response.count.alertCount ||
        notificationInfo.notificationData.applicationCount !== response.count.applicationCount ||
        notificationInfo.notificationData.notificationCount !== response.count.notificationCount ||
        notificationInfo.notificationData.total !== response.count.total
      ) {
        notificationInfo.setNotificationData(response.count);
      }
    });
  });
  const openNotificationDrawer = useCallback(() => {
    setVisible(true);
  }, []);
  return (
    <>
      <Button
        onClick={openNotificationDrawer}
        type={'link'}
        style={{ padding: 0, height: 32, width: 32 }}
        shape={'circle'}>
        <Badge
          count={
            notificationInfo.notificationData.total >= 0
              ? notificationInfo.notificationData.notificationCount
              : 0
          }
          size={'small'}
          overflowCount={99}>
          {/*<BellOutlined*/}
          {/*  className={props.darkMode ? styles.dark : undefined}*/}
          {/*  style={bp.xs ? { fontSize: 20 } : { fontSize: 16 }}*/}
          {/*/>*/}
          <i
            className="icon-bell text-[18px] text-yellow-950"
            style={{ color: props.darkMode ? '#FFFFFF' : '#344153' }}
          />
        </Badge>
      </Button>
      <NotificationDrawer visible={visible} setVisible={setVisible} />
    </>
  );
};
