import React, { FC } from 'react';
import stylesModule from './dashboard.module.less';
import { Button, Grid, Space } from 'antd';
import moment from 'moment';
import { BellOutlined, ClockCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { ContentBlock, TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import { Logo } from '../../uicomp/atmos/logo/Logo';
import { useRouter } from 'next/router';
import { ProfileCompletionNotifyCard } from './ProfileCompletionNotifyCard';
import { useCachedData } from '../../connection/CacheContext';
import { useConnection, useUserInfo } from '../../connection/Application';
import { useRecruiter } from '../Recruiiter/RecruiterContext';

const styles: any = stylesModule;

export type JobNotificationCardProps = {
  title: string;
  content: string;
  location?: string[];
  time: string;
  type: string;
  read: boolean;
  recruiterImage?: string;
  recruiterName: string;
  buttonText: string;
  buttonType?: 'primary' | 'custom';
  userId: string;
  referralUser?: string;
  recruiterId?: string;
};

type Props = {
  data: JobNotificationCardProps;
  onClick?: () => void;
  onRead?: () => void;
};

export const JobNotificationCard: FC<Props> = (props) => {
  const bp = Grid.useBreakpoint();
  const { enumValue } = useCachedData();
  const history = useRouter();
  const user = useUserInfo();
  const recruiter = useRecruiter();
  const connection = useConnection();

  const {
    location,
    recruiterImage,
    recruiterName,
    type,
    time,
    read,
    userId,
    referralUser,
    recruiterId,
  } = props.data;

  let bg = '';
  if (!read) {
    bg = '#F9F9F9';
  }
  const router = useRouter();
  return (
    <div
      style={{ backgroundColor: bg }}
      className={styles.shortlistCardContainer}
      onClick={props.onRead}>
      <>{console.log('type', type)}</>
      {type === 'eyeball' && (
        <ContentBlock
          onClick={!bp.sm ? props.onClick : undefined}
          className={'fullwidth'}
          left={<Logo src={recruiterImage} />}
          gap={'lg'}>
          <ContentBlock
            align={'center'}
            right={
              <div className={styles.jobCardButtonEmail}>
                {bp.sm && (
                  <Button onClick={props.onClick} size={'small'}>
                    Email Now
                  </Button>
                )}
              </div>
            }>
            <Space className={'fullwidth'} direction={'vertical'} align={'start'}>
              <div>
                <TextBlock heading>{recruiterName}</TextBlock>
                <TextBlock secondary>
                  {Array.isArray(location)
                    ? location?.map((v) => enumValue['Location']?.[v])?.join(' / ')
                    : location
                    ? enumValue?.['Location']?.[location]
                    : ''}
                </TextBlock>
              </div>
              <ContentBlock left={<EyeOutlined className={styles.colorPrimary} />}>
                Has eyeballed you!
              </ContentBlock>
              <ContentBlock secondary left={<ClockCircleOutlined />}>
                <TextSpan primary>{moment(time).fromNow()}</TextSpan>
              </ContentBlock>
              {/* <div className={styles.jobCardButonsMobile}>
              <Button onClick={props.onClick} size={'small'}>
                Email Now
              </Button>
            </div> */}
            </Space>
          </ContentBlock>
        </ContentBlock>
      )}
      {type === 'MOBILE_VERIFICATION' && (
        <ProfileCompletionNotifyCard
          onClick={() => router.push(`/profile/${userId}`)}
          description={`Please verify your mobile number to be a referral profile for ${referralUser}`}
          buttonTitle={'Verify Now'}
          time={time}
          title={'Verify your mobile.'}
        />
      )}
      {type === 'REMINDER' && (
        <ProfileCompletionNotifyCard
          onClick={() => router.push(`/profile/${userId}`)}
          description={`Please complete your profile at least 80% and verify your mobile number to be a referral profile for ${referralUser}.`}
          buttonTitle={'Update profile'}
          time={time}
          title={'Update your profile.'}
        />
      )}
      {type === 'REMINDER_PROFILE_COMPLETION' && (
        <ProfileCompletionNotifyCard
          onClick={() => router.push(`/profile/${userId}`)}
          description={`Please complete your profile at least 80% to be a referral profile for ${referralUser}`}
          buttonTitle={'Update profile'}
          time={time}
          title={'Update your profile.'}
        />
      )}
      {type === 'DEACTIVATE' && (
        <div>
          <ContentBlock
            onClick={() => {
              if (!bp.sm) {
                history.push(`/profile/${user?.userId}`);
              }
            }}
            className={'fullwidth'}
            gap={'lg'}>
            <ContentBlock
              align={'center'}
              right={
                <div className={styles.jobCardButtonEmail}>
                  <Button
                    style={{ color: 'white', backgroundColor: '#009E52', border: 'none' }}
                    onClick={() => {
                      history.push(`/profile/${user?.userId}`);
                    }}
                    size={'small'}>
                    Update profile
                  </Button>
                </div>
              }>
              <Space className={'fullwidth'} direction={'vertical'} align={'start'}>
                <div>
                  <ContentBlock
                    left={
                      <BellOutlined style={{ fontSize: '24px' }} className={styles.colorPrimary} />
                    }>
                    <TextBlock heading>Update Your Profile</TextBlock>
                  </ContentBlock>
                </div>
                <ContentBlock style={{ textAlign: 'start' }}>
                  Your profle is no longer visible to recruiters for eyeballing due to presence of
                  irrelevant or innappropriate profile content
                </ContentBlock>
                <ContentBlock secondary left={<ClockCircleOutlined />}>
                  <TextSpan primary>{moment(time).fromNow()}</TextSpan>
                </ContentBlock>
              </Space>
            </ContentBlock>
          </ContentBlock>
        </div>
      )}
      {type === 'REC_DEACTIVATE' && (
        <>
          <ContentBlock
            onClick={async () => {
              if (!bp.sm) {
                if (!recruiterId) {
                  history.push(`/contactus`);
                } else {
                  history.push(`/recruiter/${recruiterId}/contact-us`);
                }
              }
            }}
            className={'fullwidth'}
            gap={'lg'}>
            <ContentBlock
              align={'center'}
              right={
                <div className={styles.jobCardButtonEmail}>
                  {bp.sm && (
                    <Button
                      style={{ color: 'white', backgroundColor: '#009E52', border: 'none' }}
                      onClick={async () => {
                        if (!recruiterId) {
                          history.push(`/contactus`);
                        } else {
                          history.push(`/recruiter/${recruiterId}/contact-us`);
                        }
                      }}
                      size={'small'}>
                      Contact Us
                    </Button>
                  )}
                </div>
              }>
              <Space className={'fullwidth'} direction={'vertical'} align={'start'}>
                <div>
                  <ContentBlock
                    left={
                      <BellOutlined style={{ fontSize: '24px' }} className={styles.colorPrimary} />
                    }>
                    <TextBlock heading>Please contact support team</TextBlock>
                  </ContentBlock>
                </div>
                <ContentBlock style={{ textAlign: 'start' }}>
                  Your recruiter profile ({recruiterName}) has been flagged as inauthentic due to
                  presence of inaccurate or inappropriate content. Please contact support team
                  within 7 days to prevent recruiter profile deactivation.
                </ContentBlock>
                <ContentBlock secondary left={<ClockCircleOutlined />}>
                  <TextSpan primary>{moment(time).fromNow()}</TextSpan>
                </ContentBlock>
              </Space>
            </ContentBlock>
          </ContentBlock>
        </>
      )}
      {!read && <div className={styles.unread} />}
    </div>
  );
};
