import React, { FC } from 'react';
import { NumberView } from './NumberView';
import styles from '../RecruiterProfile.module.less';
import { Typography } from 'antd';
import { HtmlView } from '../../../components/HtmlTemplate/HtmlView';

type Props = {
  title?: string;
  numberText: string;
  value?: string;
  benifits?: boolean;
};
export const DescListItem: FC<Props> = ({ numberText, title, value, benifits }) => {
  return (
    <div className={styles.rowItem}>
      <div>
        <NumberView text={numberText} />
      </div>
      <div>
        {title && <Typography.Text strong>{title}</Typography.Text>}
        <p style={benifits ? { marginBottom: '36px' } : {}} className={styles.recruiterContent}>
          {value && <HtmlView value={value} />}
        </p>
      </div>
    </div>
  );
};
