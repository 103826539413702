import React, { FC } from 'react';
import { useLeaderBoardContext } from './LeaderBoardContext';
import BannerLarge from '../../public/banner-large.png';
import BannerSmall from '../../public/banner-small.png';
import Image from 'next/image';
import { Grid } from 'antd';
import Link from 'next/link';

export const LeaderBoardAd: FC<{ url?: string }> = (props) => {
  const ctx = useLeaderBoardContext();
  const bp = Grid.useBreakpoint();
  return (
    <Link href={props.url ?? '/referral-terms'}>
      <div className={'w-full flex justify-center cursor-pointer'}>
        <Image
          className={'w-full h-auto'}
          src={bp.sm ? BannerLarge : BannerSmall}
          width={bp.sm ? 1600 : 572}
          height={bp.sm ? 492 : 567}
          alt={'Banner Image'}
        />
      </div>
    </Link>
  );
  // return (
  //   <div
  //     className={styles.homepageContainerGreenX}
  //     style={{ display: 'flex', flexDirection: 'row' }}>
  //     <div className={styles.containerTxtContainer}>
  //       <Title level={1}>THIS MONTH</Title>
  //       <div className={styles.postAjob}>
  //         <Text style={{ color: '#9999A3' }}>
  //           {(ctx.results?.addText ?? '').split('\n').map((x) => (
  //             <>
  //               {x}
  //               <br />
  //             </>
  //           ))}
  //         </Text>
  //       </div>
  //     </div>
  //     <div className={styles.containerTxtContainer}>
  //       <img
  //         alt={'leaderboard-gift'}
  //         src={ctx.results?.addImage ?? `${config.fileStoreUrl}/files/images/leader-board-gift.png`}
  //         height={200}
  //       />
  //     </div>
  //   </div>
};
