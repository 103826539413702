import React, { FC } from 'react';
import { Grid, Space } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { faBriefcase, faCheckCircle, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JobApplyButton } from './JobApplyButton';
import stylesModule from './dashboard.module.less';
import { ContentBlock, TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';
import TieImage from '../../public/home/tie.svg';
import ExperienceImg from '../../public/home/Experiance.svg';
import Image from 'next/image';
import { Logo } from '../../uicomp/atmos/logo/Logo';
import { JobPost } from '../../models/JobPostModals';
import Utils from '../../utils/Utils';
import { useCachedData } from '../../connection/CacheContext';

const styles: any = stylesModule;

export type ShortlistCardProps = JobPost & {
  recruiterName: string;
  applicantCount: number;
  shortlisted?: boolean;
  applied?: boolean;
  applicationState?: string;
  applicationStateColor?: string;
  recruiterImage?: any;
  appliedTime?: string;
  read?: boolean;
};
type Props = {
  data: ShortlistCardProps;
  onApply?: () => void;
  onView?: () => void;
  onWithDraw?: () => void;
  onClick?: () => void;
};

const getFontColor = (backColor: string) => {
  const red = parseInt(backColor.substring(1, 3), 16);
  const green = parseInt(backColor.substring(3, 5), 16);
  const blue = parseInt(backColor.substring(5, 7), 16);
  return red > 0x80 && green > 0x80 && blue > 0x80 ? '#000000' : '#FFFFFF';
};

export const JobShortlistCard: FC<Props> = (props) => {
  const {
    applied,
    jobTitle,
    recruiterName,
    location,
    expireDate,
    applicantCount,
    jobType,
    jobCategory,
    applicationState,
    applicationStateColor,
    recruiterImage,
    appliedTime,
    workplaceType,
    experienceLevel,
    read,
  } = props.data;

  const { enumValue } = useCachedData();

  const bp = Grid.useBreakpoint();
  const locationX = location.map((value) => enumValue?.['Location']?.[value]);

  return (
    <div
      tabIndex={-1}
      onKeyPress={() => console.log('kp')}
      role={'link'}
      className={Utils.mergeClasses({
        [styles.shortlistCardContainer]: true,
        [styles.shortlistCardContainerUnread]: read === false,
      })}
      onClick={props.onClick}>
      <ContentBlock
        vertical={!bp.sm}
        gap={'lg'}
        className={'fullwidth'}
        left={<Logo src={recruiterImage} />}>
        <Space
          className={Utils.mergeClasses('fullwidth', styles.mainContent)}
          direction={'vertical'}
          align={'baseline'}>
          {/*<TextBlock heading>{jobTitle}</TextBlock>*/}
          <ContentBlock className={'fullwidth'} heading>
            {jobTitle}
          </ContentBlock>
          <ContentBlock
            className={'fullwidth'}
            align={'top'}
            gap={'lg'}
            right={
              bp.md && (
                <JobApplyButton
                  jobPostId={props.data.jobPostId ?? ''}
                  applied={applied}
                  expired={moment().diff(moment(expireDate).add(1, 'd')) < 0 ? undefined : true}
                  onApply={props.onApply}
                  onView={props.onView}
                  onWithdraw={props.onWithDraw}
                  receiveApplicationsUrl={
                    props.data.receiveApplications === 'external'
                      ? props.data.receiveApplicationsUrl
                      : undefined
                  }
                />
              )
            }>
            <TextBlock level={1}>{recruiterName}</TextBlock>
            <TextBlock secondary>
              {Array.isArray(locationX) ? locationX?.join(' / ') : locationX}
            </TextBlock>
          </ContentBlock>
          <Space direction={bp.lg ? 'horizontal' : 'vertical'}>
            <ContentBlock className={styles.contentLeft} danger left={<ClockCircleOutlined />}>{`${
              moment().diff(moment(expireDate).add(1, 'd')) < 0 ? 'Expires' : 'Expired'
            } ${moment(expireDate).add(1, 'd').fromNow()}`}</ContentBlock>
            <ContentBlock
              className={styles.contentLeft}
              secondary
              left={<FontAwesomeIcon icon={faBriefcase} />}>
              {jobType.map((value) => enumValue?.['Job Types']?.[value]).join(' / ')}
            </ContentBlock>
            {workplaceType && (
              <ContentBlock secondary left={<FontAwesomeIcon icon={faLaptopHouse} />}>
                {enumValue?.['Workplace Type']?.[workplaceType]}
              </ContentBlock>
            )}
          </Space>
          <Space direction={bp.lg ? 'horizontal' : 'vertical'}>
            <ContentBlock
              className={styles.contentLeft}
              secondary
              left={
                <Image alt="applied" src={TieImage} width={20} height={20} />
              }>{`${applicantCount} Applied`}</ContentBlock>
            <ContentBlock className={styles.contentLeft} secondary left={<FolderOpenOutlined />}>
              {jobCategory.map((value) => enumValue?.['Job Category']?.[value]).join(' / ')}
            </ContentBlock>
            {experienceLevel && (
              <ContentBlock
                className={styles.contentLeft}
                secondary
                left={
                  <Image
                    alt="applied"
                    src={ExperienceImg}
                    width={16}
                    height={16}
                    style={{ marginTop: 2 }}
                  />
                }>
                {enumValue?.['Experience Level']?.[experienceLevel]}
              </ContentBlock>
            )}
          </Space>
          {applied && (
            <ContentBlock
              secondary
              left={
                <FontAwesomeIcon icon={faCheckCircle} className={styles.appliedDateIcon} />
              }>{`Applied on: ${moment(appliedTime).format('MMM DD, YYYY')}`}</ContentBlock>
          )}
          {!bp.md && (
            <JobApplyButton
              jobPostId={props.data.jobPostId ?? ''}
              applied={applied}
              expired={moment().diff(moment(expireDate).add(1, 'd')) < 0 ? undefined : true}
              onApply={props.onApply}
              onView={props.onView}
              onWithdraw={props.onWithDraw}
              receiveApplicationsUrl={
                props.data.receiveApplications === 'external'
                  ? props.data.receiveApplicationsUrl
                  : undefined
              }
            />
          )}
        </Space>
      </ContentBlock>
      <Space direction={'vertical'} align={'end'}>
        <p>
          Ref. No{' '}
          {isNaN(Number(props.data.jobPostId))
            ? props?.data?.jobPostId?.substr(props?.data?.jobPostId?.length - 4).padStart(8, '0')
            : String(props.data.jobPostId).padStart(8, '0')}
        </p>
      </Space>
      {applicationState && (
        <div
          className={styles.statusContainer}
          style={{
            backgroundColor: applicationStateColor,
            color: getFontColor(applicationStateColor ?? '#000000'),
          }}>
          {applicationState}
        </div>
      )}
    </div>
  );
};
