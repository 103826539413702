import React, { FC, useState } from 'react';
import { Grid, Popconfirm, Space } from 'antd';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';
import { faBriefcase, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './dashboard.module.less';
import { ContentBlock, TextBlock } from '../../uicomp/atmos/content-block/ContentBlock';
import { Logo } from '../../uicomp/atmos/logo/Logo';
import { useCachedData } from '../../connection/CacheContext';

export type JobAlertProps = {
  alertId: string;
  jobTitle: string;
  recruiterName: string;
  location: string[];
  jobPostId?: string;
  workplaceType?: string;
  jobCategory: string[];
  period: string;
  read?: boolean;
  recruiterImage?: any;
  frequency?: string;
  relationId?: string;
  experienceLevel?: string;
};
type Props = {
  data: JobAlertProps;
  onDelete?: () => void;
  onView?: () => void;
  onRead?: () => void;
};

export const JobAlertCard: FC<Props> = (props) => {
  const {
    jobTitle,
    recruiterName,
    location,
    jobCategory,
    workplaceType,
    frequency,
    recruiterImage,
    read,
    experienceLevel,
  } = props.data;
  const bp = Grid.useBreakpoint();
  const { enumValue } = useCachedData();
  const [visible, setVisible] = useState(false);
  const cache = useCachedData();
  let bg = '';
  if (!read) {
    bg = '#F9F9F9';
  }
  return (
    <div style={{ backgroundColor: bg }} className={styles.shortlistCardContainer}>
      <div onClick={props.onRead} style={{ width: '99%' }}>
        <ContentBlock
          gap={'lg'}
          vertical={!bp.sm}
          className={styles.jobCardTitleContainer}
          left={<Logo src={recruiterImage} />}>
          <Space direction={'vertical'} className={'fullwidth'} size={'small'}>
            <TextBlock heading>
              <a className={styles.alertTitleLink} onClick={props.onView}>
                {jobTitle}
              </a>
            </TextBlock>
            <TextBlock level={1}>{recruiterName}</TextBlock>
            <TextBlock secondary>
              {Array.isArray(location)
                ? location?.map((v) => enumValue['Location']?.[v])?.join(' / ')
                : location
                ? enumValue?.['Location']?.[location]
                : ''}
            </TextBlock>
          </Space>
          <div className={styles.gap8}>
            <Space>
              <ContentBlock className={styles.contentLeftAlert} left={<BellOutlined />}>
                <TextBlock danger>{frequency ?? 'Instant'}</TextBlock>
              </ContentBlock>
              <ContentBlock
                className={styles.contentCenterAlert}
                secondary
                left={<FontAwesomeIcon icon={faBriefcase} />}>
                {jobCategory?.map((v) => enumValue?.['Job Category']?.[v]).join(' / ')}
              </ContentBlock>
              {workplaceType && (
                <ContentBlock secondary left={<FontAwesomeIcon icon={faLaptopHouse} />}>
                  {cache?.enumValue['Workplace Type']?.[workplaceType]}
                </ContentBlock>
              )}
              {experienceLevel && (
                <ContentBlock
                  className={styles.contentLeft}
                  secondary
                  left={<FontAwesomeIcon icon={faBriefcase} />}>
                  {enumValue?.['Experience Level']?.[experienceLevel]}
                </ContentBlock>
              )}
            </Space>
          </div>
          {!read && <div className={styles.unread} style={{ marginRight: 20 }} />}
        </ContentBlock>
        <Space direction={'vertical'} align={'end'} style={{ width: '100%' }}>
          <p>
            Ref. No{' '}
            {isNaN(Number(props.data.jobPostId))
              ? props?.data?.jobPostId?.substr(props?.data?.jobPostId?.length - 4).padStart(8, '0')
              : String(props.data.jobPostId).padStart(8, '0')}
          </p>
        </Space>
      </div>
      <div className={styles.cardCloseButton} onClick={() => setVisible(!visible)}>
        <Popconfirm
          placement="left"
          open={visible}
          title={'Are you sure to delete Alert?'}
          onConfirm={() => {
            setVisible(false);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            props.onDelete();
          }}
          okText="Yes"
          cancelText="No">
          <CloseOutlined className={styles.red} />
        </Popconfirm>
      </div>
    </div>
  );
};
