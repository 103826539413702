import React, {
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { message, Modal, Typography } from 'antd';
import { useConnection, useEvents } from '../../../../connection/Application';

export type JobWithdrawInstance = {
  withdraw: (jobPostId: string) => void;
};

const JobWithdraw: ForwardRefRenderFunction<JobWithdrawInstance, any> = (props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [jobPostId, setJobPostId] = useState<string>();
  const events = useEvents();
  const connection = useConnection();
  useImperativeHandle(
    ref,
    () => ({
      withdraw: (jobPostId) => {
        setJobPostId(jobPostId);
        setVisible(true);
      },
    }),
    [],
  );
  const withdraw = useCallback(() => {
    setLoading(true);
    connection
      .delete(`job-apply/${jobPostId}`)
      .then(() => {
        setVisible(false);
        message.success('Withdraw success');
        events.emit('JOB_WITHDRAWN', { jobPostId: jobPostId });
      })
      .catch((e) => {
        setVisible(false);
        message.error(e.message);
      })
      .finally(() => setLoading(false));
  }, [connection, events, jobPostId]);
  return (
    <Modal
      title={'Withdraw Application'}
      onCancel={() => setVisible(false)}
      onOk={() => withdraw()}
      okText={'Withdraw'}
      okType={'danger'}
      cancelText={'Cancel'}
      open={visible}
      destroyOnClose
      confirmLoading={loading}
      maskClosable={true}
      closable={true}
      centered>
      <Typography>Do you want to withdraw your application? </Typography>
    </Modal>
  );
};
export const useJobWithdraw = () => useRef<JobWithdrawInstance>(null);
export default React.forwardRef<JobWithdrawInstance, any>(JobWithdraw);
