import React, { FC } from 'react';
import styles from './ProfileStrength.module.less';
import { Tooltip } from 'antd';

type Props = {
  value: number;
};

export const ProfileStrength: FC<Props> = (props) => {
  return (
    <div className={styles.profileStrength}>
      <div className={styles.profileStrengthBar}>
        <div className={styles.profileStrengthProgress} style={{ width: `${props.value}%` }} />
      </div>
      <Tooltip title={`${props.value}%`}>
        <div
          className={styles.profileStrengthHandle}
          style={{ left: `calc(${props.value}% - 15px)` }}
        />
      </Tooltip>
    </div>
  );
};
