import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './RecruiterProfile.module.less';
import { RichTextDescCard } from './recruiterComp/RichTextDescCard';
import { DescListItem } from './recruiterComp/DescListItem';
import { Recruiter } from '../../models/RecruiterModals';
import { useConnection, useEvents } from '../../connection/Application';
import { RecruiterInfoView } from './recruiterComp/RecruiterInfoView';
import PhoneIcon from '../../public/phone-call-footer.svg';
import MailIcon from '../../public/mail-footer.svg';
import MapPinIcon from '../../public/map-pin-footer.svg';
import FBIcon from '../../public/facebook.svg';
import YouTubeIcon from '../../public/youtube.svg';
import WebIcon from '../../public/globe.svg';
import TwitterIcon from '../../public/twitter.svg';
import InstaIcon from '../../public/instagram.svg';
import LinkedinIcon from '../../public/linkedin.svg';
import { Divider, message } from 'antd';
import Image from 'next/image';
import Utils from '../../utils/Utils';
import { NextSeo } from 'next-seo';
import { DEFAULT_OG_IMAGES, SEO_COMMON } from '../../layout/candidate/CandidateLayout';
import { TextHeading } from '../../uicomp/atmos/content-block/ContentBlock';
import FileUploadEx from '../../components/FileUpload/FileUpload';
import { FormOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
// import { FormField } from '../../components/form/Form';
import RecruiterSchema from './RecruiterSchema';
import { useCachedData } from '../../connection/CacheContext';
import { useRecruiter } from './RecruiterContext';
// import parsePhoneNumber from 'libphonenumber-js';

type Props = {
  recruiterId: string;
  recruiter?: Recruiter;
  unEditable?: boolean;
  onChange?: (recruiter: any) => void;
};
export const RecruiterProfileView: FC<Props> = (props) => {
  const [recruiters, setRecruiter] = useState<Recruiter | undefined>(props.recruiter);
  // const [loading, setLoading] = useState<boolean>(false);
  const connection = useConnection();
  const [logo, setLogo] = useState<string>();
  const history = useRouter();
  const event = useEvents();
  const { enumValue } = useCachedData();
  const rec = useRecruiter();
  useEffect(() => {
    if (recruiters?.recruiterId !== props.recruiterId) {
      connection
        .get(`recruiter/${props.recruiterId}`)
        .then((res) => {
          setRecruiter(res.recruiter);
          props.onChange?.(res.recruiter);
        })
        .catch((e) => console.log(e));
    }
  }, [connection, props, props.recruiterId, recruiters?.recruiterId]);
  const navigateSocialLink = useCallback((url) => {
    window.open(url, '_newtab');
  }, []);
  useEffect(() => {
    const update = (value: Recruiter) => {
      if (props.recruiterId === value.recruiterId) {
        setRecruiter(value);
        props.onChange?.(value);
      }
    };
    event.on('UPDATE_RECRUITER_PROFILE', update);
    return () => {
      event.off('UPDATE_RECRUITER_PROFILE', update);
    };
  }, [event, props, props.recruiterId]);

  const addressFormat = useCallback(
    (city: string, country?: string, addressL1?: string, addressL2?: string) => {
      let address = '';
      if (addressL1) {
        address = address.concat('', addressL1);
      }
      if (addressL2) {
        address = address.concat(', \n', addressL2);
      }
      address = address.concat(', \n', city);
      if (country) {
        address = address.concat(', \n', country);
      }

      return address;
    },
    [],
  );
  //
  //
  // const formatNumber = useCallback((number: string)=> {
  //   const pn = parsePhoneNumber(number);
  //   const format = pn?.formatInternational();
  //   return format;
  // }, []);
  // if (loading) {
  //   return <Skeleton active />;
  // }
  const schema = useMemo(() => RecruiterSchema('job'), []);
  const handlerSubmitLogo = useCallback(
    (value: any) => {
      const fields: string[] = ['logo', 'recruiterId'];
      const recruiter = {
        logo: value,
        recruiterId: props.recruiterId,
      };
      connection
        .patch('recruiter', { recruiter, fields })
        .then(() => {
          event.emit('UPDATE_RECRUITER_PROFILE', { ...recruiters, ...recruiter });
          history.replace(`/recruiter/${props.recruiterId}/profile`);
        })
        .catch((e) => message.error(e.message));
    },
    [connection, event, history, props.recruiterId, recruiters],
  );

  const handlerSubmitCover = useCallback(
    (value: any) => {
      const fields: string[] = ['coverImage', 'recruiterId'];
      const recruiter = {
        coverImage: value,
        recruiterId: props.recruiterId,
      };
      connection
        .patch('recruiter', { recruiter, fields })
        .then(() => {
          event.emit('UPDATE_RECRUITER_PROFILE', { ...recruiters, ...recruiter });
          history.replace(`/recruiter/${props.recruiterId}/profile`);
        })
        .catch((e) => message.error(e.message));
    },
    [connection, event, history, props.recruiterId, recruiters],
  );

  return (
    <React.Fragment>
      {recruiters && (
        <NextSeo
          title={recruiters?.name}
          description={recruiters?.about}
          openGraph={{
            title: `${
              recruiters?.name
            } | Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format('YYYY')} | Careers360`,
            description:
              'Careers360 is the most popular job portal for jobs in Sri Lanka, and to find out the best top job vacancies in Sri Lanka to your career next growth step',
            images: DEFAULT_OG_IMAGES,
          }}
          {...SEO_COMMON}
        />
      )}
      <div className={styles.recruiterProfile}>
        {recruiters && (
          <div>
            <div
              className={Utils.mergeClasses({
                [styles.coverImageHolder]: !!recruiters?.coverImage,
                [styles.coverBorder]: true,
                [styles.emptyCoverHolder]: !recruiters?.coverImage,
              })}>
              {recruiters?.coverImage && <img alt={'cover'} src={recruiters?.coverImage} />}
              {!props.unEditable && rec?.hasPermission('Edit Profile') && (
                <FileUploadEx
                  className={styles.coverUploader}
                  // value={coverImage}
                  onChange={(url) => {
                    handlerSubmitCover(url);
                  }}
                  image={{
                    crop: true,
                    // useRatio: true,
                    width: 1343,
                    height: 260,
                    // beforeCrop: uploadValidator,
                  }}
                  accept={Utils.CommonImageFileFormats}
                  acceptSize={2.5}>
                  <div>
                    <div className={[styles.editButton, styles.editPositionCover].join(' ')}>
                      <FormOutlined className={styles.editIcon} />
                    </div>
                  </div>
                </FileUploadEx>
              )}

              <div
                className={Utils.mergeClasses({
                  [styles.logoHolder]: true,
                  [styles.logBorder]: true,
                  [styles.emptyCoverLogoHolder]: !recruiters?.coverImage,
                })}>
                {recruiters?.logo && <img alt={'logo'} src={recruiters?.logo} />}
                {!props.unEditable && rec?.hasPermission('Edit Profile') && (
                  <FileUploadEx
                    value={logo}
                    className={styles.coverUploader}
                    onChange={(url) => handlerSubmitLogo(url)}
                    image={{
                      crop: true,
                      width: 178,
                      height: 178,
                    }}
                    accept={Utils.CommonImageFileFormats}
                    acceptSize={2.5}>
                    <div>
                      <div className={[styles.editButton, styles.editPositionAvatar].join(' ')}>
                        <FormOutlined
                          className={styles.editIcon}
                          onCompositionEndCapture={handlerSubmitLogo}
                        />
                      </div>
                    </div>
                  </FileUploadEx>
                )}
              </div>
            </div>
          </div>
        )}
        <article className={styles.profileBody}>
          <div>
            <div className={styles.socialHolder}>
              {recruiters?.facebook && (
                <div>
                  <Image
                    alt="facebook"
                    onClick={() => {
                      navigateSocialLink(recruiters?.facebook);
                    }}
                    src={FBIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
              {recruiters?.linkedin && (
                <div>
                  <Image
                    alt="linkedin"
                    onClick={() => {
                      navigateSocialLink(recruiters?.linkedin);
                    }}
                    src={LinkedinIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
              {recruiters?.twitter && (
                <div>
                  <Image
                    alt="twitter"
                    onClick={() => {
                      navigateSocialLink(recruiters?.twitter);
                    }}
                    src={TwitterIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
              {recruiters?.instagram && (
                <div>
                  <Image
                    alt="insta"
                    onClick={() => {
                      navigateSocialLink(recruiters?.instagram);
                    }}
                    src={InstaIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
              {recruiters?.youtube && (
                <div>
                  <Image
                    alt="youtube"
                    onClick={() => {
                      navigateSocialLink(recruiters?.youtube);
                    }}
                    src={YouTubeIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
              {recruiters?.website && (
                <div>
                  <Image
                    alt="web"
                    onClick={() => {
                      navigateSocialLink(recruiters?.website);
                    }}
                    src={WebIcon}
                    width={27}
                    height={27}
                  />
                </div>
              )}
            </div>
            <div className={styles.titleBold35}>
              <TextHeading heading level={2}>
                {recruiters?.name}
              </TextHeading>
            </div>
            <div className={Utils.mergeClasses(styles.infoHolder, 'px-5')}>
              {(recruiters?.phone || recruiters?.email || recruiters?.location) && (
                <div>
                  {recruiters?.phone && (
                    <RecruiterInfoView
                      icon={PhoneIcon}
                      text={
                        recruiters?.phone ? Utils.formatPhoneNumber(recruiters.phone) : undefined
                      }
                    />
                  )}
                  {recruiters?.email && (
                    <RecruiterInfoView icon={MailIcon} text={recruiters?.email} />
                  )}
                  {recruiters?.location && (
                    <RecruiterInfoView
                      icon={MapPinIcon}
                      text={addressFormat(
                        enumValue?.['Location']?.[recruiters?.location],
                        recruiters?.country,
                        recruiters?.addressLine1,
                        recruiters?.addressLine2,
                      )}
                    />
                  )}
                </div>
              )}
              {recruiters && (
                <div>
                  <RecruiterInfoView
                    title={'Industry Sector'}
                    text={enumValue?.['Sector']?.[recruiters?.sector]}
                  />
                </div>
              )}
              <div>
                {recruiters?.recruiterSize && (
                  <RecruiterInfoView
                    title={'Company Size'}
                    text={`${enumValue?.['Recruiter Size']?.[recruiters?.recruiterSize]} employees`}
                  />
                )}
              </div>
            </div>
            {recruiters && <RichTextDescCard title="What We Do" value={recruiters?.about} />}
            {recruiters?.environment && (
              <RichTextDescCard title="Working Environment" value={recruiters?.environment} />
            )}
            {recruiters?.vision && <RichTextDescCard title="Vision" value={recruiters?.vision} />}
            {recruiters?.mission && (
              <RichTextDescCard title="Mission" value={recruiters?.mission} />
            )}
            {recruiters?.benefits && recruiters?.benefits?.length > 0 && (
              <RichTextDescCard title="Benefits" borderless={true} className={styles.benifitCard}>
                {recruiters?.benefits.map((item, index) => (
                  <div key={index}>
                    <DescListItem
                      key={index}
                      numberText={`${(index + 1).toString().padStart(2, '0')}`}
                      title={enumValue?.['Employee Benefit']?.[item.type]}
                      value={item.description}
                    />
                    <Divider />
                  </div>
                ))}
              </RichTextDescCard>
            )}
          </div>
        </article>
      </div>
    </React.Fragment>
  );
};
