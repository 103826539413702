import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { JobNotificationCard, JobNotificationCardProps } from '../JobAlertView/JobNotificationCard';
import { Button, Drawer, Grid, message } from 'antd';
import { useConnection, useNotificationInfo, useUserInfo } from '../../connection/Application';
import { EmptyItem } from '../JobAlertView/Dashboard';
import stylesModule from '../JobAlertView/dashboard.module.less';
import { useRouter } from 'next/router';
import FormModal, { FormModalExRef } from '../../components/formModal/FormModal';
import { FormSchema } from '../../components/form/Form';

const styles: any = stylesModule;
type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
};
export const NotificationDrawer: FC<Props> = ({ visible, setVisible }) => {
  const connection = useConnection();
  const notificationInfo = useNotificationInfo();
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [notificationData, setNotificationData] = useState<JobNotificationCardProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasNotificationData, setHasNotificationData] = useState<boolean>(false);
  const lastNotificationTime = useRef<number>(0);
  const form = useRef<FormModalExRef>(null);
  const bp = Grid.useBreakpoint();
  const user = useUserInfo();
  const router = useRouter();
  const emailSchema: FormSchema = useMemo(
    () => [
      {
        name: 'Subject',
        id: 'subject',
        type: 'string',
        props: { maxLength: 200 },
        options: {
          rules: [{ required: true, message: 'Subject is required' }],
        },
      },
      {
        name: 'Message',
        id: 'message',
        type: 'richText',
        props: {
          placeholder: 'Message to send',
          autosize: { minRows: 8, maxRows: 12 },
          maxLength: 4000,
        },
        options: {
          rules: [
            { required: true, message: 'Please enter the message to send.' },
            { max: 4000, message: 'Only 4000 characters are allowed.' },
          ],
        },
      },
    ],
    [],
  );
  const eyeballSend = useCallback(
    (job: any) => {
      form.current?.edit('Send Email', emailSchema).onSave((data) => {
        console.log(data);
        job.message = data.message;
        job.subject = data.subject;
        connection
          .post(`eyeball/candidate-reply/${user?.userId}`, job)
          .then(() => message.success('Message Sent Successfully'))
          .catch(() => message.error('Sorry! Something went Wrong'))
          .finally(() => form.current?.close());
      });
    },
    [connection, emailSchema, user?.userId],
  );
  const handlerGetData = useCallback(() => {
    setNotificationData([]);
    setLoading(true);
    setPage(1);
    setTotal(0);
    connection
      .get('notification/list/0')
      .then((response) => {
        setNotificationData(response.notifications);
        setTotal(response.notifications.length);
        if (response.notifications.length === 10) {
          setHasNotificationData(true);
        } else {
          setHasNotificationData(false);
        }
        if (response.notifications.length > 0) {
          lastNotificationTime.current = response.notifications[0].time;
        }
      })
      .catch((e) => console.log('error', e))
      .finally(() => {
        setLoading(false);
        // events.emit('NOTIFICATION_RESET', { count: 0 });
      });
  }, [connection]);
  const loadmore = useCallback(() => {
    setLoading(true);
    const lastTime = notificationData[notificationData.length - 1].time;
    connection
      .get(`notification/list/${lastTime}`)
      .then((response) => {
        const newNotifications = [...notificationData, ...response.notifications];
        setNotificationData(newNotifications);
        setTotal(total + 10);
        if (response.notifications.length === 10) {
          setHasNotificationData(true);
        } else {
          setHasNotificationData(false);
        }
      })
      .catch((e) => console.log('error', e))
      .finally(() => setLoading(false));
  }, [connection, notificationData, total]);

  const markAsRead = useCallback(
    (type: 'Notification' | 'Alert' | 'Application', data: any) => {
      connection
        .patch(`notification/mark-as-read`, { type, ...data })
        .then(({ count }) => {
          notificationInfo.setNotificationData(count);
        })
        .catch((e) => console.log('eror', e));
    },
    [connection, notificationInfo],
  );
  const markNotificationRead = (item: any, index: number) => {
    if (item.read !== false) {
      return;
    }
    const newAppliedData = [...notificationData];
    newAppliedData[index] = { ...notificationData[index], read: true };
    setNotificationData(newAppliedData);
    notificationInfo.setNotificationData({
      ...notificationInfo.notificationData,
      notificationCount: notificationInfo.notificationData.notificationCount - 1,
      total: notificationInfo.notificationData.total - 1,
    });
    markAsRead('Notification', { time: item.time, userId: user!.userId });
  };

  useEffect(() => {
    handlerGetData();
  }, [handlerGetData]);
  return (
    <Drawer
      title="Notifications"
      width={bp?.lg ? 720 : '100%'}
      onClose={() => setVisible(false)}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}>
      {notificationData.map((item, index) => {
        return (
          <JobNotificationCard
            data={item}
            key={item.time}
            onClick={() => eyeballSend(item)}
            onRead={() => markNotificationRead(item, index)}
          />
        );
      })}
      {!loading && notificationData.length === 0 && (
        <EmptyItem
          tab={'Notifications'}
          onPress={() => {
            router.push('/jobs');
            // console.log('ee');
          }}
        />
      )}
      {hasNotificationData && (
        <div className={styles.manageAlertButton}>
          <Button type={'primary'} size={'small'} onClick={loadmore}>
            Load More
          </Button>
        </div>
      )}
      <FormModal layout={'vertical'} ref={form} schema={emailSchema} okText={'Send'} />
    </Drawer>
  );
};
