// eslint-disable-next-line react-hooks/rules-of-hooks
import { FormSchema } from '../../components/form/Form';
import { FormInstance } from 'antd/lib/form';
import { NamePathArr } from '../../components/form/Form';
import { useConnection, useUserInfo } from '../../connection/Application';

const getRecruiterSchema = (prefix: string, mobile?: string): FormSchema => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useUserInfo();

  if (prefix === 'job') {
    return [
      {
        id: 'name',
        name: 'Company Name',
        type: 'string',
        props: { placeholder: 'Company Name', maxLength: 60 },
        options: {
          rules: [
            { required: true, message: 'Company Name is required' },
            { max: 60, message: 'Only 60 characters are allowed.' },
            {
              validator: 'blankValidator',
              message: 'Company Name is required',
            },
          ],
        },
      },
      {
        id: 'sector',
        name: 'Industry Sector',
        layout: { md: 12 },
        type: 'enum',
        props: { placeholder: 'Industry Sector', enumName: 'Sector' },
        options: {
          rules: [{ required: true, message: 'Industry Sector is required' }],
        },
      },
      {
        id: 'recruiterSize',
        name: 'Number of Employees',
        type: 'enum',
        props: { placeholder: 'Number of Employees', enumName: 'Recruiter Size' },
        layout: { md: 12 },
        options: {
          rules: [{ required: true, message: 'Number of Employees is required' }],
        },
      },
      {
        id: 'addressLine1',
        name: 'Address Line 1',
        type: 'string',
        props: { placeholder: 'Address Line 1' },
        layout: { md: 12 },
        options: {
          rules: [
            { required: true, message: 'Address Line 1 is required' },
            {
              validator: 'blankValidator',
              message: 'Address Line 1 is required',
            },
          ],
        },
      },
      {
        id: 'addressLine2',
        name: 'Address Line 2',
        type: 'string',
        props: { placeholder: 'Address Line 2' },
        layout: { md: 12 },
        options: {
          rules: [
            // { required: true, message: 'Address Line 2 is required' },
            {
              validator: 'blankValidator',
              message: 'Address Line 2 is required',
            },
          ],
        },
      },
      {
        id: 'location',
        name: 'City',
        type: 'enum',
        props: { placeholder: 'City', enumName: 'Location', maxLength: 256 },
        layout: { md: 12 },
        options: {
          rules: [{ required: true, message: 'City is required' }],
        },
      },
      {
        id: 'country',
        name: 'Country',
        type: 'country',
        props: { placeholder: 'Country' },
        layout: { md: 12 },
        options: {
          rules: [{ required: true, message: 'Country is required' }],
        },
      },
      {
        id: 'about',
        name: 'What we do',
        type: 'richText',
        props: {
          placeholder: 'About your company - Description',
          autosize: { minRows: 8, maxRows: 12 },
          maxLength: 4000,
        },
        options: {
          rules: [
            { required: true, message: 'Description is required' },
            { max: 4000, message: 'Only 4000 characters are allowed.' },
            { min: 150, message: 'Enter at least 150 characters.' },
          ],
        },
      },
      {
        id: 'environment',
        name: 'Working Environment',
        type: 'richText',
        props: {
          placeholder: 'About your company - Working Environment (optional)',
          autosize: { minRows: 8, maxRows: 12 },
          maxLength: 4000,
        },
        options: {
          rules: [{ max: 4000, message: 'Only 4000 characters are allowed.' }],
        },
      },
      {
        id: 'vision',
        name: 'Vision',
        type: 'richText',
        props: {
          placeholder: 'About your company - Vision (optional)',
          autosize: { minRows: 8, maxRows: 12 },
          maxLength: 4000,
        },
        options: {
          rules: [{ max: 4000, message: 'Only 4000 characters are allowed.' }],
        },
      },
      {
        id: 'mission',
        name: 'Mission',
        type: 'richText',
        props: {
          placeholder: 'About your company - Mission (optional)',
          autosize: { minRows: 8, maxRows: 12 },
          maxLength: 4000,
        },
        options: {
          rules: [{ max: 4000, message: 'Only 4000 characters are allowed.' }],
        },
      },
      {
        id: 'phone',
        name: 'Phone Number',
        type: 'phone',
        layout: { md: 12 },
        props: { placeholder: 'Phone Number' },
        options: {
          rules: [
            {
              validator: 'phone',
              message: 'Should be a valid Phone number',
            },
          ],
        },
      },
      {
        id: 'email',
        name: 'Email Address',
        type: 'email',
        layout: { md: 12 },
        props: { placeholder: 'Email Address', maxLength: 256 },
        options: {
          rules: [{ type: 'email', message: 'Invalid email address' }],
        },
      },
      {
        id: 'website',
        name: 'Website',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'Website', maxLength: 1024 },
      },
      {
        id: 'facebook',
        name: 'Facebook',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'Facebook', maxLength: 1024 },
      },
      {
        id: 'linkedin',
        name: 'LinkedIn',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'LinkedIn', maxLength: 1024 },
      },
      {
        id: 'instagram',
        name: 'Instagram',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'Instagram', maxLength: 1024 },
      },
      {
        id: 'twitter',
        name: 'Twitter',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'Twitter', maxLength: 1024 },
      },
      {
        id: 'youtube',
        name: 'YouTube',
        type: 'string',
        layout: { md: 12 },
        props: { placeholder: 'YouTube', maxLength: 1024 },
      },
      {
        id: 'contactName',
        // name: "Contact Person's Name",
        type: 'contactPersonName',
        // layout: { md: 12 },
        props: (value: any, form: FormInstance, parentPath: NamePathArr) => ({
          presentText: "Same as Company's Email Address",
          placeholder: "Contact Person's Name",
          maxLength: 1024,
          checkboxvalue: form.getFieldValue('email'),
          initialvalue: user?.name ?? undefined,
        }),
        options: {
          rules: [{ required: true, message: 'Contact person’s name is required' }],
        },
      },
      {
        id: 'contactNumber',
        name: "Contact Person's Phone Number",
        type: 'contactPersonNumber',
        layout: { md: 12 },
        props: (value: any, form: FormInstance, parentPath: NamePathArr) => ({
          presentText: "Same as Company's Phone Number",
          placeholder: "Contact Person's Phone Number",
          maxLength: 1024,
          checkboxvalue: form.getFieldValue('phone'),
          initialvalue: mobile ?? undefined,
        }),
        options: {
          rules: [
            {
              validator: 'phoneVerification',
              message: 'Should be a valid Phone number',
            },
            { required: true, message: 'Contact person’s phone number is required' },
          ],
          // dependencies: ['mobileVerification'],
        },
      },
      {
        id: 'contactEmail',
        name: "Contact Person's Email Address",
        type: 'contactPersonEmail',
        layout: { md: 12 },
        props: (value: any, form: FormInstance, parentPath: NamePathArr) => ({
          presentText: "Same as Company's Email Address",
          placeholder: "Contact Person's Email Address",
          maxLength: 1024,
          checkboxvalue: form.getFieldValue('email'),
          initialvalue: user?.email ?? undefined,
        }),
        options: {
          rules: [
            { type: 'email', message: 'Invalid email address' },
            { required: true, message: 'Contact person’s email address  is required' },
          ],
        },
      },

      {
        id: 'benefits',
        name: 'Benefits',
        numlist: true,
        type: {
          array: {
            id: '<auto>',
            type: {
              object: [
                {
                  id: 'type',
                  type: 'enum',
                  props: { enumName: 'Employee Benefit', placeholder: 'Type of benefit' },
                  options: {
                    rules: [
                      { required: true, message: 'Benefit type required' },
                      // {
                      //   validator: 'array-item-equals',
                      //   compare: 'type',
                      //   message: 'Benefit already defined',
                      // },
                    ],
                  },
                },
                {
                  id: 'description',
                  type: 'richText',
                  props: { placeholder: 'Description of benefit', maxLength: 500 },
                  options: {
                    rules: [
                      { required: true, message: 'Benefit description required' },
                      { max: 500, message: 'Only 500 characters are allowed.' },
                    ],
                  },
                },
              ],
            },
          },
          addText: 'Add Benefit',
          // border: true,
        },
      },
      // {
      //   id: 'video',
      //   name: 'Company Videos',
      //   type: 'string[]',
      //   props: { maxArrayLength: 2 },
      // },
    ];
  }
  return [
    {
      id: 'name',
      name: 'Company Name',
      type: 'string',
      props: { placeholder: 'Name', maxLength: 60 },
      options: {
        rules: [
          { required: true, message: 'Name is required' },
          { max: 60, message: 'Only 60 characters are allowed.' },
        ],
      },
    },
    // {
    //   id: 'sector',
    //   name: 'Sector',
    //   layout: { md: 12 },
    //   type: 'enum',
    //   props: { placeholder: 'Sector', enum: 'sector' },
    //   options: {
    //     rules: [{ required: true, message: 'Sector is required' }],
    //   },
    // },
    {
      id: 'country',
      name: 'Country',
      type: 'country',
      props: { placeholder: 'Country' },
      layout: { md: 12 },
      options: {
        rules: [{ required: true, message: 'Country is required' }],
      },
    },
    // {
    //   id: 'location',
    //   name: 'Location',
    //   type: 'autocomplete',
    //   props: { placeholder: 'Location', type: 'location', maxLength: 256 },
    //   layout: { md: 12 },
    //   options: {
    //     rules: [{ required: true, message: 'Location is required' }],
    //   },
    // },
    {
      id: 'about',
      name: 'What we do',
      type: 'richText',
      props: {
        placeholder: 'About your company - Description',
        autosize: { minRows: 8, maxRows: 12 },
      },
      options: {
        rules: [
          { required: true, message: 'Please enter the description.' },
          { max: 4000, message: 'Only 4000 characters are allowed.' },
          { min: 150, message: 'Enter at least 150 characters.' },
        ],
      },
    },
    {
      id: 'vision',
      name: 'Vision',
      type: 'richText',
      props: {
        placeholder: 'About your company - Vision (optional)',
        autosize: { minRows: 8, maxRows: 12 },
      },
      options: {
        rules: [{ max: 4000, message: 'Only 4000 characters are allowed.' }],
      },
    },
    {
      id: 'mission',
      name: 'Mission',
      type: 'richText',
      props: {
        placeholder: 'About your company - Mission (optional)',
        autosize: { minRows: 8, maxRows: 12 },
      },
      options: {
        rules: [{ max: 4000, message: 'Only 4000 characters are allowed.' }],
      },
    },
    {
      id: 'phone',
      name: 'Phone Number',
      type: 'phone',
      layout: { md: 12 },
      props: { placeholder: 'Phone Number' },
      // options: {
      //   rules: [{ validator: CommonAdapters.validatePhone }],
      // },
    },
    {
      id: 'email',
      name: 'Email',
      type: 'email',
      layout: { md: 12 },
      props: { placeholder: 'Email Address', maxLength: 256 },
      options: {
        rules: [{ type: 'email', message: 'Invalid email address' }],
      },
    },
    {
      id: 'web',
      name: 'Website',
      type: 'string',
      layout: { md: 12 },
      props: { placeholder: 'Website', maxLength: 1024 },
    },
    {
      id: 'facebook',
      name: 'Facebook',
      type: 'string',
      layout: { md: 12 },
      props: { placeholder: 'Facebook', maxLength: 1024 },
    },
    {
      id: 'linkedin',
      name: 'LinkedIn',
      type: 'string',
      layout: { md: 12 },
      props: { placeholder: 'LinkedIn', maxLength: 1024 },
    },
    {
      id: 'instagram',
      name: 'Instagram',
      type: 'string',
      layout: { md: 12 },
      props: { placeholder: 'Instagram', maxLength: 1024 },
    },
    {
      id: 'twitter',
      name: 'Twitter',
      type: 'string',
      layout: { md: 12 },
      props: { placeholder: 'Twitter', maxLength: 1024 },
    },
    // {
    //   id: 'benefits',
    //   name: 'Benefits',
    //   type: {
    //     array: {
    //       id: '<auto>',
    //       type: {
    //         object: [
    //           {
    //             id: 'type',
    //             type: 'enum',
    //             props: { enum: 'employee_benefits', placeholder: 'Type of benefit' },
    //             options: {
    //               rules: [
    //                 { required: true, message: 'Benefit type required' },
    //                 {
    //                   validator: 'array-item-equals',
    //                   compare: 'type',
    //                   message: 'Benefit already defined',
    //                 },
    //               ],
    //             },
    //           },
    //           {
    //             id: 'description',
    //             type: 'text',
    //             props: { placeholder: 'Description of benefit', maxLength: 1024 },
    //             options: {
    //               rules: [{ required: true, message: 'Benefit description required' }],
    //             },
    //           },
    //         ],
    //       },
    //     },
    //     addText: 'Add Benefit',
    //     // border: true,
    //   },
    // },
  ];
};
export default getRecruiterSchema;
