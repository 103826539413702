import React, { FC } from 'react';
import styles from '../RecruiterProfile.module.less';
import { Typography } from 'antd';
import Image from 'next/image';

type Props = {
  icon?: any;
  text?: string;
  title?: string;
};
export const RecruiterInfoView: FC<Props> = (props) => {
  if (props.title) {
    return (
      <div className={styles.rowItemContainer}>
        <div>
          <Typography.Text className={styles.infoTitle}>{props.title}</Typography.Text>
          <br />
          <Typography.Text ellipsis={false} className={styles.infoText}>
            {props.text}
          </Typography.Text>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.rowItemContainer}>
      {props.icon && (
        <div className={styles.infoIcon}>
          <Image src={props.icon} width={21} height={21} alt={'Tie'} />
        </div>
      )}
      <div className={styles.infoContent}>
        <Typography.Text className={styles.infoText}>{props.text}</Typography.Text>
      </div>
    </div>
  );
};
