import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Divider, Grid, message, Modal, Space } from 'antd';
import { ContentBlock, TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import { Logo } from '../../uicomp/atmos/logo/Logo';
import Utils from '../../utils/Utils';
import querystring from 'querystring';
import { ClockCircleOutlined, CopyOutlined, DollarCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faFolder, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';
import TieImage from '../../public/home/tie.svg';
import ExperienceImage from '../../public/home/Experiance.svg';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import {
  EmailIcon,
  EmailShareButton,
  // FacebookIcon,
  FacebookShareButton,
  // LinkedinIcon,
  LinkedinShareButton,
  // TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'next-share';

import FacebookIcon from '../../public/facebook.svg';
import TwitterIcon from '../../public/twitter.svg';
import LinkedinIcon from '../../public/linkedin.svg';

import { useConnection, useUserInfo } from '../../connection/Application';
import Form, { FormSchema } from '../form/Form';
import { useSearchContext } from '../../page-components/search/SearchContext';
import { useCachedData } from '../../connection/CacheContext';
import config from '../../config';
import stylesObj from '../../page-components/search/components/SearchComponents.module.less';
import { RecruiterProfileView } from '../../page-components/Recruiiter/RecruiterProfileView';
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal';

const styles: any = stylesObj;

type Props = {
  value: any;
  query?: any;
  visible: boolean;
  onCancel?: (value: boolean) => void;
};

const JobPostShare: FC<Props> = (props) => {
  const { value } = props;
  const [recruiterId, setRecruiterId] = useState<string>();
  const ctx = useSearchContext();
  const bp = Grid.useBreakpoint();
  const cache = useCachedData();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const jobTitle = value?.jobTitle ?? '';
  const user = useUserInfo();
  const shareUrl = `${config.frontendUrl}/jobs/${Utils.normalizeURIComponent(jobTitle)}/${
    value.jobPostId
  }`.trim();
  const connection = useConnection();

  const handlerCancel = useCallback(() => {
    props.onCancel?.(false);
  }, [props]);

  const handlerSendEmail = useCallback(
    (valueX: any) => {
      valueX.url = shareUrl;
      const job = { ...value };
      delete job.badge;
      valueX.job = job;
      setLoading(true);
      connection
        .post('job-post/share', valueX)
        .then(() => {
          handlerCancel();
          form.resetFields();
          message.success('Successfully Shared');
        })
        .catch((e) => message.error(e.message))
        .finally(() => setLoading(false));
    },
    [connection, form, handlerCancel, shareUrl, value],
  );

  const schema: FormSchema = useMemo(
    () => [
      {
        id: 'name',
        name: 'From',
        type: 'string',
        props: { maxLength: 60 },
        options: {
          rules: [
            { required: true, message: 'Name is required' },
            { max: 60, message: 'Only 60 characters are allowed.' },
          ],
        },
      },
      {
        id: 'email',
        name: 'Email to',
        type: 'email',
        props: { maxLength: 256 },
        options: {
          rules: [
            { required: true, message: 'Email address is required' },
            { type: 'email', message: 'Invalid Email address' },
          ],
        },
      },
    ],
    [],
  );

  const shareText = useMemo(
    () =>
      `Hey,\nYou have been invited to view the following job opportunity on Careers360.lk\n${value.jobTitle} - \n`,
    [value.jobTitle],
  );

  const shareTextMail = useMemo(
    () =>
      `Hey,\n\nYou have been invited to view the following job opportunity on Careers360.lk\n\n${value.jobTitle} -`,
    [value.jobTitle],
  );

  return (
    <>
      <Modal
        open={props.visible}
        maskClosable={false}
        onCancel={handlerCancel}
        centered
        width={760}
        footer={null}
        className={styles.modalclose}>
        <ContentBlock heading level={1} className={styles.modalpadding}>
          {value.jobTitle}
        </ContentBlock>

        <ContentBlock
          className={styles.modalpaddingb}
          gap="lg"
          vertical={!bp.md}
          left={
            <Space direction={bp.md ? 'vertical' : 'horizontal'}>
              <Button
                className={styles.viewRecruiter}
                type={'link'}
                onClick={() => setRecruiterId(value.recruiterId)}>
                <Logo src={value.recruiterImage!} />
              </Button>
              <a
                href={`/jobs/${Utils.normalizeURIComponent(value.jobTitle ?? 'job')}/${
                  value.jobPostId
                }?${querystring.stringify({
                  company: value.recruiterId,
                  results: 'show',
                })}`}
                target={'_blank'}
                rel="noreferrer">
                <Button
                  onClick={() => ctx.search({ company: [value.recruiterId!] }, true)}
                  type={'link'}
                  className={styles.viewAllJobspopup}>
                  View All Jobs
                </Button>
              </a>
            </Space>
          }>
          <Space direction={'vertical'}>
            <Space wrap>
              <TextBlock level={1}>
                {value.recruiterName}
                &nbsp; : &nbsp;
                {/*{value.location?.join(', ')}*/}
                {cache.getLocationString(value.locationData ?? [])}
              </TextBlock>
            </Space>
            <Space size={['large', 'small']} wrap>
              <ContentBlock primary left={<ClockCircleOutlined />}>
                {moment(value.time).fromNow()}
              </ContentBlock>
              <ContentBlock secondary left={<FontAwesomeIcon icon={faBriefcase} />}>
                {value.jobType?.map((v: any) => cache.enumValue?.['Job Types']?.[v]).join(' / ')}
              </ContentBlock>
              <ContentBlock
                secondary
                left={<Image alt="applicants" src={TieImage} width={16} height={16} />}>
                <TextSpan strong normal>
                  {value.applicantCount ?? 0}
                </TextSpan>{' '}
                Applied
              </ContentBlock>
              <ContentBlock secondary left={<FontAwesomeIcon icon={faCalendarAlt} />}>
                {`Expires on: ${moment(new Date(value?.expireDate ?? '')).format('MMM DD yyyy')}`}
              </ContentBlock>
              {(value.salaryValue ?? '').trim() !== '' && (
                <ContentBlock secondary left={<DollarCircleFilled />}>
                  {value.salaryValue}
                </ContentBlock>
              )}
              <ContentBlock secondary left={<FontAwesomeIcon icon={faFolder} />}>
                {value.jobCategory
                  ?.map((v: any) => cache.enumValue?.['Job Category']?.[v])
                  .join(' / ')}
              </ContentBlock>
              {value.workplaceType && (
                <ContentBlock secondary left={<FontAwesomeIcon icon={faLaptopHouse} />}>
                  {cache.enumValue?.['Workplace Type']?.[value.workplaceType]}
                </ContentBlock>
              )}
              {value.experienceLevel && (
                <ContentBlock
                  secondary
                  left={
                    <Image
                      alt="tie"
                      src={ExperienceImage}
                      width={16}
                      height={16}
                      style={{ alignSelf: 'center', display: 'flex', marginTop: 3 }}
                    />
                  }>
                  {cache.enumValue?.['Experience Level']?.[value.experienceLevel]}
                </ContentBlock>
              )}
            </Space>
          </Space>
        </ContentBlock>
        <Space
          direction={'vertical'}
          align={'end'}
          style={{ width: '100%' }}
          className={styles.deviderpadding}>
          <p>
            Ref. No{' '}
            {isNaN(Number(value.jobPostId))
              ? value?.jobPostId?.substr(value?.jobPostId?.length - 4).padStart(8, '0')
              : String(value?.jobPostId).padStart(8, '0')}
          </p>
        </Space>
        <Divider
          plain
          className={styles.deviderpadding}
          style={{ paddingTop: '5px', color: '#6C6C6C', fontSize: '16px' }}>
          {' '}
          Share with
        </Divider>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 8,
            alignItems: 'center',
            height: 10,
            paddingBottom: '23px',
            paddingTop: '23px',
          }}>
          <EmailShareButton
            url={shareUrl}
            subject={`${value.jobTitle} opportunity on Careers360!`}
            body={shareTextMail}>
            <EmailIcon size={30} round />
          </EmailShareButton>
          <FacebookShareButton url={shareUrl} quote={shareText}>
            {/* <FacebookIcon size={30} round /> */}
            <Image alt="facbook" src={FacebookIcon} width={30} height={30} />
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl} title={shareText}>
            <WhatsappIcon size={30} round />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl} title={shareText}>
            {/* <TwitterIcon size={30} round /> */}
            <Image alt="twitter" src={TwitterIcon} width={30} height={30} />
          </TwitterShareButton>
          <LinkedinShareButton title={shareText} url={shareUrl}>
            {/* <LinkedinIcon size={30} round /> */}
            <Image alt="linkedin" src={LinkedinIcon} width={30} height={30} />
          </LinkedinShareButton>
          <div className={styles.copybutton}>
            <Button
              size={'small'}
              onClick={() => {
                navigator.clipboard
                  .writeText(`${shareUrl}`)
                  .then(() => message.success('Job URL Copied to the clipboard'))
                  .catch(() => message.error("Sorry! Couldn't copy Job URL"));
              }}
              icon={<CopyOutlined size={5} />}
              shape="circle"
            />
          </div>
        </div>

        <Divider
          plain
          className={styles.deviderpadding}
          style={{ paddingBottom: '5px', color: '#6C6C6C' }}>
          {' '}
          Or Send Mail
        </Divider>

        <div className={styles.modalpaddingb}>
          <Form.Form
            layout={'vertical'}
            form={form}
            onFinish={handlerSendEmail}
            initialValues={{ name: user?.name }}>
            <Form.Items schema={schema} />
          </Form.Form>
          <div className={styles.buttonpadding}>
            <Button
              className={styles.button}
              type="primary"
              block
              onClick={form.submit}
              loading={loading}>
              Share This Job
            </Button>
          </div>
        </div>
      </Modal>
      <ResponsiveModal
        open={!!recruiterId}
        onCancel={() => setRecruiterId(undefined)}
        footer={false}
        destroyOnClose
        maskClosable={true}
        closable={true}
        width={996}>
        {recruiterId && <RecruiterProfileView recruiterId={recruiterId} unEditable={true} />}
      </ResponsiveModal>
    </>
  );
};

export default JobPostShare;
