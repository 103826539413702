import React, { FC } from 'react';
import styles from './SearchComponents.module.less';
import { Button, Image, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
  image: string;
};

export const JobImageView: FC<Props> = (props) => {
  return (
    <div className={styles.jobImageViewHolder}>
      <a target={'_blank'} download={'image.png'} href={props.image} rel="noreferrer">
        <Button type={'link'}>
          <Space>
            <FontAwesomeIcon icon={faDownload} />
            Download
          </Space>
        </Button>
      </a>
      <div className={styles.jobImageView}>
        <div className={styles.jobImageContainer}>
          <img alt={'job'} className={styles.jobImage} src={props.image} />
        </div>
        <div className={styles.jobImageActions}>
          <Image alt={'job'} className={styles.jobThumbnail} src={props.image} />
          <a href={props.image} target={'_blank'} rel="noreferrer">
            <Button type={'link'}>
              <Space>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                View in Browser
              </Space>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};
