import React, { FC, useRef } from 'react';
import Image from 'next/image';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import stylesModule from '../LandingPage.module.less';
import Link from 'next/link';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = stylesModule;

type Props = {
  testimonials: any[];
  bg?: string;
};

const TestimonialsCard: FC<any> = (props) => {
  return (
    <div className="group transition-all cursor-pointer border border-solid border-slate-200 hover:border-primary p-[36px] rounded-lg bg-white flex flex-col gap-4">
      {/*<Image src={DoubleQuotes} alt={'quote'} />*/}
      <i className="icon-double-quotes text-[#99dabd] text-[26px] group-hover:text-primary" />
      <div className="font-thin regular text-lg text-[#5B6272] max-h-[80px] min-h-[80px] overflow-hidden text-ellipsis">
        {props.description}
      </div>
      <div className="mt-4 flex gap-4 items-center">
        <div className="grow-0 shrink-0">
          <Image
            className="rounded-full"
            src={props.userImage}
            alt="testimonial"
            width={60}
            height={60}
          />
        </div>
        <div className="-mt-1 flex flex-col grow shrink overflow-hidden">
          <div className="text-xl font-bold whitespace-nowrap text-ellipsis overflow-hidden">
            {props.name}
          </div>
          <div className="line-clamp-2 text-base leading-6 text-[#495564] whitespace-nowrap text-ellipsis overflow-hidden">
            {props.designation} • {props.company}
          </div>
        </div>
      </div>
    </div>
  );
};
export const TestimonialsSection: FC<Props> = (props) => {
  const swipe = useRef<SwiperRef>(null);
  console.log(props.testimonials);
  return (
    <section
      className={`relative w-screen self-center flex justify-center  ${
        props.bg ? `bg-${props.bg}` : 'bg-[#F8F9FD]'
      } text-[#050931] mb-[60px]`}>
      <div className="w-full max-w-[1600px] px-[20px] py-[40px]">
        <Heading variant={2} className="text-center mb-10">
          Testimonials
        </Heading>
        <div className="relative flex justify-between items-center gap-4">
          <Button
            shape="circle"
            size={'large'}
            className="bg-white grow-0 shrink-0 hidden lg:block"
            onClick={() => swipe.current?.swiper.slidePrev()}>
            <ArrowLeftOutlined />
          </Button>
          <div className={`${styles.testimonialsSlider} grow shrink`}>
            <Swiper
              className={styles.swiperOverride}
              ref={swipe}
              // install Swiper modules
              modules={[Pagination, Autoplay, A11y]}
              spaceBetween={40}
              slidesPerView={'auto'}
              autoplay={{ delay: 5000 }}
              // loop={true}
              slidesPerGroup={1}
              pagination={{ clickable: true, dynamicMainBullets: 1, dynamicBullets: true }}
              // scrollbar={{ draggable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              breakpoints={{
                200: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 320px
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // when window width is >= 480px
                1200: {
                  slidesPerView: 'auto',
                  spaceBetween: 20,
                },
                // 1600: {
                //   slidesPerView: 4,
                //   spaceBetween: 40,
                // },
              }}
              onSlideChange={() => console.log('slide change')}>
              {props.testimonials.map((testimonial) => (
                <SwiperSlide className="lg:max-w-[396px]" key={testimonial.testimonialId}>
                  <div className="w-full h-[380px] xl:h-auto">
                    <TestimonialsCard {...testimonial} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Button
            shape="circle"
            size={'large'}
            className="bg-white grow-0 shrink-0 hidden lg:block"
            onClick={() => swipe.current?.swiper.slideNext()}>
            <ArrowRightOutlined />
          </Button>
        </div>
        <div className="w-full flex justify-center mt-8">
          <Link href={'/view-testimonials'}>
            <Button
              type="default"
              style={{ borderRadius: 8 }}
              className="group border-primary text-primary font-bold px-5 hover:bg-primary"
              size="middle">
              <div className="text-primary text-base leading-6 font-medium px-1px group-hover:text-white">
                View All
              </div>
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
