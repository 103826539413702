import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  CandidateLayout,
  DEFAULT_OG_IMAGES,
  SEO_COMMON,
} from '../../layout/candidate/CandidateLayout';
import { Button, Grid, message, Radio, Skeleton, Space, Spin, Typography } from 'antd';
import stylesModule from './dashboard.module.less';
import { DashboardProfileCard } from './DashboardProfileCard';
import { FileTextOutlined, LikeOutlined, WarningOutlined } from '@ant-design/icons';
import { PopularSectors } from '../home/home-components/PopularSectors';
import { JobShortlistCard, ShortlistCardProps } from './JobShortlistCard';
import { JobAlertCard, JobAlertProps } from './JobAlertCard';
import {
  useConnection,
  useEvents,
  useNotificationInfo,
  useUserInfo,
} from '../../connection/Application';
import { ResponsiveModal } from '../../components/ResponsiveModal/ResponsiveModal';
import JobApplyModalEx from '../../components/Applicant/Job/Apply/JobApplyModalEx';
import { JobDetailsCard } from '../search/components/JobDetailsCard';
import { FormModalExRef } from '../../components/formModal/FormModal';
import { FormSchema } from '../../components/form/Form';
import { useRouter } from 'next/router';
import { CandidateHomeData, JobSummary } from '../home/HomePage';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import _ from 'lodash';
import { AlertManagementView } from './AlertManagementView';
import { NextSeo } from 'next-seo';
import Utils from '../../utils/Utils';
import AlertsImage from '../../public/dashbord/Alerts.svg';
import NotificationsImage from '../../public/dashbord/Notifications.svg';
import ShortlistedImage from '../../public/dashbord/Shortlisted.svg';
import AppliedImage from '../../public/dashbord/Applied.svg';
import QuickProfileBuildModal, {
  useQuickProfileBuildModal,
} from '../../components/OneStep/QuickProfileBuildModal';
import Image from 'next/image';
import Link from 'next/link';
import { BlogView } from '../../components/BlogView/BlogView';
import blogcareers from './../../../public/blogcareers.png';
import { CompanyLogoList } from '../../components/companyLogoList/CompanyLogoList';
import { TestimonialView } from '../../components/TestimonialView/TestimonialView';
import { JobRecommendCard } from './JobRecommendCard';
import { LeaderBoardAd } from '../LeaderBoard/LeaderBoardAd';
import { TopHiringCompanies } from '../LandingPage/components/TopHiringCompanies';
import { TestimonialsSection } from '../LandingPage/components/TestimonialsSection';
import { PopularSection } from '../LandingPage/components/PopularSection';

const styles: any = stylesModule;

export type DashboardProps = {
  jobSummary: JobSummary[];
  tab: string;
};
const emailSchema: FormSchema = [
  {
    name: 'Subject',
    id: 'subject',
    type: 'string',
    props: { maxLength: 200 },
    options: {
      rules: [{ required: true, message: 'Subject is required' }],
    },
  },
  {
    name: 'Message',
    id: 'message',
    type: 'richText',
    props: {
      placeholder: 'Message to send',
      autosize: { minRows: 8, maxRows: 12 },
      maxLength: 4000,
    },
    options: {
      rules: [
        { required: true, message: 'Please enter the message to send.' },
        { max: 4000, message: 'Only 4000 characters are allowed.' },
      ],
    },
  },
];

export const EmptyItem: FC<{ tab: string; onPress: () => void }> = (props) => {
  let image;
  switch (props.tab) {
    case 'Applied':
      image = AppliedImage;
      break;
    case 'Shortlisted':
      image = ShortlistedImage;
      break;
    case 'Notifications':
      image = NotificationsImage;
      break;
    default:
      image = AlertsImage;
  }
  return (
    <Space align={'center'} direction={'vertical'} className={'fullwidth'}>
      <div className={styles.emptyImage}>
        <Image alt={'empty'} src={image} />
      </div>
      {props.tab === 'Applied' && (
        <Space direction={'vertical'} align={'center'} className={styles.emptyContent}>
          <TextBlock className={styles.emptyTextHeading} align={'center'}>
            Life is short.{' '}
            <TextSpan primary className={styles.emptyTextHeading}>
              Work
            </TextSpan>{' '}
            somewhere awesome!
          </TextBlock>
          <Button
            type={'primary'}
            size={'small'}
            onClick={props.onPress}
            className={styles.emptyContentButton}>
            START APPLYING NOW
          </Button>
        </Space>
      )}
      {props.tab === 'Shortlisted' && (
        <Space direction={'vertical'} align={'center'} className={styles.emptyContent}>
          <TextBlock className={styles.emptyTextHeading} align={'center'}>
            You haven’t saved any jobs yet
          </TextBlock>
          <TextBlock secondary align={'center'}>
            Don&apos;t have enough time to apply right now? or you need to give it a bit more
            thought? saved jobs will be listed here.
          </TextBlock>
          <Button
            type={'primary'}
            size={'small'}
            onClick={props.onPress}
            className={styles.emptyContentButton}>
            START EXPLORING NOW
          </Button>
        </Space>
      )}
      {props.tab === 'Notifications' && (
        <Space direction={'vertical'} align={'center'} className={styles.emptyContent}>
          <TextBlock className={styles.emptyTextHeading} align={'center'}>
            Welcome to{' '}
            <TextSpan className={styles.emptyTextHeading} primary>
              Careers360
            </TextSpan>
          </TextBlock>
          <TextBlock secondary align={'center'}>
            Welcome to the best place to start opening new doors and to get connected with boundless
            top career opportunities. We&apos;ll keep you updated on your latest activity and{' '}
            <TextSpan primary>Careers360</TextSpan> news!
          </TextBlock>
          <Button
            type={'primary'}
            size={'small'}
            onClick={props.onPress}
            className={styles.emptyContentButton}>
            DISCOVER NEW JOB OPENINGS
          </Button>
        </Space>
      )}
      {props.tab === 'Alerts' && (
        <Space direction={'vertical'} align={'center'} className={styles.emptyContent}>
          <TextBlock className={styles.emptyTextHeading} align={'center'}>
            You haven’t subscribed to any job alerts yet
          </TextBlock>
          <TextBlock secondary align={'center'}>
            Don&apos;t miss out on any lucky breaks that comes your way! turn on job alerts for your
            specific searches and get alerts here instantly/daily/weekly.
          </TextBlock>
          <Button
            type={'primary'}
            size={'small'}
            onClick={props.onPress}
            className={styles.emptyContentButton}>
            START EXPLORING NOW
          </Button>
        </Space>
      )}
    </Space>
  );
};

export const Dashboard: FC<DashboardProps> = (props) => {
  const [tab, setTab] = useState(props.tab);
  const [appliedData, setAppliedData] = useState<ShortlistCardProps[]>([]);
  const [shortlistData, setShortlistData] = useState<ShortlistCardProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [current, setCurrent] = useState<string>();
  const [modalVisible1, setModalVisible1] = useState<boolean>(false);
  const [jobVisible, setJobVisible] = useState<boolean>(false);
  const [withdrawVisible, setWithdrawVisible] = useState<boolean>(false);
  // const [showLoad, setShowLoad] = useState<boolean>(false);
  const [hasAppliedData, setHasAppliedData] = useState<boolean>(false);
  const [hasShortListedData, setHasShortListedData] = useState<boolean>(false);
  const [hasAlertData, setHasAlertData] = useState<boolean>(false);
  const [hasNotificationData, setHasNotificationData] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<any>();
  const [dashbordData, setDashbordData] = useState<any>({});
  const [jobRecommendedData, setJobRecommendedData] = useState<any[]>([]);
  const [jobAlertData, setJobAlertData] = useState<JobAlertProps[]>([]);
  const [editAlertVisible, setEditAlertVisible] = useState<boolean>(
    typeof window !== 'undefined' &&
      new URLSearchParams(window.location.search).get('unsub') === 'true',
  );
  const [withdrawing, setWithdrawing] = useState<boolean>(false);
  const notificationInfo = useNotificationInfo();
  const [spinDelete, setSpinDelete] = useState<boolean>(false);
  // const [jobAlerts, setJobAlerts] = useState<JobAlertManageProps[]>([]);
  // const [alertManagePage, setAlertManagePage] = useState<number>(1);
  // const [alertTotal, setAlertTotal] = useState<number>(0);
  // const [alertLoading, setAlertLoading] = useState<boolean>(false);

  const connection = useConnection();
  const user = useUserInfo();
  const events = useEvents();
  const router = useRouter();
  const form = useRef<FormModalExRef>(null);
  const scrollRef = useRef<any>();

  const { query } = router;
  const { tab: currentTab } = query;
  const bp = Grid.useBreakpoint();
  const quick = useQuickProfileBuildModal();
  const lastTab = useRef<string>('');
  const lastApplicationModifiedTime = useRef<number>(0);
  const lastNotificationTime = useRef<number>(0);
  const lastAlertTime = useRef<number>(0);
  const [Data, setData] = useState([]);
  const [LandingData, setLandingData] = useState<any>([]);
  const [testidata, setTestidata] = useState<CandidateHomeData>();
  const history = useRouter();

  // const [nextStartItem, setNextStartItem] = useState<number>(9);

  useEffect(() => {
    setTab((currentTab as any) ?? 'Applied');
  }, [currentTab]);

  useEffect(() => {
    setLoading(true);
    connection
      .get('home/candidate')
      .then((v) => {
        console.log({ v });
        setTestidata(v);
      })
      .catch((e) => console.log(e))
      .finally(async () => {
        const landingPageData = await Utils.getLandingPageData();
        if (landingPageData) {
          setLandingData(landingPageData);
        } else {
          connection
            .get(`home/landing`)
            .then((v) => {
              setLandingData(v);
            })
            .catch((e) => console.log(e))
            .finally(() => setLoading(false));
        }
      });
  }, [connection]);

  const reload = useCallback(() => {
    setLoading(true);
    setPage(1);
    setTotal(0);
    if (tab === 'Applied') {
      setAppliedData([]);
      connection
        .get(`users/applied/${user?.userId}`, { page: 1 })
        .then((response) => {
          response.total > response.applied.length
            ? setHasAppliedData(true)
            : setHasAppliedData(false);
          setAppliedData(response.applied);
          if (response.applied.length > 0) {
            lastApplicationModifiedTime.current = response.applied[0].modifiedTime;
          }
          setTotal(response.total);
        })
        .catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
    if (tab === 'Shortlisted') {
      setShortlistData([]);
      setLoading(true);
      setPage(1);
      setTotal(0);
      connection
        .get(`users/shortlisted/${user?.userId}`, { page: 1 })
        .then((response) => {
          response.total > response.shortlisted.length
            ? setHasShortListedData(true)
            : setHasShortListedData(false);
          setShortlistData(response.shortlisted);
          setTotal(response.total);
        })
        .catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
    if (tab === 'Recommendation') {
      setJobRecommendedData([]);
      setLoading(true);
      setPage(1);
      setTotal(0);
      connection
        .get('job-recommend')
        .then((response) => {
          setJobRecommendedData(response.jobRecommendedData);
          setTotal(response.jobRecommendedData.length);
        })
        .catch((e) => console.log('error', e))
        .finally(() => {
          setLoading(false);
          // events.emit('NOTIFICATION_RESET', { count: 0 });
        });
    }
    if (tab === 'Alerts') {
      setLoading(true);
      setPage(1);
      setTotal(0);
      setJobAlertData([]);
      connection
        .get(`job-post/alerts/${user?.userId}`, { start: 0, size: 10 })
        .then((value) => {
          console.log('value', value);
          setJobAlertData(value.alerts);
          setHasAlertData(value.total > 10);
          if (value.alerts.length > 0) {
            lastAlertTime.current = value.alerts[0].time;
          }
        })
        .catch((e) => alert(e))
        .finally(() => setLoading(false));
    }
  }, [connection, tab, user?.userId]);

  useEffect(() => {
    if (lastTab.current !== tab) {
      reload();
      lastTab.current = tab;
      return;
    }
    if (
      tab === 'Applied' &&
      notificationInfo.notificationData.applicationData !== lastApplicationModifiedTime.current
    ) {
      reload();
    } else if (tab === 'Shortlisted') {
      reload();
    } else if (
      tab === 'Notifications' &&
      notificationInfo.notificationData.notificationData !== lastNotificationTime.current
    ) {
      reload();
    }
    // else if (
    //   tab === 'Alerts'
    //   // notificationInfo.notificationData.alertData !== lastAlertTime.current
    // ) {
    //   reload();
    // }
  }, [reload, notificationInfo.notificationData, tab]);

  // useEffect(() => {
  //   setAlertLoading(true);
  //   connection
  //     .get('job-alert', { page: alertManagePage })
  //     .then((response) => {
  //       setJobAlerts(response.alertData);
  //       setAlertTotal(response.total);
  //     })
  //     .catch((e) => console.log('eror', e))
  //     .finally(() => setAlertLoading(false));
  // }, [alertManagePage, connection]);

  useEffect(() => {
    setLoading(true);
    connection
      .get('blogs', { active: true })
      .then((v) => {
        console.log({ v });
        setData(v);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, [connection]);

  useEffect(() => {
    connection
      .get(`users/${user?.userId}/dashboard-data`)
      .then((res) => {
        setDashbordData(res);
        if (res.showQuick) {
          quick.current?.show();
        }
      })
      .catch((e) => console.log(e));
  }, [quick, connection, user?.userId]);

  const handleApplied = useCallback(
    ({ jobPostId }) => {
      if (shortlistData !== undefined) {
        const shortlistIndex = shortlistData.findIndex((v) => v.jobPostId === jobPostId);
        if (shortlistIndex !== -1) {
          const shortlist = [...shortlistData];
          shortlist[shortlistIndex].applied =
            shortlist[shortlistIndex].receiveApplications === 'portal';
          shortlist[shortlistIndex].applicantCount =
            (shortlist[shortlistIndex].applicantCount ?? 0) + 1;
        }
      }
      if (appliedData) {
        const appliedIndex = appliedData.findIndex((v) => v.jobPostId === jobPostId);
        if (appliedIndex !== -1) {
          const applied = [...appliedData];
          applied[appliedIndex].applied = true;
          applied[appliedIndex].applicantCount = (applied[appliedIndex].applicantCount ?? 0) + 1;
          applied.push(applied[appliedIndex]);
          setAppliedData(applied);
        }
      }
      const temp = { ...selectedJob };
      temp.applied = true;
      setSelectedJob(temp);
    },
    [appliedData, selectedJob, shortlistData],
  );

  const handleWithdrawn = useCallback(
    ({ jobPostId }) => {
      const appliedIndex = appliedData.findIndex((v) => v.jobPostId === jobPostId);
      const shortlistIndex = shortlistData.findIndex((v) => v.jobPostId === jobPostId);

      if (appliedIndex !== -1) {
        const applied = [...appliedData];
        applied[appliedIndex].applied = false;
        applied[appliedIndex].applicantCount = (applied[appliedIndex].applicantCount ?? 1) - 1;
        setAppliedData(applied);
      }

      if (shortlistIndex !== -1) {
        const shortlist = [...shortlistData];
        shortlist[shortlistIndex].applied = false;
        shortlist[shortlistIndex].applicantCount =
          (shortlist[shortlistIndex].applicantCount ?? 1) - 1;
        setShortlistData(shortlist);
      }
      const temp = { ...selectedJob };
      temp.applied = false;
      setSelectedJob(temp);
    },
    [appliedData, selectedJob, shortlistData],
  );

  const handleSaved = useCallback(
    ({ jobPostId }) => {
      const appliedIndex = appliedData.findIndex((v) => v.jobPostId === jobPostId);
      const shortlistIndex = shortlistData.findIndex((v) => v.jobPostId === jobPostId);

      if (appliedIndex !== -1) {
        const applied = [...appliedData];
        applied[appliedIndex].shortlisted = true;
        setAppliedData(applied);
      }

      if (shortlistIndex !== -1) {
        const shortlist = [...shortlistData];
        shortlist[shortlistIndex].shortlisted = true;
        setShortlistData(shortlist);
      }
    },
    [appliedData, shortlistData],
  );

  const handleUnsaved = useCallback(
    ({ jobPostId }) => {
      const appliedIndex = appliedData.findIndex((v) => v.jobPostId === jobPostId);
      const shortlistIndex = shortlistData.findIndex((v) => v.jobPostId === jobPostId);

      if (appliedIndex !== -1) {
        const applied = [...appliedData];
        applied[appliedIndex].shortlisted = false;
        setAppliedData(applied);
      }

      if (shortlistIndex !== -1) {
        const shortlist = [...shortlistData];
        shortlist[shortlistIndex].shortlisted = false;
        setShortlistData(shortlist);
      }
    },
    [appliedData, shortlistData],
  );

  useEffect(() => {
    events.on('JOB_APPLIED', handleApplied);
    events.on('JOB_WITHDRAWN', handleWithdrawn);
    events.on('JOB_SAVED', handleSaved);
    events.on('JOB_UNSAVED', handleUnsaved);
    return () => {
      events.off('JOB_APPLIED', handleApplied);
      events.off('JOB_WITHDRAWN', handleWithdrawn);
      events.off('JOB_SAVED', handleSaved);
      events.off('JOB_UNSAVED', handleUnsaved);
    };
  }, [events, handleApplied, handleSaved, handleUnsaved, handleWithdrawn]);

  const onpaginationX = useCallback(() => {
    // setPage(page);
    setLoading(true);
    if (tab === 'Applied') {
      connection
        .get(`users/applied/${user?.userId}`, { page: page + 1 })
        .then((response) => {
          const appliedDataX = [...appliedData, ...response.applied];
          setAppliedData(appliedDataX);
          setTotal(response.total);
          response.total > appliedDataX.length ? setHasAppliedData(true) : setHasAppliedData(false);
        })
        .catch((e) => console.log('error', e))
        .finally(() => {
          setPage(page + 1);
          setLoading(false);
        });
    }
    if (tab === 'Shortlisted') {
      connection
        .get(`users/shortlisted/${user?.userId}`, { page: page + 1 })
        .then((response) => {
          const shortlistDataX = [...shortlistData, ...response.shortlisted];
          setShortlistData(shortlistDataX);
          setTotal(response.total);
          response.total > shortlistDataX.length
            ? setHasShortListedData(true)
            : setHasShortListedData(false);
        })
        .catch((e) => console.log('error', e))
        .finally(() => {
          setPage(page + 1);
          setLoading(false);
        });
    }
  }, [appliedData, connection, page, shortlistData, tab, user?.userId]);

  const applyJob = useCallback((job) => {
    setCurrent(job);
    setModalVisible1(true);
  }, []);

  const showJob = useCallback((item) => {
    setSelectedJob(item);
    setJobVisible(true);
  }, []);
  const withdrawJob = useCallback((jobId) => {
    setCurrent(jobId);
    setWithdrawVisible(true);
  }, []);

  const withdraw = useCallback(() => {
    setWithdrawing(true);
    connection
      .delete(`job-apply/${current}`)
      .then(() => {
        setWithdrawVisible(false);
        handleWithdrawn({ jobPostId: current });
        setJobVisible(false);
        message.success('Withdraw success');
      })
      .catch((e) => {
        setWithdrawVisible(false);
        message.error(e.message);
      })
      .finally(() => setWithdrawing(false));
  }, [connection, current, handleWithdrawn]);

  const eyeballSend = useCallback(
    (job: any) => {
      setSelectedJob(job);
      form.current?.edit('Send Email', emailSchema).onSave((data) => {
        console.log(data);
        job.message = data.message;
        job.subject = data.subject;
        connection
          .post(`eyeball/candidate-reply/${user?.userId}`, job)
          .then(() => message.success('Message Sent Successfully'))
          .catch(() => message.error('Sorry! Something went Wrong'))
          .finally(() => form.current?.close());
      });
    },
    [connection, user?.userId],
  );

  const loadMore = useCallback(() => {
    if (tab === 'Recommendation') {
      setLoading(true);
      const lastTime = page * 10;
      connection
        .get('job-recommend', { start: lastTime })
        .then((response) => {
          const newRecommended = [...jobRecommendedData, ...response.jobRecommendedData];
          setJobRecommendedData(newRecommended);
          setTotal(total + 10);
        })
        .catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
    if (tab === 'Alerts') {
      setLoading(true);
      const nextLoadStartItem = page * 10;
      console.log('nextLoadStartItem', nextLoadStartItem);
      connection
        .get(`job-post/alerts/${user?.userId}`, { start: nextLoadStartItem, size: 10 })
        .then((response) => {
          const newAlert = [...jobAlertData, ...response.alerts];
          setJobAlertData(newAlert);
          setPage(page + 1);
          setHasAlertData(newAlert.length < response.total);
        })
        .catch((e) => console.log('error', e))
        .finally(() => setLoading(false));
    }
  }, [connection, jobAlertData, jobRecommendedData, page, tab, total, user?.userId]);

  const deleteJobAlert = useCallback(
    async (data) => {
      setSpinDelete(true);
      if (data.read === false) {
        notificationInfo.setNotificationData({
          ...notificationInfo.notificationData,
          alertCount: notificationInfo.notificationData.alertCount - 1,
          total: notificationInfo.notificationData.total - 1,
        });
      }
      try {
        await connection.delete('job-alert/delete-alert', data).then(() => console.log('deleted'));
        const response = await connection.get(`job-post/alerts/${user?.userId}`, {
          start: 0,
          size: 10,
        });
        if (response.alerts.length > 0) {
          setJobAlertData(response.alerts);
        }
        setHasAlertData(response.alerts?.length < response.total);
        setSpinDelete(false);
        message.success('Alert Deleted');
      } catch (e) {
        setSpinDelete(false);
      }
    },
    [connection, notificationInfo, user?.userId],
  );

  const getJobPost = useCallback(
    (jobPostId) => {
      connection.get(`job-post/byid/${jobPostId}`).then((value) => {
        setSelectedJob(value.jobPost);
        setJobVisible(true);
      });
    },
    [connection],
  );
  const markAsRead = useCallback(
    (type: 'Notification' | 'Alert' | 'Application', data: any) => {
      connection
        .patch(`notification/mark-as-read`, { type, ...data })
        .then(({ count }) => {
          notificationInfo.setNotificationData(count);
        })
        .catch((e) => console.log('eror', e));
    },
    [connection, notificationInfo],
  );
  const markApplicationRead = (item: any, index: number) => {
    if (item.read !== false) {
      return;
    }
    const newAppliedData = [...appliedData];
    newAppliedData[index] = { ...appliedData[index], read: true };
    setAppliedData(newAppliedData);
    notificationInfo.setNotificationData({
      ...notificationInfo.notificationData,
      applicationCount: notificationInfo.notificationData.applicationCount - 1,
      total: notificationInfo.notificationData.total - 1,
    });
    markAsRead('Application', { jobPostId: item.jobPostId, userId: user!.userId });
  };

  const markAlertRead = (item: any, index: number) => {
    if (item.read !== false) {
      return;
    }
    const newJobAlertData = [...jobAlertData];
    newJobAlertData[index] = { ...jobAlertData[index], read: true };
    setJobAlertData(newJobAlertData);
    notificationInfo.setNotificationData({
      ...notificationInfo.notificationData,
      alertCount: notificationInfo.notificationData.alertCount - 1,
      total: notificationInfo.notificationData.total - 1,
    });
    markAsRead('Alert', {
      jobPostId: item.jobPostId,
      relationId: item.relationId,
      time: item.time,
      userId: user!.userId,
    });
  };
  useEffect(() => {
    if (
      (tab === 'Recommendation' || tab === 'Alerts') &&
      scrollRef.current !== undefined &&
      bp.xs
    ) {
      scrollRef.current.scrollLeft += 600;
    }
  }, [bp.xs, tab]);

  return (
    <CandidateLayout
      title={
        <NextSeo
          title={`Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format(
            'YYYY',
          )} | Careers360`}
          description="Careers360 is the most popular job portal for jobs in Sri Lanka, and to find out the best top job vacancies in Sri Lanka to your career next growth step"
          additionalMetaTags={[{ name: 'keywords', content: 'Jobs in Sri Lanka, Careers360' }]}
          openGraph={{
            title: `Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format(
              'YYYY',
            )} | Careers360`,
            description:
              'Careers360 is the most popular job portal for jobs in Sri Lanka, and to find out the best top job vacancies in Sri Lanka to your career next growth step',
            images: DEFAULT_OG_IMAGES,
          }}
          {...SEO_COMMON}
        />
      }
      showSearch>
      <div className={`${styles.c360} ${styles.mainContainer}`}>
        <div className={styles.contentContainer}>
          <div className={styles.topContent}>
            <Space size={'large'} direction={'vertical'} className="flex-1">
              {(bp.lg || bp.md || bp.sm) && (
                <Radio.Group
                  size="large"
                  buttonStyle={'solid'}
                  value={tab}
                  className={styles.buttonBar}
                  onChange={(e) => {
                    setTab(e.target.value);
                    router.push({ query: { tab: e.target.value } }, undefined, { shallow: true });
                  }}>
                  <Radio.Button value="Applied" id={'my-dashboard-tab-application'}>
                    <Space className={styles.tabText}>
                      {(bp.xxl || !bp.sm) && <FileTextOutlined />}
                      {bp.sm && (
                        <TextBlock style={{ whiteSpace: 'nowrap' }}>My Applications</TextBlock>
                      )}
                      {(notificationInfo.notificationData.applicationCount ?? 0) !== 0 &&
                        `(${notificationInfo.notificationData.applicationCount})`}
                    </Space>
                  </Radio.Button>
                  <Radio.Button value="Shortlisted" id={'my-dashboard-tab-saved'}>
                    <Space className={styles.tabText}>
                      {(bp.xxl || !bp.sm) && <FontAwesomeIcon icon={faBriefcase} />}
                      {bp.sm && <TextBlock style={{ whiteSpace: 'nowrap' }}>Saved Jobs</TextBlock>}
                      {/*{(dashbordData?.dashboardCount?.shortlistedCount ?? 0) !== 0 &&*/}
                      {/*  `(${dashbordData?.dashboardCount?.shortlistedCount ?? 0})`}*/}
                    </Space>
                  </Radio.Button>
                  <Radio.Button value="Recommendation" id={'my-dashboard-tab-recommendation'}>
                    <Space className={styles.tabText}>
                      {(bp.xxl || !bp.sm) && <LikeOutlined />}
                      {bp.sm && (
                        <TextBlock style={{ whiteSpace: 'nowrap' }}>Job Recommendations</TextBlock>
                      )}
                    </Space>
                  </Radio.Button>
                  <Radio.Button value="Alerts" id={'my-dashboard-tab-alerts'}>
                    <Space className={styles.tabText}>
                      {(bp.xxl || !bp.sm) && <WarningOutlined />}
                      {bp.sm && <TextBlock style={{ whiteSpace: 'nowrap' }}>Job Alerts</TextBlock>}
                      {(notificationInfo?.notificationData?.alertCount ?? 0) !== 0 &&
                        `(${notificationInfo?.notificationData?.alertCount ?? 0})`}
                    </Space>
                  </Radio.Button>
                </Radio.Group>
              )}

              {bp.xs && (
                <div className={styles.buttonBarMobile} ref={scrollRef}>
                  <Link
                    href={{ pathname: '/dashboard', query: { tab: 'Applied' } }}
                    shallow={true}
                    id={'my-dashboard-m-link-application'}>
                    <Button
                      type={tab === 'Applied' ? 'primary' : 'default'}
                      shape="round"
                      onClick={() => setTab('Applied')}
                      className={styles.tabButtonMobile}>
                      My Applications
                    </Button>
                  </Link>
                  <Link
                    href={{ pathname: '/dashboard', query: { tab: 'Shortlisted' } }}
                    id={'my-dashboard-m-link-saved'}
                    shallow={true}>
                    <Button
                      type={tab === 'Shortlisted' ? 'primary' : 'default'}
                      shape="round"
                      onClick={() => setTab('Shortlisted')}
                      className={styles.tabButtonMobile}>
                      Saved Jobs
                    </Button>
                  </Link>
                  <Link
                    href={{ pathname: '/dashboard', query: { tab: 'Recommendation' } }}
                    id={'my-dashboard-m-link-recommendation'}
                    shallow={true}>
                    <Button
                      type={tab === 'Recommendation' ? 'primary' : 'default'}
                      shape="round"
                      onClick={() => setTab('Recommendation')}
                      className={styles.tabButtonMobile}>
                      Job Recommendations
                    </Button>
                  </Link>
                  <Link
                    href={{ pathname: '/dashboard', query: { tab: 'Alerts' } }}
                    shallow={true}
                    id={'my-dashboard-m-link-alert'}>
                    <Button
                      type={tab === 'Alerts' ? 'primary' : 'default'}
                      shape="round"
                      onClick={() => setTab('Alerts')}
                      className={styles.tabButtonMobile}>
                      Job Alerts
                    </Button>
                  </Link>
                </div>
              )}

              <div className={styles.tabContent}>
                {tab === 'Applied' &&
                  appliedData.map((item, index) => {
                    return (
                      <JobShortlistCard
                        data={item}
                        key={item.jobPostId}
                        onView={() => showJob(item)}
                        onApply={() => applyJob(item.jobPostId)}
                        onWithDraw={() => withdrawJob(item.jobPostId)}
                        onClick={() => markApplicationRead(item, index)}
                      />
                    );
                  })}
                {tab === 'Shortlisted' &&
                  shortlistData.map((item) => {
                    return (
                      <JobShortlistCard
                        data={{ ...item, read: true }}
                        key={item.jobPostId}
                        onApply={() => applyJob(item.jobPostId)}
                        onView={() => showJob(item)}
                        onWithDraw={() => withdrawJob(item.jobPostId)}
                      />
                    );
                  })}
                {tab === 'Recommendation' &&
                  jobRecommendedData.map((item) => {
                    return (
                      <JobRecommendCard
                        data={item}
                        key={item.time}
                        onView={() => getJobPost(item.jobPostId)}
                      />
                    );
                  })}
                {tab === 'Alerts' && (
                  <Spin spinning={spinDelete}>
                    {jobAlertData.map((item, index) => {
                      return (
                        <JobAlertCard
                          data={item}
                          key={`${item?.jobPostId}-${item?.relationId}`}
                          onDelete={() => deleteJobAlert(item)}
                          onView={() => getJobPost(item.jobPostId)}
                          onRead={() => markAlertRead(item, index)}
                        />
                      );
                    })}
                  </Spin>
                )}
                {loading &&
                  _.fill(Array(10), null).map((_, index) => {
                    return (
                      <Skeleton avatar active key={index}>
                        <div>Test</div>
                      </Skeleton>
                    );
                  })}
                {!loading && tab === 'Applied' && appliedData.length === 0 && (
                  // <Space direction={'vertical'} align={'start'} className={'fullwidth'}>
                  //   <TextBlock heading level={-1} className={'fullwidth'} align={'center'}>
                  //     You haven’t applied to any jobs yet
                  //   </TextBlock>
                  //   <TextBlock level={-1} className={'fullwidth'} align={'center'}>
                  //     All jobs you apply to will be listed here. Companies can update the status of
                  //     your application to display here if they wish.
                  //   </TextBlock>
                  // </Space>
                  <EmptyItem tab={'Applied'} onPress={() => router.push('/jobs')} />
                )}
                {!loading && tab === 'Shortlisted' && shortlistData.length === 0 && (
                  // <Space direction={'vertical'} align={'start'} className={'fullwidth'}>
                  //   <TextBlock heading level={-1} className={'fullwidth'} align={'center'}>
                  //     You haven’t saved any jobs yet
                  //   </TextBlock>
                  //   <TextBlock level={-1} className={'fullwidth'} align={'center'}>
                  //     {/* eslint-disable-next-line react/no-unescaped-entities */}
                  //     Don't have enough time to apply right now? Or you need to give it a bit more
                  //     thought? Saved jobs will be listed here.
                  //   </TextBlock>
                  // </Space>
                  <EmptyItem tab={'Shortlisted'} onPress={() => router.push('/jobs')} />
                )}
                {!loading && tab === 'Recommendation' && jobRecommendedData.length === 0 && (
                  // <Space direction={'vertical'} align={'start'} className={'fullwidth'}>
                  //   <TextBlock heading level={-1} className={'fullwidth'} align={'center'}>
                  //     No notifications available
                  //   </TextBlock>
                  //   <TextBlock level={-1} className={'fullwidth'} align={'center'}>
                  //     {/* eslint-disable-next-line react/no-unescaped-entities */}
                  //     You aren't very active right now are you?
                  //   </TextBlock>
                  // </Space>
                  <EmptyItem tab={'Notifications'} onPress={() => router.push('/jobs')} />
                )}
                {!loading && tab === 'Alerts' && jobAlertData.length === 0 && (
                  // <Space direction={'vertical'} align={'start'} className={'fullwidth'}>
                  //   <TextBlock heading level={-1} className={'fullwidth'} align={'center'}>
                  //     You haven’t subscribed to any job alerts yet
                  //   </TextBlock>
                  //   <TextBlock level={-1} className={'fullwidth'} align={'center'}>
                  //     {/* eslint-disable-next-line react/no-unescaped-entities */}
                  //     Don't miss out on any lucky breaks that comes your way! Turn on job alerts for
                  //     your specific searches and get alerts here Instantly/Daily/Weekly.
                  //   </TextBlock>
                  // </Space>
                  <EmptyItem tab={'Alerts'} onPress={() => router.push('/jobs')} />
                )}
                {tab === 'Alerts' && (
                  <div className={styles.manageAlertButton}>
                    {!hasAlertData && <div />}
                    {hasAlertData && (
                      <Button type={'primary'} size={'small'} onClick={loadMore}>
                        Load More
                      </Button>
                    )}
                    {!dashbordData?.hasJobAlerts && <div />}
                    {dashbordData?.hasJobAlerts && (
                      <Button
                        type={'primary'}
                        size={'small'}
                        onClick={() => setEditAlertVisible(true)}>
                        Manage Alerts
                      </Button>
                    )}
                  </div>
                )}

                {((tab === 'Applied' && hasAppliedData) ||
                  (tab === 'Shortlisted' && hasShortListedData) ||
                  (tab === 'Recommendation' && hasNotificationData)) && (
                  <div className={styles.manageAlertButton}>
                    <Button
                      type={'primary'}
                      size={'small'}
                      onClick={tab === 'Recommendation' ? loadMore : onpaginationX}>
                      Load More
                    </Button>
                  </div>
                )}
              </div>
              <div className={'mt-16'}>
                <LeaderBoardAd url={'/leaderboard'} />
              </div>
            </Space>
            <div className={styles.sideContainer}>
              <div>{dashbordData && <DashboardProfileCard data={dashbordData as any} />}</div>
            </div>
          </div>

          <div className={styles.popularSector}>
            {/* <PopularSectors jobSummary={props.jobSummary} /> */}
            <PopularSection
              sectors={LandingData.sectors ?? []}
              functions={LandingData.jobFunctions ?? []}
              employers={LandingData.topHiring ?? []}
            />
          </div>

          <div className={styles.blogpost} style={{ paddingTop: '7%' }}>
            <div className={styles.homepageColumn}>
              <div className={styles.homepageRowContainer}>
                <p style={{ textAlign: 'center' }}>
                  Latest <span className={styles.greenText}> Blog </span> Posts
                </p>
                <BlogView items={Data} />
              </div>
            </div>

            <div className={styles.blogpostimage}>
              <Link href={'https://blog.careers360.lk/'} target={'_blank'}>
                <Image unoptimized className="w-full h-auto" alt="blog" src={blogcareers} />
              </Link>
            </div>
          </div>

          {/*<div className={styles.homepageRows} style={{ paddingTop: '7%' }}>*/}
          {/*  <div className={styles.homepageRowContainer}>*/}
          {/*    <p className={styles.partners}>*/}
          {/*      Latest <span className={styles.greenText}> Partners </span> Onboarded*/}
          {/*    </p>*/}
          {/*    <CompanyLogoList logos={testidata?.recruiters ?? []} />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={Utils.mergeClasses(styles.homepageRowContainer, 'pt-[7%]', 'mt-[40px]')}>
            {/* <p className={styles.partners}>
              Latest <span className={styles.greenText}> Partners </span> Onboarded
            </p> */}
            {/* <CompanyLogoList logos={testidata?.recruiters ?? []} logosperslide={12} /> */}
            {/* <>{console.log('LandingData', LandingData)}</>
                <TopHiringCompanies companies={LandingData.topHiring} /> */}
            <>{console.log('LandingData', LandingData)}</>
            <TopHiringCompanies companies={LandingData.topHiring ?? []} />

            <TestimonialsSection bg={'#ffffff'} testimonials={LandingData.testimonials ?? []} />
          </div>
          {/* <div className={styles.recruiterRows}>
            <div className={styles.RowContainer}>
              <p className={styles.Testimonials}>Testimonials</p>
              <div style={{ width: '100%', alignSelf: 'center' }}>
                <div className={styles.testimonialView}>
                  {loading && <Spin />}

                   <TestimonialView items={testidata?.testimonials ?? []} slideCount={true} />
                </div>
              </div>
              <div className={styles.btnContainer} style={{ alignSelf: 'center' }}>
                <Button
                  type={'primary'}
                  onClick={() => history.push('/view-testimonials')}
                  className={styles.btnPrimary}>
                  <a href={'/view-testimonials'} className={styles.btnText}>
                    View All
                  </a>
                </Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/*<ResponsiveModal*/}
      {/*  visible={visible}*/}
      {/*  onCancel={() => setVisible(false)}*/}
      {/*  footer={false}*/}
      {/*  destroyOnClose*/}
      {/*  maskClosable={true}*/}
      {/*  closable={true}*/}
      {/*  width={996}>*/}
      {/*  <RecruiterProfileView recruiterId={props.data?.recruiterId} />*/}
      {/*</ResponsiveModal>*/}
      <ResponsiveModal
        width={'35%'}
        title={'Withdraw Application'}
        onCancel={() => setWithdrawVisible(false)}
        onOk={() => withdraw()}
        okText={'Withdraw'}
        okType={'danger'}
        cancelText={'Cancel'}
        open={withdrawVisible}
        destroyOnClose
        maskClosable={true}
        closable={true}
        confirmLoading={withdrawing}
        centered>
        <Typography>Are you sure you want to withdraw this application?</Typography>
      </ResponsiveModal>
      {current && (
        <JobApplyModalEx
          jobPostId={current as string}
          visible={modalVisible1}
          onClose={() => {
            setModalVisible1(false);
          }}
        />
      )}
      {selectedJob && (
        <ResponsiveModal
          width={1200}
          footer={false}
          onCancel={() => setJobVisible(false)}
          open={jobVisible}
          title={`${selectedJob?.jobTitle} for ${selectedJob?.recruiterName}`}>
          <JobDetailsCard value={selectedJob as any} />
        </ResponsiveModal>
      )}
      {tab === 'Alerts' && (
        <ResponsiveModal
          width={1000}
          open={editAlertVisible ?? false}
          title={'Manage Job Alerts'}
          onCancel={() => setEditAlertVisible(false)}
          footer={false}>
          <AlertManagementView />
        </ResponsiveModal>
      )}
      <QuickProfileBuildModal ref={quick} />
      {/*<div className={styles.bestWeb}>*/}
      {/*  <a*/}
      {/*    rel="noreferrer"*/}
      {/*    href="https://www.vote.bestweb.lk/site/www_careers360_lk/"*/}
      {/*    target="_blank">*/}
      {/*    <img src="https://www.bw2022.lk/vote4us.png" alt="logo" width="100%" height="100%" />*/}
      {/*  </a>*/}
      {/*</div>*/}
    </CandidateLayout>
  );
};
