import React, { FC } from 'react';
import Image from 'next/image';
import stylesModule from './CandidateLayout.module.less';
import { Text } from '../../components/Typogrphy/Text';
import Logo from '../../public/logo.svg';
import { Title } from '../../components/Typogrphy/Title';
import CallIcon from '../../public/phone-call-footer.svg';
import MailIcon from '../../public/mail-footer.svg';
import LocationIcon from '../../public/map-pin-footer.svg';
import { TextWithImage } from '../../components/TextWithImage/TextWithImage';
import FbIcon from '../../public/facebook.svg';
import LinkedinIcon from '../../public/linkedin.svg';
import TwitterIcon from '../../public/twitter.svg';
import InstagramIcon from '../../public/instagram.svg';
import YoutubeIcon from '../../public/youtube.svg';
import PinterestIcon from '../../public/pinterest.svg';
import Link from 'next/link';
import moment from 'moment';
import Utils from '../../utils/Utils';

const styles: any = stylesModule;

const FooterLink: FC<{ title: string; url: string; id?: string }> = ({ title, url, id }) => {
  return (
    <div className={styles.footerlinks}>
      <Link href={url} id={id}>
        <Text>{title}</Text>
      </Link>
    </div>
  );
};

export const CandidateFooter: FC = () => {
  return (
    <footer>
      {/*© Copyright 2021, All rights reserved*/}
      <div className={styles.footerMain}>
        <div className={styles.footerImagemobile}>
          <img
            className={'w-[180px] sm:w-auto'}
            src="https://c360-qa-files.s3.us-east-2.amazonaws.com/files/footer_image.png"
            alt="careers360"
          />
        </div>
        <div className={styles.footerImage}>
          {/*<Image src={FooterImage} layout={'responsive'} />*/}
        </div>

        <div className={styles.footerContentContainer}>
          <div className={styles.logoContainerView}>
            <div className={styles.logoContainer}>
              <Link href={'/'}>
                <Image
                  className={styles.logo}
                  src={Logo}
                  width={221}
                  height={50}
                  alt={'Careers360 Logo'}
                />
              </Link>
            </div>
            <Text style={{ marginTop: 8, marginBottom: 40 }}>Looking to get in touch with us?</Text>
            <div className={styles.contactContent}>
              <TextWithImage
                alignIcon="center"
                content={<a href="tel:+94727360360">+94 727 360 360</a>}
                image={CallIcon}
              />
              <TextWithImage
                alignIcon="center"
                content={<a href="mailto:info@careers360.lk">info@careers360.lk</a>}
                image={MailIcon}
              />
              <TextWithImage
                content={<p className={'mt-0'}>65, Jethawana Road, Colombo 14, Sri Lanka</p>}
                image={LocationIcon}
              />
            </div>
          </div>
          <div className={styles.footerMainLeft}>
            <div
              className={styles.footerContentTop}
              style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={'w-[160px] xs:w-[170px] sm:w-auto'}>
                <Title level={4}>Careers360</Title>
                <FooterLink title={'About Us'} url={'/about-us'} id={'footer-link-about-us'} />
                <FooterLink
                  title={'Blog'}
                  url={'https://blog.careers360.lk'}
                  id={'footer-link-blog'}
                />
                <FooterLink
                  title={'Success Stories'}
                  url={'/success-stories'}
                  id={'footer-link-success-stories'}
                />
              </div>
              <div className={'w-[160px] xs:w-[170px] sm:w-auto'}>
                <Title level={4}>Product</Title>
                <FooterLink title={'Pricing'} url={'/pricing'} id={'footer-link-pricing'} />
                <FooterLink title={'Features'} url={'/features'} id={'footer-link-features'} />
                <FooterLink
                  title={'Integrations'}
                  url={'/integrations'}
                  id={'footer-link-integrations'}
                />
                <FooterLink title={'FAQ'} url={'/faq'} id={'footer-link-faq'} />
              </div>
              <div className={'w-[170px] sm:w-auto'}>
                <Title level={4}>Legal</Title>
                <FooterLink
                  title={'Terms of Service'}
                  url={'/terms-of-service'}
                  id={'footer-link-terms-of-service'}
                />
                <FooterLink
                  title={'Privacy Policy'}
                  url={'/privacy-policy'}
                  id={'footer-link-privacy-policy'}
                />
                <FooterLink title={'Your Security'} url={'/security'} id={'footer-link-security'} />
              </div>
              <div className={'w-[160px] xs:w-[170px] sm:w-auto'}>
                <Title level={4}>Contact</Title>
                <FooterLink title={'Contact Us'} url={'/contactus'} id={'footer-link-contact-us'} />
                <FooterLink
                  title={'Press Center'}
                  url={'/press-center'}
                  id={'footer-link-press-center'}
                />
              </div>
            </div>
            <div className={styles.socialIcons}>
              <a href={Utils.getSocialMediaLink('fb')} target={'_blank'} rel="noreferrer">
                <Image src={FbIcon} width={36} alt={'Facebook Icon'} />
              </a>
              <a href={Utils.getSocialMediaLink('LinkedIn ')} target={'_blank'} rel="noreferrer">
                <Image src={LinkedinIcon} width={36} alt={'Linkedin Icon'} />
              </a>
              <a href={Utils.getSocialMediaLink('twitter')} target={'_blank'} rel="noreferrer">
                <Image src={TwitterIcon} width={36} alt={'Twitter Icon'} />
              </a>
              <a href={Utils.getSocialMediaLink('ig')} target={'_blank'} rel="noreferrer">
                <Image src={InstagramIcon} width={36} alt={'Instagram Icon'} />
              </a>
              <a href={Utils.getSocialMediaLink('youtube')} target={'_blank'} rel="noreferrer">
                <Image src={YoutubeIcon} width={36} alt={'Youtube Icon'} />
              </a>
              <a href={Utils.getSocialMediaLink('pinterest')} target={'_blank'} rel="noreferrer">
                <Image src={PinterestIcon} width={36} alt={'Pinterest Icon'} />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.copyrightText}>
          <Text style={{ fontSize: 15 }}>
            © {moment().format('yyyy')} Careers360. All Rights Reserved.
          </Text>
          {/*<Text style={{ fontSize: 15 }}>All Rights Reserved.</Text>*/}
        </div>
      </div>
    </footer>
  );
};
