import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  notification,
  Radio,
  Space,
  Spin,
  Steps,
  Typography,
} from 'antd';
import Utils from '../../../../utils/Utils';
import image1 from '../../../../public/images/trophy.png';
import Image from 'next/image';
import FileUploadEx from '../../../FileUpload/FileUpload';
import { useConnection, useEvents } from '../../../../connection/Application';
import { POST } from '../../../JobPostSet/JobPostSet';
import { UserProfile } from '../../../../models/UserModels';
import style from './JobApplyModalEx.module.less';
import { Title } from '../../../Typogrphy/Title';
import Router from 'next/router';
import moment from 'moment';
import { ResponsiveModal } from '../../../ResponsiveModal/ResponsiveModal';

const TextArea = Input.TextArea;

type JobApplyStep = {
  step: number;
  type: 'question' | 'cv' | 'cover' | 'summary';
  question?: any;
  answer?: any;
};

type JobApplySteps = {
  titles: string[];
  steps: JobApplyStep[];
};

type JobApplyCvUse = 'profile' | 'existing' | 'upload' | 'none' | undefined;
type JobApplyData = {
  visible: boolean;
  job: any;
  profile: any;
  answers: any[];
  cvUse: JobApplyCvUse;
  setCvUse: (cvUse: JobApplyCvUse) => void;
  cvUrl?: string;
  setCvUrl: (cvUrl?: string) => void;
  error?: string;
  setError: (error?: string) => void;
  coverUse: JobApplyCvUse;
  setCoverUse: (cvUse: JobApplyCvUse) => void;
  coverUrl?: string;
  setCoverUrl: (coverUrl?: string) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  applied: boolean;
  jobPostId?: string;
  onClose: (success: boolean) => void;
  steps: JobApplySteps;
  setSteps: (steps: JobApplySteps) => void;
  step: number;
  setStep: (step: number) => void;
  next: () => void;
  back: () => void;
};

// tslint:disable-next-line:variable-name
const JobApplyContext = createContext<JobApplyData>({
  answers: [],
  coverUse: 'profile',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCvUse: () => {},
  cvUse: 'profile',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCvUrl: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCoverUrl: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCoverUse: () => {},
  job: undefined,
  profile: undefined,
  visible: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
  loading: false,
  applied: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: () => {},
  step: 0,
  steps: { titles: [], steps: [] },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSteps: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setError: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  next: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  back: () => {},
});

type JobApplyContextWrapperProps = {
  jobPostId: string;
  visible: boolean;
  onClose: (success: boolean) => void;
};

export const JobApplyContextWrapper: FC<PropsWithChildren<JobApplyContextWrapperProps>> = (
  props,
) => {
  const [job, setJob] = useState<any>(undefined);
  const [profile, setProfile] = useState<any>(undefined);
  const [coverUse, setCoverUse] = useState<JobApplyCvUse>(undefined);
  const [coverUrl, setCoverUrl] = useState<string | undefined>(undefined);
  const [cvUse, setCvUse] = useState<JobApplyCvUse>('profile');
  const [cvUrl, setCvUrl] = useState<string | undefined>('');
  const [answers, setAnswers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [applied, setApplied] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [steps, setSteps] = useState<JobApplySteps>({ steps: [], titles: [] });
  const [error, setError] = useState<string | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const connection = useConnection();
  const events = useEvents();

  const loadInitial = async (jobIdentifier: string) => {
    try {
      const [jobPostId] = jobIdentifier.split('/');
      return await connection.get(`job-apply/${jobPostId}`);
    } catch (e) {
      return { status: 'ERROR' };
    }
  };
  const reset = () => {
    setJob(undefined);
    setProfile(undefined);
    setCoverUse(undefined);
    setCoverUrl(undefined);
    setCvUse(undefined);
    setCvUrl(undefined);
    setAnswers([]);
    setApplied(false);
    setLoading(false);
    setStep(0);
    setError(undefined);
    setSteps({ steps: [], titles: [] });
  };

  const goBack = () => {
    setError(undefined);
    setStep(step - 1);
  };

  const goNext = () => {
    const current = steps.steps[step];
    if (current.type === 'question') {
      if (current.question.required && !current.answer) {
        setError('Please answer this question to continue');
        return;
      }

      if (
        current.question.type === 'MCQ (Single Answer)' &&
        current.answer &&
        current.question.blocked &&
        current.question.blocked.indexOf(current.answer) !== -1
      ) {
        setError(current.question.description);
        return;
      }

      if (
        current.question.type === 'MCQ (Multiple Answers)' &&
        current.answer &&
        current.question.blocked &&
        current.answer.find((val: string) => current.question.blocked.indexOf(val) !== -1)
      ) {
        setError(current.question.description);
        return;
      }
      setError(undefined);
      setStep(step + 1);
    } else if (current.type === 'cv') {
      if (!cvUse) {
        setError('Please select a CV');
        return;
      }
      if (cvUse === 'upload' && cvUrl === undefined) {
        setError('Please upload a CV');
        return;
      }
      setError(undefined);
      setCoverUse(undefined);
      setStep(step + 1);
    } else if (current.type === 'cover') {
      if (!coverUse) {
        setError('Please select a Cover Letter');
        return;
      }
      if (coverUse === 'upload' && coverUrl === undefined) {
        setError('Please upload a Cover Letter');
        return;
      }
      setError(undefined);
      setStep(step + 1);
    } else if (current.type === 'summary') {
      setLoading(true);
      connection
        .post('job-apply/apply', {
          cvUse,
          cvUrl,
          coverUse,
          coverUrl,
          quiz: steps.steps
            .filter((value) => value.type === 'question')
            .map((value) => ({ question: value.question, answer: value.answer })),
          jobPostId: job.jobPostId,
          recruiterId: job.recruiterId,
          jobPublishedDate: job.activeDate,
        })
        .then(() => {
          setLoading(false);
          setVisible(true);
          events.emit('JOB_APPLIED', { jobPostId: job.jobPostId });
          props.onClose(true);
        })
        .catch((reason) => {
          notification['error']({
            message: 'Error',
            description: reason.message,
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props.visible) {
      setLoading(true);
      loadInitial(props.jobPostId).then(
        (value: { job?: POST; status?: string; applied?: boolean; profile: UserProfile }) => {
          setLoading(false);
          if (value.status === 'ERROR') {
            reset();
            // localStorage.setItem('BackUrl', '/jobs');
            // localStorage.setItem('BackQuery', JSON.stringify(router.query));
            localStorage.setItem('BackUrl', `/job/${props.jobPostId}`);
            Router.push({
              pathname: '/login',
            });
            props.onClose(false);
          } else {
            reset();

            if (value.applied === true) {
              setApplied(true);
            } else {
              setProfile(value.profile);
            }
            setJob(value.job);
            const jobSteps: JobApplyStep[] = [];
            const jobStepTitles: string[] = [];
            if (value.job?.question && value.job?.question.length > 0) {
              value.job?.question.forEach((question: any) => {
                jobSteps.push({ question, step: jobStepTitles.length, type: 'question' });
              });
              jobStepTitles.push('Questions');
            }
            jobSteps.push({ step: jobStepTitles.length, type: 'cv' });
            jobStepTitles.push('Custom CV');
            jobSteps.push({ step: jobStepTitles.length, type: 'cover' });
            jobStepTitles.push('Cover Letter');
            jobSteps.push({ step: jobStepTitles.length, type: 'summary' });
            jobStepTitles.push('Summary');
            setStep(0);
            setSteps({ titles: jobStepTitles, steps: jobSteps });
          }
        },
      );
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [props.jobPostId, props.visible]);
  return (
    <JobApplyContext.Provider
      value={{
        answers,
        coverUrl,
        setCoverUrl,
        coverUse,
        setCoverUse,
        cvUrl,
        setCvUrl,
        cvUse,
        setCvUse,
        job,
        profile,
        loading,
        setLoading,
        applied,
        step,
        setStep,
        steps,
        setSteps,
        error,
        setError,
        next: goNext,
        back: goBack,
        visible: props.visible,
        jobPostId: props.jobPostId,
        onClose: props.onClose,
      }}>
      {props.children}
      <Modal
        closable={true}
        footer={false}
        destroyOnClose
        onCancel={() => setVisible(false)}
        open={visible}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          <Image src={image1} width={100} height={110} alt={'Success Image'} />
          <Title level={4}>Congratulations !</Title>
          <Typography style={{ textAlign: 'center', fontWeight: 600 }}>
            Your Application submitted successfully
          </Typography>
        </div>
      </Modal>
    </JobApplyContext.Provider>
  );
};

type JobApplyCvSelectProps = {
  cvUse: JobApplyCvUse;
  setCvUse: (cvUse: JobApplyCvUse) => void;
  cvUrl?: string;
  isCompletion: boolean;
  setCvUrl: (cvUrl?: string) => void;
  cvs: string[];
  type: 'CV' | 'Cover Letter';
};

const JobApplyCvSelect: FC<JobApplyCvSelectProps> = (props) => {
  const context = useContext(JobApplyContext);
  const [uploadedCv, setUploadedCv] = useState<string | undefined>(undefined);
  const { cvUse, cvUrl, setCvUrl, setCvUse, cvs, type, isCompletion } = props;
  useEffect(() => {
    if (cvUse === 'upload') {
      setUploadedCv(cvUrl);
    }
  }, [cvUrl, cvUse]);
  // const cvs = (context.profile && context.profile.cv) ? context.profile.cv : [];
  return (
    <div className={style.job_apply_cv}>
      {/*{type === 'CV' && <div className={`${cvUse === 'profile' ? 'job-apply-cv-selected' : ''}`}>*/}
      {/*<h3>Profile</h3>*/}
      {/*<Radio*/}
      {/*  disabled={!context.profile || context.profile.profileCompletion < 75}*/}
      {/*  checked={cvUse === 'profile'}*/}
      {/*  onChange={(e) => {*/}
      {/*    setCvUse('profile');*/}
      {/*    setCvUrl(undefined);*/}
      {/*    context.setError(undefined);*/}
      {/*  }}>*/}
      {/*  Use profile as CV {context.profile ? `(${context.profile.profileCompletion}% completed)` :
        'Not available'}*/}
      {/*</Radio>*/}
      {/*{!context.profile &&*/}
      {/*<div className="job-apply-error">*/}
      {/*  You have not created a profile*/}
      {/*</div>}*/}
      {/*{context.profile && context.profile.profileCompletion < 75 &&*/}
      {/*<div className="job-apply-error">*/}
      {/*  Your profile is not completed enough to use it as the CV.
        You must complete at least 75% to use it as a CV.*/}
      {/*</div>}*/}
      {/*</div>}*/}
      {type === 'CV' && (
        <>
          {isCompletion ? (
            <div className={`${cvUse === 'profile' ? style.job_apply_cv_selected : ''}`}>
              <h3>Apply using your profile</h3>
              <Radio
                checked={cvUse === 'profile'}
                onChange={() => {
                  setCvUse('profile');
                  setCvUrl(undefined);
                  context.setError(undefined);
                }}>
                Increase your chance of getting hired!
              </Radio>
            </div>
          ) : (
            <div
              className={`${cvUse === 'profile' ? style.job_apply_cv_selected : ''}`}
              style={{ cursor: 'not-allowed', opacity: 0.6 }}>
              <h3>Apply using your profile</h3>
              <Radio checked={false}>
                Increase your chance of getting hired! Complete your profile up to at least 80% to
                apply.
              </Radio>
            </div>
          )}
        </>
      )}
      <div
        className={`${
          cvUse === 'existing' ? style.job_apply_cv_selected : ''
        } flex flex-col gap-2 fullwidth`}>
        <h3>Existing {type}</h3>
        {cvs.map((cv: any, index) => (
          <div key={index} className="flex gap-2 fullwidth">
            <div className="flex grow gap-2 shrink overflow-hidden text-ellipsis">
              <Radio
                className="shrink grow overflow-hidden break-words"
                checked={cvUse === 'existing' && cvUrl === cv.url}
                onChange={() => {
                  setCvUse('existing');
                  setCvUrl(cv.url);
                  context.setError(undefined);
                }}>
                {cv.url.split('/').slice(-1)[0]}
              </Radio>
              <div className="shrink-0 grow-0">
                <i>({moment(new Date(+cv.time * 1000)).format('MMM DD yyyy')})</i>
              </div>
            </div>
            <a className="shrink-0 grow-0" target="_blank" href={cv.url} rel="noreferrer">
              <DownloadOutlined />
              <span className="ml-2 invisible hidden sm:visible sm:inline-block">Download</span>
            </a>
          </div>
        ))}
        {cvs.length === 0 && (
          <div>
            You have not uploaded any {type} yet. You can upload multiple {type}s in your profile
            page and use it later when applying for jobs.
          </div>
        )}
      </div>
      <div className={`${cvUse === 'upload' ? style.job_apply_cv_selected : ''}`}>
        <h3>Upload {type}</h3>
        <Radio
          checked={cvUse === 'upload'}
          onChange={() => {
            setCvUse('upload');
            setCvUrl(uploadedCv);
            context.setError(undefined);
          }}>
          Use uploaded {type}
        </Radio>
        {uploadedCv && (
          <div
            className={Utils.mergeClasses(
              style.job_apply_cv_existing_row,
              style.job_apply_cv_uploaded_row,
            )}>
            <span>{uploadedCv.split('/').slice(-1)[0]}</span>
            <div className={style.spacer} />
            <a target="_blank" href={uploadedCv} rel="noreferrer">
              <DownloadOutlined className={'mr-2'} />
              Download
            </a>
          </div>
        )}
        {cvUse === 'upload' && (
          <FileUploadEx
            type={'drag'}
            onChange={(url: any) => {
              setUploadedCv(url);
              setCvUrl(url);
              context.setLoading(false);
            }}
            accept={Utils.CommonDocumentFileFormats}
            acceptSize={2.5}>
            <p>Click here or drag and drop your {type}</p>
            <p>Only .doc, .docx, .pdf, .txt, .odt and .rtf accepted.</p>
          </FileUploadEx>
        )}
      </div>
      {type === 'Cover Letter' && (
        <>
          <div className={`${cvUse === 'none' ? style.job_apply_cv_selected : ''}`}>
            <h3>Skip</h3>
            <Radio
              checked={cvUse === 'none'}
              onChange={() => {
                setCvUse('none');
                setCvUrl(undefined);
              }}>
              Do not use a cover letter
            </Radio>
          </div>
        </>
      )}
    </div>
  );
};

const JobApplyMcqSingleQuestion: FC<{
  options: string[];
  value: string;
  onChange: (value: string) => void;
}> = (props) => {
  // const radioStyle = {
  //   display: 'flex',
  //   height: '36px',
  //   lineHeight: '30px',
  // };
  return (
    <Radio.Group onChange={(e) => props.onChange(e.target.value)} value={props.value}>
      {props.options.map((opt, i) => (
        <Radio key={i} value={opt} className={style.mcqSingleRadio}>
          {opt}
        </Radio>
      ))}
    </Radio.Group>
  );
};

const JobApplyMcqMultiQuestion: FC<{
  options: string[];
  value?: string[];
  onChange: (value?: string[]) => void;
}> = (props) => {
  return (
    <Checkbox.Group
      className={style.job_apply_check_group}
      options={props.options}
      onChange={(e) => props.onChange(e && e.length > 0 ? (e as any) : undefined)}
      value={props.value ? props.value : []}
    />
  );
};

const JobApplyMcqTextQuestion: FC<{ value?: string; onChange: (value: string) => void }> = (
  props,
) => {
  return (
    <TextArea
      rows={5}
      maxLength={4000}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
    />
  );
};

const JobApplyQuestion: FC = () => {
  const context = useContext(JobApplyContext);
  if (context.steps.steps.length === 0) {
    return null;
  }

  const step = context.steps.steps[context.step];

  if (step.type !== 'question') {
    return null;
  }
  const { question } = step;

  const onChange = (value: any) => {
    const s = { ...context.steps };
    s.steps[context.step].answer = value;
    context.setSteps(s);
  };

  return (
    <div className={style.job_apply_question}>
      <div className={style.job_apply_question_question}>
        {context.step + 1}. {question.question}
      </div>
      <div className={style.job_apply_question_answer}>
        {question.type === 'MCQ (Single Answer)' && (
          <JobApplyMcqSingleQuestion
            options={question.answers}
            value={step.answer}
            onChange={onChange}
          />
        )}
        {question.type === 'MCQ (Multiple Answers)' && (
          <JobApplyMcqMultiQuestion
            options={question.answers}
            value={step.answer}
            onChange={onChange}
          />
        )}
        {question.type === 'Plain Text Answer' && (
          <JobApplyMcqTextQuestion value={step.answer} onChange={onChange} />
        )}
      </div>
    </div>
  );
};

const JobApplySummary: FC = () => {
  const context = useContext(JobApplyContext);
  const questions = context.steps.steps.filter((value) => value.type === 'question');
  return (
    <div>
      {questions.length > 0 && (
        <>
          <Alert
            message=" You are going to submit following details with the Job application"
            type="success"
            style={{ textAlign: 'center' }}
          />
          <Divider />
          <div className={style.job_apply_summary_title}>Questions</div>
          {questions.map((value, index) => (
            <div key={index} className={style.job_apply_summary_question}>
              <div className={style.job_apply_summary_qq}>
                {index + 1}. {value.question.question}
              </div>
              {!value.answer && <i>Not answered</i>}
              {value.answer &&
                Array.isArray(value.answer) &&
                value.answer.map((ans: any, i: number) => (
                  <div key={i} className={style.job_apply_summary_qa}>
                    {ans}
                  </div>
                ))}
              {value.answer && !Array.isArray(value.answer) && (
                <div className={style.job_apply_summary_qa}>{value.answer}</div>
              )}
            </div>
          ))}
          <Divider />
        </>
      )}
      <div className={style.job_apply_summary_title}>Custom CV</div>
      {context.cvUse === 'profile' && (
        <div
          className={Utils.mergeClasses(
            style.job_apply_cv_existing_row,
            style.job_apply_cv_uploaded_row,
          )}>
          You have used the profile as your CV.
        </div>
      )}
      {context.cvUrl && (
        <div
          className={Utils.mergeClasses(
            style.job_apply_cv_existing_row,
            style.job_apply_cv_uploaded_row,
          )}>
          <span>{context.cvUrl.split('/').slice(-1)[0]}</span>
          <div className={style.spacer} />
          <a target="_blank" href={context.cvUrl} rel="noreferrer">
            <DownloadOutlined className={'mr-2'} />
            Download
          </a>
        </div>
      )}
      <Divider />
      <div className={style.job_apply_summary_title}>Cover Letter</div>
      {context.coverUse === 'none' && (
        <div
          className={Utils.mergeClasses(
            style.job_apply_cv_existing_row,
            style.job_apply_cv_uploaded_row,
          )}>
          You have not preferred to use a cover letter.
        </div>
      )}
      {context.coverUrl !== 'profile' && context.coverUrl && (
        <div
          className={Utils.mergeClasses(
            style.job_apply_cv_existing_row,
            style.job_apply_cv_uploaded_row,
          )}>
          <span>{context.coverUrl.split('/').slice(-1)[0]}</span>
          <div className={style.spacer} />
          <a target="_blank" href={context.coverUrl} rel="noreferrer">
            <DownloadOutlined className={'mr-2'} />
            Download
          </a>
        </div>
      )}
    </div>
  );
};

const JobApplyModal: FC = () => {
  const context = useContext(JobApplyContext);
  const current = context.steps.steps.length > 0 ? context.steps.steps[context.step] : undefined;
  const isCompletion = context.profile?.profileCompletion > 80;
  return (
    <ResponsiveModal
      title={`Apply for ${context.job ? context.job.jobTitle : ''}`}
      open={context.visible}
      onCancel={() => context.onClose(false)}
      confirmLoading={context.loading}
      destroyOnClose={true}
      maskClosable={false}
      width={800}
      footer={
        <div className={style.job_apply_footer}>
          <Button
            type="default"
            onClick={context.back}
            disabled={context.step === 0}
            id={'job-apply-modal-btn-back'}>
            Back
          </Button>
          <Space className={style.job_apply_footer_button}>
            <div />
            <Button
              type="default"
              onClick={() => context.onClose(false)}
              id={'job-apply-modal-btn-cancel'}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={current && current.type === 'summary' ? context.loading : false}
              onClick={context.next}
              id={
                current && current.type === 'summary'
                  ? 'job-apply-modal-btn-submit'
                  : 'job-apply-modal-btn-next'
              }>
              {current && current.type === 'summary' ? 'Submit' : 'Next'}
            </Button>
            {/*{!isCompletion && (*/}
            {/*  <Button*/}
            {/*    type="primary"*/}
            {/*    onClick={() => {*/}
            {/*      window.location.href = `/profile/${context.profile.userId}`;*/}
            {/*      context.onClose(false);*/}
            {/*    }}>*/}
            {/*    OK*/}
            {/*  </Button>*/}
            {/*)}*/}
          </Space>
        </div>
      }>
      <Spin spinning={context.loading}>
        <Steps
          current={
            context.steps.steps.length > 0 ? context.steps.steps[context.step].step : undefined
          }>
          {context.steps.titles.map((value, index) => (
            <Steps.Step className={style.jobApplyStepcv} key={index} title={value} />
          ))}
        </Steps>
        <Divider />
        {/*{!isCompletion && (*/}
        {/*  <div className="job-apply-content">*/}
        {/*    Please complete your profile at least 75% to apply this advert.*/}
        {/*  </div>*/}
        {/*)}*/}

        <div className={style.job_apply_content}>
          <div className={style.job_apply_content_body}>
            <JobApplyQuestion />
            {current && current.type === 'cv' && (
              <JobApplyCvSelect
                cvUse={context.cvUse}
                setCvUse={context.setCvUse}
                cvUrl={context.cvUrl}
                isCompletion={isCompletion}
                setCvUrl={context.setCvUrl}
                cvs={context.profile && context.profile.resumes ? context.profile.resumes : []}
                type={'CV'}
              />
            )}
            {current && current.type === 'cover' && (
              <JobApplyCvSelect
                cvUse={context.coverUse}
                setCvUse={context.setCoverUse}
                isCompletion={isCompletion}
                cvUrl={context.coverUrl}
                setCvUrl={context.setCoverUrl}
                cvs={
                  context.profile && context.profile.coverLetters
                    ? context.profile.coverLetters
                    : []
                }
                type={'Cover Letter'}
              />
            )}
            {current && current.type === 'summary' && <JobApplySummary />}
          </div>
          {context.error && <Alert message={context.error} type="error" />}
        </div>
      </Spin>
    </ResponsiveModal>
  );
};

// tslint:disable-next-line:variable-name
const JobApplyModalEx: FC<{
  jobPostId: string;
  visible: boolean;
  onClose: (success: boolean) => void;
}> = (props) => {
  return (
    <JobApplyContextWrapper {...props}>
      <JobApplyModal />
    </JobApplyContextWrapper>
  );
};

export default JobApplyModalEx;
