import React, { FC } from 'react';
import { ContentBlock, TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import styles from './dashboard.module.less';
import { Button, Grid, Space } from 'antd';
import { BellOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

type Props = {
  buttonTitle: string;
  description: string;
  time: any;
  title?: string;
  onClick?: () => void;
};
export const ProfileCompletionNotifyCard: FC<Props> = (props) => {
  const { time, title, buttonTitle, onClick, description } = props;
  const bp = Grid.useBreakpoint();
  return (
    <ContentBlock onClick={!bp.sm ? onClick : () => undefined} className={'fullwidth'} gap={'lg'}>
      <ContentBlock
        align={'center'}
        right={
          <div className={styles.jobCardButons}>
            {bp.sm && (
              <Button type={'primary'} onClick={onClick} size={'small'}>
                {buttonTitle}
              </Button>
            )}
          </div>
        }>
        <Space className={'fullwidth'} direction={'vertical'} align={'start'}>
          <Space>
            <BellOutlined className={styles.bellIcon} />
            <TextBlock heading>{title}</TextBlock>
          </Space>
          <TextBlock align={'left'}>{description}</TextBlock>
          <ContentBlock secondary left={<ClockCircleOutlined />}>
            <TextSpan primary>{moment(time).fromNow()}</TextSpan>
          </ContentBlock>
        </Space>
      </ContentBlock>
    </ContentBlock>
  );
};
