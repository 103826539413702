import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SendJobPost } from '../../../models/JobPostModals';
import { Button, Divider, Grid, Space } from 'antd';
import {
  ContentBlock,
  TextBlock,
  TextHeading,
  TextSpan,
} from '../../../uicomp/atmos/content-block/ContentBlock';
import stylesModule from './SearchComponents.module.less';
import { Logo } from '../../../uicomp/atmos/logo/Logo';
import moment from 'moment';
import Config from '../../../config';
import { ArrowLeftOutlined, ClockCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  faBriefcase,
  faFolder,
  faHandHoldingUsd,
  faLaptopHouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TieImage from '../../../public/home/tie.svg';
import ExperienceImage from '../../../public/home/Experiance.svg';
import FeaturedBadge from '../../../public/featuredbadge.svg';
import Image from 'next/image';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { HtmlView } from '../../../components/HtmlTemplate/HtmlView';
import { TextTag } from '../../../uicomp/atmos/text-tag/TextTag';
import JobApplyModalEx from '../../../components/Applicant/Job/Apply/JobApplyModalEx';
import JobWithdraw, {
  useJobWithdraw,
} from '../../../components/Applicant/Job/Withdraw/JobWithdraw';
import { useConnection, useEvents, useUserInfo } from '../../../connection/Application';
import { JobImageView } from './JobImageView';
import Router, { useRouter } from 'next/router';
import { useSearchContext } from '../SearchContext';
import { CandidateLayout, SEO_COMMON } from '../../../layout/candidate/CandidateLayout';
import Utils from '../../../utils/Utils';
import { NextSeo } from 'next-seo';
import querystring from 'querystring';
import { useCachedData } from '../../../connection/CacheContext';
import { CandidateFooter } from '../../../layout/candidate/CandidateFooter';
import JobPostShare from '../../../components/JobPost/JobPostShare';
import Link from 'next/link';

const styles: any = stylesModule;

type Props = {
  value: SendJobPost;
  query?: any;
  showFooter?: boolean;
};

export const JobBasicInfoCard: FC<Props> = (props) => {
  const { value } = props;
  const ctx = useSearchContext();
  const bp = Grid.useBreakpoint();
  const user = useUserInfo();
  const cache = useCachedData();

  // const shareUrl = `${window.location.origin}/job/${value.jobPostId}`;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const expired = useMemo(() => {
    return value?.expireDate && Utils.moment().format('YYYY-MM-DD') > value.expireDate;
  }, [value?.expireDate]);

  return (
    <Space direction={'vertical'} size={'middle'} className={'fullwidth'}>
      {user && !user?.eyeball && (
        <div className={styles.eyeballMessage}>
          <TextBlock>
            {
              "You're closed to eyeballing. Switch it on, so the Recruiters will see your profile in their searches and may approach you."
            }
            <Link href={`/profile/${user?.userId}`}>Click Here</Link>
          </TextBlock>
        </div>
      )}

      <ContentBlock
        className={'fullwidth'}
        right={
          <div>
            <Button
              id={'btn-job-post-share'}
              onClick={showModal}
              hidden={!!expired}
              type={'link'}
              shape={'circle'}
              size={'small'}
              style={{ padding: 0, fontSize: 24 }}>
              <ShareAltOutlined />
            </Button>

            <JobPostShare value={value} visible={isModalVisible} onCancel={setIsModalVisible} />
          </div>
        }
        heading
        level={1}>
        <TextHeading heading level={1} className={styles.jobCardTitle}>
          {value.jobTitle}
        </TextHeading>
      </ContentBlock>
      <ContentBlock
        gap="lg"
        vertical={!bp.md}
        left={
          <Space direction={bp.md ? 'vertical' : 'horizontal'}>
            <a
              href={`/recruiters/${Utils.normalizeURIComponent(
                value?.recruiterName ?? 'company',
              )}/${value.recruiterId}`}
              target={'_blank'}
              rel="noreferrer">
              <Button
                className={styles.viewRecruiter}
                type={'link'}
                // onClick={() => setRecruiterId(value.recruiterId)}
              >
                <Logo src={value.recruiterImage!} />
              </Button>
            </a>
            <Link
              href={`/jobs/?${querystring.stringify({
                company: value.recruiterId,
                results: 'show',
              })}`}
              onClick={() => ctx.search({ company: [value.recruiterId!] }, true)}>
              <Button
                onClick={() => ctx.search({ company: [value.recruiterId!] }, true)}
                type={'link'}
                className={styles.viewAllJobs}>
                View All Jobs
              </Button>
            </Link>
          </Space>
        }>
        <Space direction={'vertical'}>
          <Space wrap>
            <TextBlock level={1}>
              {value.recruiterName}
              <br />
              {cache.getLocationString(value.locationData ?? [])}
            </TextBlock>
          </Space>
          <Space size={['large', 'small']} wrap>
            <ContentBlock primary left={<ClockCircleOutlined style={{ marginBottom: 1 }} />}>
              {moment(value.time).fromNow()}
            </ContentBlock>
            <ContentBlock
              secondary
              left={<FontAwesomeIcon icon={faBriefcase} style={{ marginBottom: 1 }} />}>
              {value.jobType?.map((v) => cache?.enumValue?.['Job Types']?.[v]).join(' / ')}
            </ContentBlock>
            <ContentBlock
              secondary
              align={'bottom'}
              left={
                <Image
                  alt="tie"
                  src={TieImage}
                  width={16}
                  height={16}
                  style={{ alignSelf: 'center', display: 'flex', marginBottom: 6 }}
                />
              }>
              <TextSpan strong normal>
                {value.applicantCount ?? 0}
              </TextSpan>{' '}
              Applied
            </ContentBlock>
            <ContentBlock
              secondary
              left={<FontAwesomeIcon icon={faCalendarAlt} style={{ marginBottom: 1 }} />}>
              {`${
                moment().diff(moment(value?.expireDate).add(1, 'd')) < 0
                  ? 'Expires on:'
                  : 'Expired on:'
              } ${moment(new Date(value?.expireDate ?? '')).format('MMM DD yyyy')}`}
            </ContentBlock>
            {(value.salaryValue ?? '').trim() !== '' && (
              <ContentBlock
                secondary
                left={<FontAwesomeIcon icon={faHandHoldingUsd} style={{ marginBottom: 2 }} />}>
                {value.salaryValue}
              </ContentBlock>
            )}
            <ContentBlock
              secondary
              left={<FontAwesomeIcon icon={faFolder} style={{ marginBottom: 1 }} />}>
              {value.jobCategory?.map((v) => cache?.enumValue['Job Category']?.[v]).join(' / ')}
            </ContentBlock>
            {value.workplaceType && (
              <ContentBlock
                secondary
                left={<FontAwesomeIcon icon={faLaptopHouse} style={{ marginBottom: 1 }} />}>
                {cache?.enumValue['Workplace Type']?.[value.workplaceType]}
              </ContentBlock>
            )}
            {value.experienceLevel && (
              <ContentBlock
                secondary
                left={
                  <Image
                    alt="tie"
                    src={ExperienceImage}
                    width={16}
                    height={16}
                    style={{ alignSelf: 'center', display: 'flex', marginTop: 3 }}
                  />
                }>
                {cache?.enumValue['Experience Level']?.[value.experienceLevel]}
              </ContentBlock>
            )}
          </Space>
        </Space>
        {/* <Space direction={'vertical'} align={'end'} style={{ width: '100%' }}>
        </Space> */}
        <ContentBlock
          className={styles.featuredBadge_RefNo}
          right={
            <p className={'m-0'}>
              Ref.No{' '}
              {isNaN(Number(value.jobPostId))
                ? value?.jobPostId?.substr(value?.jobPostId?.length - 4).padStart(8, '0')
                : String(value?.jobPostId).padStart(8, '0')}
            </p>
          }>
          {value.featuredAdvert === 1 && (
            <div>
              <Image alt="featured" src={FeaturedBadge} width={117} height={24} />
            </div>
          )}
        </ContentBlock>
      </ContentBlock>
    </Space>
  );
};

export const JobDetailCardActions: FC<Props> = (props) => {
  const { value } = props;
  const { jobPostId, shortlisted } = value;
  const [visible, setVisible] = useState<boolean>(false);
  const jobWithdraw = useJobWithdraw();
  const connection = useConnection();
  const events = useEvents();
  const user = useUserInfo();
  const router = useRouter();

  const save = useCallback(() => {
    if (!user) {
      localStorage.setItem(
        'BackUrl',
        `/jobs/${Utils.normalizeURIComponent(value.jobTitle ?? 'job')}/${value.jobPostId}`,
      );
      localStorage.setItem('BackQuery', JSON.stringify(router.query));
      Router.push({
        pathname: '/login',
      });
      return;
    }
    connection.post(`job-apply/${jobPostId}/save`);
    events.emit('JOB_SAVED', { jobPostId });
  }, [connection, events, jobPostId, router.query, user, value.jobPostId, value.jobTitle]);
  const unsave = useCallback(() => {
    if (!user) {
      localStorage.setItem(
        'BackUrl',
        `/jobs/${Utils.normalizeURIComponent(value.jobTitle ?? 'job')}/${value.jobPostId}`,
      );
      localStorage.setItem('BackQuery', JSON.stringify(router.query));
      Router.push({
        pathname: '/login',
      });
      return;
    }
    connection.post(`job-apply/${jobPostId}/unsave`);
    events.emit('JOB_UNSAVED', { jobPostId });
  }, [connection, events, jobPostId, router.query, user, value.jobPostId, value.jobTitle]);

  const expired: boolean = useMemo(() => {
    return (value?.expireDate ?? '') < Utils.moment().format('YYYY-MM-DD');
  }, [value?.expireDate]);

  return (
    <Space className={styles.actionBtnContainer}>
      {!value.applied && !expired && value.receiveApplications === 'portal' && (
        <Button
          className={styles.actionButton}
          type="primary"
          onClick={() => setVisible(true)}
          id={'job-details-job-apply-button-1'}>
          Apply Now
        </Button>
      )}
      {!value.applied && !expired && value.receiveApplications === 'external' && (
        <Button
          id={'btn-job-apply'}
          className={styles.actionButton}
          onClick={() => {
            if (user) {
              connection.post(`job-post/${jobPostId}/external-apply`, {}).then((res) => {
                if (res.response.status === 'Not applied') {
                  events.emit('JOB_APPLIED', { jobPostId });
                }
              });
              window.open(value.receiveApplicationsUrl);
            } else {
              Router.push({
                pathname: '/login',
              });
            }
          }}
          type="primary">
          Apply Now
        </Button>
      )}
      {value.applied && (
        <Button
          id={'btn-job-withdraw'}
          className={styles.actionButton}
          type="primary"
          danger
          onClick={() => jobWithdraw.current?.withdraw(jobPostId!)}>
          Withdraw
        </Button>
      )}
      <Button
        id={'btn-job-post-save'}
        className={styles.actionButton}
        disabled={expired && !shortlisted}
        onClick={shortlisted ? unsave : save}>
        {shortlisted ? 'Unsave' : 'Save'}
      </Button>
      <JobApplyModalEx
        jobPostId={jobPostId ?? ''}
        visible={visible}
        onClose={() => {
          setVisible(false);
          //props?.search?.({ page: +(props?.page ?? 1), size: 10 }, 'time', 'desc');
        }}
      />
      <JobWithdraw ref={jobWithdraw} />
    </Space>
  );
};

export const JobDetailsCard: FC<Props> = (props) => {
  const { value, showFooter } = props;
  const cache = useCachedData();
  return (
    <Space
      direction={'vertical'}
      className={styles.jobDetailsCard}
      split={<Divider style={{ margin: 0 }} />}
      size={'middle'}>
      <JobBasicInfoCard value={value} />
      <JobDetailCardActions value={value} />
      {value.jobAdvertImage && <JobImageView image={value.jobAdvertImage} />}
      <Space direction="vertical">
        {value.description && (
          <Space direction="vertical">
            <TextBlock heading level={-2}>
              Description
            </TextBlock>
            <TextBlock>
              <HtmlView className={styles.pText} value={value.description} />
            </TextBlock>
          </Space>
        )}
        {/*{value.responsibilities && (*/}
        {/*  <Space direction="vertical">*/}
        {/*    <TextBlock heading level={-2}>*/}
        {/*      Responsibilities*/}
        {/*    </TextBlock>*/}
        {/*    <TextBlock>*/}
        {/*      <HtmlView className={styles.pText} value={value.responsibilities} />*/}
        {/*    </TextBlock>*/}
        {/*  </Space>*/}
        {/*)}*/}
        {value.skills && (
          <Space direction="vertical">
            <TextBlock heading level={-2}>
              Skills
            </TextBlock>
            <Space size={'middle'} wrap>
              {value.skills.map((skill) => (
                <TextTag key={skill.id}>{skill.value}</TextTag>
              ))}
            </Space>
          </Space>
        )}
        {value.sector && (
          <Space direction="vertical">
            <TextBlock heading level={-2}>
              Industry Sector
            </TextBlock>
            <Space size={'middle'} wrap>
              {value.sector.map((sector) => (
                <TextTag key={sector}>{cache?.enumValue?.['Sector']?.[sector]}</TextTag>
              ))}
            </Space>
          </Space>
        )}
      </Space>
      <JobDetailCardActions value={value} />
      {showFooter && (
        <div className={styles.footermobile}>
          <CandidateFooter />
        </div>
      )}
    </Space>
  );
};

export const JobDetailsPage: FC<Props> = (props) => {
  const [value, setValue] = useState<SendJobPost>(props.value);
  const events = useEvents();
  const cache = useCachedData();
  const locations = cache.getLocationString(value.locationData ?? []);
  const handleApplied = useCallback(
    ({ jobPostId }) => {
      if (value.jobPostId === jobPostId) {
        const newVal = { ...value };
        newVal.applied = newVal.receiveApplications === 'portal';
        newVal.appliedTime = Date.now();
        newVal.applicantCount = (newVal.applicantCount ?? 0) + 1;
        setValue(newVal);
      }
    },
    [value],
  );
  const handleWithdrawn = useCallback(
    ({ jobPostId }) => {
      if (value.jobPostId === jobPostId) {
        const newVal = { ...value };
        newVal.applied = false;
        newVal.appliedTime = undefined;
        newVal.applicantCount = (newVal.applicantCount ?? 1) - 1;
        setValue(newVal);
      }
    },
    [value],
  );

  const handleSaved = useCallback(
    ({ jobPostId }) => {
      if (value.jobPostId === jobPostId) {
        const newVal = { ...value };
        newVal.shortlisted = true;
        setValue(newVal);
      }
    },
    [value],
  );

  const handleUnaved = useCallback(
    ({ jobPostId }) => {
      if (value.jobPostId === jobPostId) {
        const newVal = { ...value };
        newVal.shortlisted = false;
        setValue(newVal);
      }
    },
    [value],
  );

  useEffect(() => {
    events.on('JOB_APPLIED', handleApplied);
    events.on('JOB_WITHDRAWN', handleWithdrawn);
    events.on('JOB_SAVED', handleSaved);
    events.on('JOB_UNSAVED', handleUnaved);
    return () => {
      events.off('JOB_APPLIED', handleApplied);
      events.off('JOB_WITHDRAWN', handleWithdrawn);
      events.off('JOB_SAVED', handleSaved);
      events.off('JOB_UNSAVED', handleUnaved);
    };
  }, [events, handleApplied, handleSaved, handleUnaved, handleWithdrawn]);

  const [query, hasQuery] = useMemo(() => {
    if (value === null) {
      return [undefined, false];
    }
    const qq = { ...props.query };
    delete qq.jobId;
    const q =
      Object.keys(qq).length > 0
        ? { ...qq, results: 'show' }
        : { search: value.jobTitle, results: 'show' };

    return [q, Object.keys(qq).length > 0];
  }, [props.query, value]);

  if (value === null) {
    return <div>Not Found</div>;
  }
  return (
    <CandidateLayout
      title={
        <NextSeo
          title={[
            value.jobTitle,
            value.recruiterName,
            locations,
            // (value?.enumData?.location ?? []).join(', '),
            // (value?.enumData?.country ?? []).join(', '),
            (value?.enumData?.jobCategory ?? []).join(', '),
          ].join(' | ')}
          description={`Want to know about the top ${(value?.enumData?.jobCategory ?? []).join(
            ', ',
          )} jobs in Sri Lanka? Visit the Top Jobs listing page to get all the latest information on ${
            value.jobTitle
          }. Find more about jobs in Sri Lanka.`}
          canonical={`${Config.frontendUrl}/jobs/${Utils.normalizeURIComponent(value.jobTitle!)}/${
            value.jobPostId
          }`}
          openGraph={{
            title: [
              value.jobTitle,
              value.recruiterName,
              locations,
              (value?.enumData?.jobCategory ?? []).join(', '),
            ].join(' | '),
            description: [
              `Want to know about the top ${(value?.enumData?.jobCategory ?? []).join(
                ', ',
              )} jobs in Sri Lanka? Visit the Top Jobs listing page to get all the latest information on ${
                value.jobTitle
              }. Find more about jobs in Sri Lanka.`,
            ].join(' | '),
            url: `${Config.frontendUrl}/jobs/${Utils.normalizeURIComponent(value.jobTitle!)}/${
              value.jobPostId
            }`,
            images: [
              {
                url: `${Config.fileStoreUrl}/files/images/recruiter/job/${value.recruiterId}.jpeg`,
                width: 1200,
                height: 630,
              },
            ],
          }}
          {...SEO_COMMON}
        />
      }>
      <Space direction={'vertical'} className={'fullwidth'} size={'large'}>
        <a
          href={`/jobs/${Utils.normalizeURIComponent(value.jobTitle ?? '')}/${
            value.jobPostId
          }?${querystring.stringify(query)}`}>
          <ContentBlock heading left={<ArrowLeftOutlined />}>
            {hasQuery ? (
              <>
                View{' '}
                <TextSpan level={2} primary>
                  search
                </TextSpan>{' '}
                results
              </>
            ) : (
              <>
                Search{' '}
                <TextSpan level={2} primary>
                  {value.jobTitle}
                </TextSpan>{' '}
                Jobs
              </>
            )}
          </ContentBlock>
        </a>
        <JobDetailsCard value={value} />
      </Space>
    </CandidateLayout>
  );
};
