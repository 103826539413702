import React, { FC } from 'react';
import { Button, Space } from 'antd';
import stylesModule from './dashboard.module.less';
import { useConnection, useEvents } from '../../connection/Application';

const styles: any = stylesModule;
type Props = {
  receiveApplicationsUrl?: string;
  applied?: boolean;
  expired?: boolean;
  onView?: () => void;
  onApply?: () => void;
  onWithdraw?: () => void;
  jobPostId: string;
};

export const JobApplyButton: FC<Props> = (props) => {
  const { applied, onView, onApply, onWithdraw, expired, receiveApplicationsUrl } = props;
  const connection = useConnection();
  const events = useEvents();
  return (
    <Space className={styles.btnContainer}>
      {applied && (
        <Button
          size={'small'}
          danger={true}
          onClick={onWithdraw}
          className={styles.btnWithdraw}
          id={'job-card-btn-withdraw'}>
          Withdraw
        </Button>
      )}
      {!applied && (receiveApplicationsUrl === undefined || expired) && (
        <Button
          type={'primary'}
          disabled={expired}
          onClick={onApply}
          size={'small'}
          id={'job-card-btn-apply'}>
          Apply Now
        </Button>
      )}
      {!applied && receiveApplicationsUrl !== undefined && !expired && (
        <Button
          type={'primary'}
          onClick={() => {
            connection.post(`job-post/${props.jobPostId}/external-apply`, {}).then((res) => {
              if (res.response.status === 'Not applied') {
                events.emit('JOB_APPLIED', { jobPostId: props.jobPostId });
              }
            });
            window.open(receiveApplicationsUrl);
          }}
          size={'small'}
          id={'job-card-btn-apply-external'}>
          Apply Now
        </Button>
      )}
      <Button
        onClick={onView}
        size={'small'}
        className={styles.jobCardButons}
        id={'job-card-btn-view-details'}>
        View Details
      </Button>
    </Space>
  );
};
