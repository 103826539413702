import React, { FC } from 'react';
import { Avatar, Button, Grid, Space } from 'antd';
import styles from './dashboard.module.less';
import Link from 'next/link';
import { useUserInfo } from '../../connection/Application';
import { useRouter } from 'next/router';
import { HeartOutlined } from '@ant-design/icons';
import { ProfileStrength } from '../../uicomp/atmos/proile-strength/ProfileStrength';
import { ContentBlock, TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import Utils from '../../utils/Utils';

// type JobAlertProfileProps = {
//   name: string;
//   image?: string;
//   strength?: number;
// };

export type leaderBoardData = {
  firstName: string;
  lastName: string;
  userId?: string;
  points: number;
  position: number;
  picture: string;
  user?: boolean;
};

type LeaderBoardProps = {
  name: string;
  profileCompletion: number;
  picture: string;
  points: number;
  position: number;
  leaderBoard: leaderBoardData[];
  popular?: any;
};

type Props = {
  data: LeaderBoardProps;
};
type LeaderbordItemProps = {
  position: number;
  heart: boolean;
  points: number;
  picture: string;
  name: string;
};

const LeaderBoardItem: FC<LeaderbordItemProps> = (props) => {
  const { points, name, position, heart } = props;
  return (
    <ContentBlock
      className={Utils.mergeClasses({
        [styles.leaderboardItem]: true,
        [styles.leaderboardItemSelected]: heart,
      })}
      left={
        <Space>
          <div className={styles.leaderboardRank}>{position}</div>
        </Space>
      }
      right={<div className={styles.leaderboardPoints}>{points}</div>}>
      <Space>
        {name} {heart && <HeartOutlined className={styles.leaderboardHeart} />}
      </Space>
    </ContentBlock>
  );
};

export const DashboardProfileCard: FC<Props> = (props) => {
  const { picture, profileCompletion, points, name, position, leaderBoard } = props.data;
  const user = useUserInfo();
  const history = useRouter();
  const bp = Grid.useBreakpoint();
  console.log({ leaderBoard });
  return (
    <div>
      <Space
        size={0}
        className={'fullwidth'}
        align={'start'}
        direction={bp.md && !bp.lg ? 'horizontal' : 'vertical'}>
        <Space
          className={styles.sideProfileCard}
          align={'center'}
          direction={'vertical'}
          size={'large'}>
          <Space size={0} direction={'vertical'} align={'center'}>
            <TextBlock>Profile Summary</TextBlock>

            <Link href={`profile/${user?.userId}`}>
              <TextBlock heading level={1} className={styles.profilename}>
                {user?.name}
              </TextBlock>
            </Link>
          </Space>
          <Space size={0} direction={'vertical'}>
            <Link href={`profile/${user?.userId}`}>
              <Avatar className={styles.profileAvatar} size={108} src={picture} />
            </Link>
            <TextBlock secondary>Profile Strength</TextBlock>
          </Space>

          <div style={{ width: 200 }}>
            <ProfileStrength value={profileCompletion} />
          </div>

          <TextBlock align={'center'} className={styles.link}>
            Your profile is <TextSpan primary>{profileCompletion}%</TextSpan> complete <br />
            <Link href={`/profile/${user?.userId}`}>Click here</Link> to update your profile
          </TextBlock>
          <Button
            className={styles.cvDownloadBtn}
            size={'small'}
            onClick={() => history.push(`/profile/${user?.userId}`)}>
            CV Builder Available
          </Button>
        </Space>
        <Space direction={'vertical'} size={0} style={{ width: '100%' }}>
          <div className={styles.refferalLeaderboard}>
            <TextBlock align={'center'} heading>
              Referral Points
              <br />
              Leaderboard
            </TextBlock>
          </div>
          <div className={styles.leaderboardItemContainer}>
            {leaderBoard &&
              leaderBoard.map((item) => {
                console.log({ item });
                return (
                  <LeaderBoardItem
                    key={item.position}
                    position={item.position}
                    heart={user?.userId === item.userId ?? false}
                    points={item.points}
                    picture={item.picture}
                    name={item.firstName}
                  />
                );
              })}
            {position > 3 && (
              <>
                {position > 4 ? (
                  <>
                    <div className={styles.leaderEmpty}>
                      <div className={styles.leaderEmptyDot} />
                      <div className={styles.leaderEmptyDot} />
                      <div className={styles.leaderEmptyDot} />
                    </div>
                    <LeaderBoardItem
                      position={position}
                      heart={true}
                      points={points ?? 0}
                      picture={picture ?? ''}
                      name={name?.split(' ')[0]}
                    />
                  </>
                ) : (
                  <LeaderBoardItem
                    position={position}
                    heart={true}
                    points={points ?? 0}
                    picture={picture ?? ''}
                    name={name?.split(' ')[0]}
                  />
                )}
              </>
            )}

            <div className={styles.leaderboardItemButton}>
              {/*<Button*/}
              {/*  size={'small'}*/}
              {/*  onClick={() =>*/}
              {/*    Router.push({*/}
              {/*      pathname: '/leaderboard',*/}
              {/*    })*/}
              {/*  }>*/}
              {/*  View Details*/}
              {/*</Button>*/}
              <Button
                className={styles.referBtn}
                type={'primary'}
                onClick={() => history.push({ pathname: '/leaderboard' })}
                icon={<i className="icon-gift-outline mr-2 mt-[-4px] text-[#F9CC41] text-xl" />}
                id={'my-dashboard-btn-refer-win'}>
                Refer & Win
              </Button>
              <TextSpan className={styles.leaderboardItemTxt}>
                Help a friend and win amazing prizes!
              </TextSpan>
            </div>
          </div>
        </Space>
      </Space>
      {/*<div className={styles.sideProfileCard}>*/}
      {/*  <Text>Candidate Profile Summery</Text>*/}
      {/*  <Title level={3}>{user?.name ?? 'Kylie Jenner'}</Title>*/}
      {/*  <Avatar className={styles.profileAvatar} size={108} src={picture ?? undefined} />*/}
      {/*  <Text>Profile Strenght</Text>*/}
      {/*  <div className={styles.profileStrength}>*/}
      {/*    <ProfileStrength value={profileCompletion} />*/}
      {/*  </div>*/}
      {/*  <div className={styles.strengthText}>*/}
      {/*    <Text>*/}
      {/*      Your profile is{' '}*/}
      {/*      <span className={styles.profileCompletionValue}>{profileCompletion}%</span> complete{' '}*/}
      {/*      <Link*/}
      {/*        onClick={() => {*/}
      {/*          history.push(`/profile/${user?.userId}`);*/}
      {/*        }}*/}
      {/*        title={'Click here'}>*/}
      {/*        Click here*/}
      {/*      </Link>{' '}*/}
      {/*      {''}*/}
      {/*      to update your profile*/}
      {/*    </Text>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={styles.refferalLeaderboard}>*/}
      {/*  <Title level={3}>*/}
      {/*    Referral Points*/}
      {/*    <br /> Leaderboard*/}
      {/*  </Title>*/}
      {/*</div>*/}
      {/*<div className={styles.leaderboardItemContainer}>*/}
      {/*  {leaderBoard &&*/}
      {/*    leaderBoard.map((item) => {*/}
      {/*      console.log({ item });*/}
      {/*      return (*/}
      {/*        <LeaderBoardItem*/}
      {/*          key={item.position}*/}
      {/*          position={item.position}*/}
      {/*          heart={item.user}*/}
      {/*          points={item.points}*/}
      {/*          picture={item.picture}*/}
      {/*          name={item.name}*/}
      {/*        />*/}
      {/*      );*/}
      {/*    })}*/}
      {/*  {position > 4 && <div className={styles.leaderEmpty} />}*/}
      {/*  <LeaderBoardItem*/}
      {/*    position={position}*/}
      {/*    heart={true}*/}
      {/*    points={points ?? 0}*/}
      {/*    picture={picture ?? ''}*/}
      {/*    name={name}*/}
      {/*  />*/}
      {/*  <div className={styles.leaderboardItemButton}>*/}
      {/*    <Button size={'small'}>View Details</Button>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
