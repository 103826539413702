import React, { FC } from 'react';
import { Button } from 'antd';
import styles from './BlogView.module.less';
import Image from 'next/image';

type Item = {
  link: string;
  blogImage?: string;
  blogId: string;
  name: string;
  status: string;
  description?: string;
};
type Props = {
  items: Item[];
  className?: string;
};

export const BlogView: FC<Props> = (props) => {
  const { items } = props;
  // const Datas = items.map((item)=> {if(item.status == 'Active')return item })
  console.log(items);
  return (
    <div className={styles.blogImgContainer}>
      <div className={styles.blogItemContainer}>
        {items
          ?.reverse()
          .slice(0, 3)
          .map((item, index) => {
            // {item.status === 'Active' ? index : index=index-1 }

            return (
              index < 3 && (
                <div key={item.blogId}>
                  <div className={styles.blogItem}>
                    <div className={styles.blogimage}>
                      <a href={`//${item.link}`} rel="noreferrer" target="_blank">
                        {' '}
                        <Image
                          width={100}
                          height={100}
                          unoptimized
                          src={item.blogImage ?? ''}
                          alt={item.name}
                        />{' '}
                      </a>
                    </div>
                    <div className={styles.blogItemText}>
                      <div className={styles.blogTitleContainer}>{item.name}</div>
                      <div className={styles.blogDescriptionContainer}>
                        {item.description ?? ''}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </div>

      <div className={styles.linkbutton}>
        <Button type="link" block>
          <u>
            <a href={'https://blog.careers360.lk/'}>View Old Posts</a>
          </u>
        </Button>
      </div>
    </div>
  );
};
