import React, { FC, PropsWithChildren, useContext } from 'react';
import { Recruiter } from '../../models/RecruiterModals';
import { Spin } from 'antd';
import ApplicationContext from '../../connection/ApplicationContext';

type RecruiterContextData = {
  recruiter?: Recruiter;
  recruiterToken?: string;
  availablePermissions: string[];
  jobRecruiter?: string[];
  setRecruiter: (value: Recruiter) => void;
  setRecruiterToken: (value: string) => void;
};

const RecruiterContext = React.createContext<RecruiterContextData>({} as any);
export default RecruiterContext;
export const useRecruiter = () => {
  return useContext(ApplicationContext).recruiterCtx!;
};
export const useRecruiterCache = () => {
  return useContext(ApplicationContext).recruiterCache!;
};

export const RecruiterContextProvider: FC<PropsWithChildren> = (props) => {
  const recruiter = useRecruiter();
  if (!recruiter || recruiter.loading) {
    return (
      <Spin spinning={true}>
        <div style={{ width: '100vw', minHeight: '100vh' }} />
      </Spin>
    );
  }
  return <>{props.children}</>;
};
