import React, {
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
  useEffect,
  useState,
} from 'react';
import { DownloadOutlined, FallOutlined } from '@ant-design/icons';
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal';
import { TextBlock, TextSpan } from '../../uicomp/atmos/content-block/ContentBlock';
import Form, { SchemaFormItems } from '../form/Form';
import { Modal, Radio, Space, Alert, Spin } from 'antd';
import { useConnection, useUserInfo } from '../../connection/Application';

import style from '../Applicant/Job/Apply/JobApplyModalEx.module.less';
import { UserProfile } from '../../models/UserModels';
import moment from 'moment';
import FileUploadEx from '../FileUpload/FileUpload';
import Utils from '../../utils/Utils';

export type QuickProfileBuildInstance = {
  show: () => void;
};

type Props = any;

const QuickProfileBuildModal: ForwardRefRenderFunction<QuickProfileBuildInstance, Props> = (
  props,
  ref,
) => {
  type JobApplyCvUse = 'linkedinurl' | 'existing' | 'upload' | 'none' | undefined;
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contextloading, setContextloading] = useState<boolean>(false);
  const [cvUse, setCvUse] = useState<JobApplyCvUse>('linkedinurl');
  const [cvUrl, setCvUrl] = useState<string | undefined>('');
  const [uploadedCv, setUploadedCv] = useState<string | undefined>(undefined);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);
  //if linked in is selected
  const user = useUserInfo();
  const [isLinkedInValid, setIsLinkedInValid] = useState<boolean>(user?.linkedInUrl ? true : false);

  const [form] = Form.useForm();
  const connection = useConnection();

  useEffect(() => {
    if (cvUse === 'upload') {
      setUploadedCv(cvUrl);
    }
  }, [cvUrl, cvUse]);
  useEffect(() => {
    if (user?.linkedInUrl) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?((www|\\w\\w)\\.)?linkedin.com\\/((in\\/[^/]+/?)|(pub\\/[^\\/]+/((\\w|\\d)+\\/?){3}))$',
        'i',
      );
      if (user?.linkedInUrl?.match(pattern)) {
        setCvUrl('');
        setCvUse('linkedinurl');
        setError(undefined);
        setIsLinkedInValid(true);
      }
    }
  }, [visible, user?.linkedInUrl]);

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        form.resetFields();
        setVisible(true);
        setLoading(false);
      },
    }),
    [form],
  );
  useEffect(() => {
    setContextloading(true);
    connection
      .get(`profiles/${user?.userId}`)
      .then((res) => {
        setProfile(res.profile);
      })
      .finally(() => setContextloading(false));
  }, [connection, user?.userId, visible]);

  const submit = useCallback(
    (value: any) => {
      console.log('valuesubmitw', value);
      if (!value.linkedInUrl && !value.quickcvUrl) {
        console.log('empty fieds');
        setError('Please add your resume');
        return;
      }
      setLoading(true);
      connection
        .post('profiles/quick', value)
        .then(() => {
          connection.refreshToken();
          Modal.success({
            title: 'Quick Profile Build',
            content: 'Your profile will be updated and notified as soon as possible.',
          });
        })
        .finally(() => {
          setLoading(false);
          setVisible(false);
        });
    },
    [connection],
  );

  return (
    <ResponsiveModal
      title={'Quick Profile Build'}
      open={visible}
      onOk={() => {
        const formData = form.getFieldsValue();
        console.log('formData', formData);
        if (cvUse == 'linkedinurl') {
          if (formData.linkedInUrl && !cvUrl) {
            submit({ linkedInUrl: formData.linkedInUrl });
            return;
          }
          form.submit();
        }

        if (cvUse == 'existing' && !cvUrl) {
          setError('Please add your resume');
          return;
        }
        if (cvUse == 'existing' && cvUrl) {
          submit({ quickcvUrl: cvUrl });
          return;
        }
        if (cvUse == 'upload' && !cvUrl) {
          setError('Please add your resume');
          return;
        }
        if (cvUse == 'upload' && cvUrl) {
          submit({ quickcvUrl: cvUrl });
          return;
        }

        if (!formData.linkedInUrl && !cvUrl) {
          form.submit();
          console.log('144');
          setError('Please add your resume');
          return;
        }

        if (!formData.linkedInUrl && cvUrl) {
          // console.log('using resume')
          submit({ quickcvUrl: cvUrl });
          return;
        }
      }}
      confirmLoading={loading}
      okText={'Submit Now'}
      cancelText={'Cancel'}
      onCancel={() => {
        setVisible(false), setError(undefined);
      }}>
      <Spin spinning={contextloading}>
        <Space size={20} className={'fullwidth'} direction={'vertical'}>
          {/* {user?.linkedInUrl && (
          <>
            <TextBlock>
              Hey! We noticed you have included your <TextSpan primary>LinkedIn profile</TextSpan>.
            </TextBlock>
            <TextBlock>
              We can complete your <TextSpan primary>Careers360 profile</TextSpan> using your
              LinkedIn profile. Interested?
            </TextBlock>
          </>
        )} */}
          <>
            <TextBlock>
              Share your <TextSpan primary>LinkedIn profile or Resume</TextSpan> with us and we will
              do the work for you!
            </TextBlock>
            <Form.Form
              form={form}
              layout={'vertical'}
              onFinish={submit}
              initialValues={{ linkedInUrl: user?.linkedInUrl }}>
              <SchemaFormItems
                schema={[
                  {
                    id: 'linkedInUrl',
                    name: 'LinkedIn Url',
                    type: 'url',
                    props: {
                      linkedin: true,
                      placeholder: 'linkedin',
                      // defaultValue: user && user.linkedInUrl ? user.linkedInUrl : '',
                      onChange: (e: any) => {
                        if (e.target.value === '') {
                          setIsLinkedInValid(false);
                        }
                      },
                      disabled: cvUrl !== '',
                    },
                    options: {
                      rules: [
                        { required: cvUrl === '', message: 'Please enter Linkedin URL' },
                        {
                          validator: async (_a: any, value: string) => {
                            if (value === null || value === '' || value === undefined) {
                              return;
                            }
                            const pattern = new RegExp(
                              '^(https?:\\/\\/)?((www|\\w\\w)\\.)?linkedin.com\\/((in\\/[^/]+/?)|(pub\\/[^\\/]+/((\\w|\\d)+\\/?){3}))$',
                              'i',
                            );

                            if (!value.match(pattern)) {
                              throw Error('Invalid Linkedin URL');
                            } else {
                              setCvUrl('');
                              setCvUse('linkedinurl');
                              setError(undefined);
                              setIsLinkedInValid(true);
                            }
                          },
                        },
                      ],
                    },
                  },
                ]}
              />
              <h3 style={{ textAlign: 'center' }}>OR</h3>
              <div className={style.job_apply_cv}>
                <h3 style={{ fontSize: '14px', fontWeight: 600, marginBottom: '10px' }}>
                  {!isLinkedInValid && <span className={style.requiredastrix}>*</span>} Add Resume
                </h3>

                <div
                  className={`${
                    cvUse === 'existing' ? style.job_apply_cv_selected : ''
                  } flex flex-col gap-2 fullwidth`}
                  style={{
                    ...(isLinkedInValid ? { cursor: 'not-allowed', opacity: 0.6 } : {}),
                    ...(error ? { borderColor: '#ff4d4f', boxShadow: 'none' } : {}),
                  }}>
                  <h3>Existing CV</h3>
                  {profile?.resumes?.length
                    ? ''
                    : 'You have not uploaded a CV yet. You can upload one on your profile page and use it later.'}
                  {profile?.resumes?.map((cv: any, index) => (
                    <div key={index} className="flex gap-2 fullwidth">
                      <div className="flex grow gap-2 shrink overflow-hidden text-ellipsis">
                        <Radio
                          className="shrink grow overflow-hidden break-words"
                          checked={cvUse === 'existing' && cvUrl === cv.url}
                          onClick={() => {
                            if (!isLinkedInValid) {
                              if (cvUrl === '') {
                                setCvUse('existing');
                                setError(undefined);
                              } else {
                                setCvUrl('');
                                setError(undefined);
                              }
                            }
                          }}
                          onChange={() => {
                            if (!isLinkedInValid) {
                              setCvUse('existing');
                              setCvUrl(cv.url);
                              setError(undefined);
                            }
                          }}>
                          {cv.url.split('/').slice(-1)[0]}
                        </Radio>
                        <div className="shrink-0 grow-0">
                          <i>({moment(new Date(+cv.time * 1000)).format('MMM DD yyyy')})</i>
                        </div>
                      </div>
                      <a className="shrink-0 grow-0" target="_blank" href={cv.url} rel="noreferrer">
                        <DownloadOutlined />
                        <span className="ml-2 invisible hidden sm:visible sm:inline-block">
                          Download
                        </span>
                      </a>
                    </div>
                  ))}
                  {profile?.resumes?.length === 0 && (
                    <div>
                      You have not uploaded any cv yet. You can upload multiple cvs in your profile
                      page and use it later when applying for jobs.
                    </div>
                  )}
                </div>
                <div
                  className={`${cvUse === 'upload' ? style.job_apply_cv_selected : ''}`}
                  style={{
                    ...(isLinkedInValid ? { cursor: 'not-allowed', opacity: 0.6 } : {}),
                    ...(error ? { borderColor: '#ff4d4f', boxShadow: 'none' } : {}),
                  }}>
                  <h3>Upload CV</h3>
                  <Radio
                    checked={cvUse === 'upload'}
                    onClick={() => {
                      if (!isLinkedInValid) {
                        if (cvUrl === '') {
                          setCvUse('upload');
                          setError(undefined);
                        } else {
                          setCvUse('upload');
                          setCvUrl('');
                          setError(undefined);
                        }
                      }
                    }}
                    onChange={() => {
                      if (!isLinkedInValid) {
                        setCvUse('upload');
                        uploadedCv ? setCvUrl(uploadedCv) : '';
                        setError(undefined);
                      }
                    }}>
                    Use uploaded CV
                  </Radio>
                  {uploadedCv && (
                    <div
                      className={Utils.mergeClasses(
                        style.job_apply_cv_existing_row,
                        style.job_apply_cv_uploaded_row,
                      )}>
                      <span>{uploadedCv.split('/').slice(-1)[0]}</span>
                      <div className={style.spacer} />
                      <a target="_blank" href={uploadedCv} rel="noreferrer">
                        <DownloadOutlined />
                        Download
                      </a>
                    </div>
                  )}
                  {cvUse === 'upload' && (
                    <FileUploadEx
                      type={'drag'}
                      onChange={(url: any) => {
                        setUploadedCv(url);
                        setCvUrl(url);
                        setError(undefined);
                      }}
                      accept={Utils.CommonDocumentFileFormats}
                      acceptSize={2.5}>
                      <p>Click here or drag and drop your CV</p>
                      <p>Only .doc, .docx, .pdf, .txt, .odt and .rtf accepted.</p>
                    </FileUploadEx>
                  )}
                </div>
                {/* font-family: 'Roboto',sans-serif; 
              font-size: 16px;
    line-height: 1.5;
    color: #ff4d4f; */}
                {error && (
                  <span
                    style={{
                      fontFamily: "Roboto',sans-serif",
                      fontSize: '16px',
                      lineHeight: 1.5,
                      color: ' #ff4d4f',
                    }}>
                    {error}
                  </span>
                )}
              </div>
            </Form.Form>
          </>
          {/* )} */}
        </Space>
      </Spin>
    </ResponsiveModal>
  );
};

export const useQuickProfileBuildModal = () => useRef<QuickProfileBuildInstance>(null);

export default React.forwardRef<QuickProfileBuildInstance, Props>(QuickProfileBuildModal);
