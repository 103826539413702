import React, { CSSProperties, FC } from 'react';
import Image from 'next/image';
import { Text } from '../Typogrphy/Text';

type Props = {
  content: React.ReactNode;
  image?: any;
  style?: CSSProperties;
  icon?: any;
  alignIcon?: string;
  textStyle?: CSSProperties;
};

export const TextWithImage: FC<Props> = (props) => {
  return (
    <div style={(props.style, { display: 'flex', flexDirection: 'row' })}>
      <div style={{ display: 'flex', alignItems: props.alignIcon }}>
        {props.icon && props.icon}
        {props.image && <Image alt="Text with image" src={props.image} width={20} />}
      </div>
      <div style={{ paddingLeft: 20 }}>
        <Text style={props.textStyle}>{props.content}</Text>
      </div>
    </div>
  );
};
