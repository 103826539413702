import React, { FC, useMemo, useRef } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Button, Grid, Tooltip } from 'antd';
import Utils from '../../../utils/Utils';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Com1 from '../../../public/company.png';
import Image from 'next/image';
import Link from 'next/link';
import stylesModule from '../LandingPage.module.less';
import { Heading } from '../../../components/Heading/Heading';

const styles: any = stylesModule;

type TopHiring = {
  name: string;
  logo: string;
  id: string;
  count: number;
};

type Props = {
  companies: TopHiring[];
};

export const TopHiringCompanies: FC<Props> = (props) => {
  const swipe = useRef<SwiperRef>(null);
  const bp = Grid.useBreakpoint();
  const companies = useMemo(() => {
    const ret: { first: TopHiring; second?: TopHiring }[] = [];
    for (let i = 0; i < props.companies.length; i += 2) {
      ret.push({
        first: props.companies[i],
        second: i + 1 < props.companies.length ? props.companies[i + 1] : undefined,
      });
    }
    return ret;
  }, [props.companies]);
  return (
    <section className="mb-10 mt-10 px-5 xl:px-0">
      <Heading variant={2} className="text-center mb-10">
        <span className="text-primary">Top Companies</span> Hiring Now
      </Heading>
      <div className="relative">
        <Swiper
          className={styles.swiperOverride}
          ref={swipe}
          // install Swiper modules
          modules={[Pagination, Autoplay, A11y]}
          spaceBetween={40}
          slidesPerView={'auto'}
          autoplay={{ delay: 5000 }}
          // loop={true}
          slidesPerGroup={1}
          pagination={{ clickable: true, dynamicMainBullets: 1, dynamicBullets: true }}
          // scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}>
          {companies.map((job) => (
            <SwiperSlide className="max-w-[108px] px-8 md:px-0 lg:px-0" key={job.first.id}>
              <div className="flex flex-col gap-[40px] h-[310px] xl:h-[264px]">
                <Link
                  href={`/recruiters/${Utils.normalizeURIComponent(job.first.name)}/${
                    job.first.id
                  }`}>
                  <Tooltip title={job.first.name}>
                    <div className="w-[108px] h-[108px] p-[8px] border border-slate-200 border-solid rounded-[10px]">
                      <Image src={job.first.logo ?? Com1} alt={'logo'} width={92} height={92} />
                    </div>
                  </Tooltip>
                </Link>
                {job.second && (
                  <Link
                    href={`/recruiters/${Utils.normalizeURIComponent(job.second.name)}/${
                      job.second.id
                    }`}>
                    <Tooltip title={job.second.name}>
                      <div className="w-[108px] h-[108px] p-[8px] rounded-[10px] border border-slate-200 border-solid">
                        <Image src={job.second.logo ?? Com1} alt={'logo'} width={92} height={92} />
                      </div>
                    </Tooltip>
                  </Link>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {bp.xl && (
          <div>
            <Button
              shape="circle"
              size={bp.xl ? 'middle' : 'small'}
              className="border-primary text-primary bg-white absolute top-[108px] xl:top-[100px] left-[-15px] xl:left-[-35px] 2xl:left-[-58px] z-10"
              onClick={() => swipe.current?.swiper.slidePrev()}>
              <ArrowLeftOutlined />
            </Button>
            <Button
              shape="circle"
              size={bp.xl ? 'middle' : 'small'}
              className="border-primary text-primary bg-white absolute top-[108px] xl:top-[100px] right-[-15px] xl:right-[-35px] 2xl:right-[-58px] z-10"
              onClick={() => swipe.current?.swiper.slideNext()}>
              <ArrowRightOutlined />
            </Button>
          </div>
        )}
      </div>
      <div className="justify-center p-4 sm:p-8  flex">
        <Link href={`/recruiters`} className="text-base leading-6 font-medium hover:underline">
          View All Companies &nbsp;
          <ArrowRightOutlined className=" ml-10group-hover:duration-500 group-hover:ml-2" />
        </Link>
      </div>
    </section>
  );
};
