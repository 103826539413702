import React, { FC, useMemo } from 'react';
import styles from './typography.module.less';
import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';

const { Title: AntdTitle } = Typography;

const levelClasses = [undefined, styles.titleLevel1, styles.titleLevel2, styles.titleLevel3];

export const Title: FC<TitleProps> = (props) => {
  const cname = useMemo(() => {
    const levelClass = levelClasses[props.level ?? 1];
    const val = `${props.className ?? ''} ${levelClass ?? ''}`.trim();
    return val === '' ? undefined : val;
  }, [props.className, props.level]);
  return (
    <AntdTitle {...props} className={cname}>
      {props.children}
    </AntdTitle>
  );
};
