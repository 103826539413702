import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import SchemaForm, { Form, FormSchema, TypeAdapter } from '../form/Form';
import { ResponsiveModal } from '../ResponsiveModal/ResponsiveModal';
import { ColProps } from 'antd/lib/grid/col';
import { ButtonProps } from 'antd/lib/button/button';
import { notification } from 'antd';

export type FormSchemaArray = { array: FormSchema[] };

export interface FormModalExProps {
  schema?: FormSchema | FormSchemaArray;
  layout?: 'inline' | 'horizontal' | 'vertical';
  adapters?: TypeAdapter[];
  cancelButtonProps?: ButtonProps;
  okButtonProps?: ButtonProps;
  okText?: string;
  cancelText?: string;
  width?: string;
  labelCol?: ColProps;
  showErrorPopup?: boolean;
  banner?: string;
  className?: string;
  footer?: (loading: boolean) => ReactNode;
}

class FormModalExCallback {
  saveCallback?: (value: any) => void;

  cancelCallback?: () => void;

  onSave = (callback: (value: any) => void): FormModalExCallback => {
    this.saveCallback = callback;
    return this;
  };

  onCancel = (callback: () => void): FormModalExCallback => {
    this.cancelCallback = callback;
    return this;
  };
}

export type FormModalExRef = {
  edit: (
    title: string | ReactNode,
    value: any,
    schema?: FormSchema | FormSchemaArray,
  ) => FormModalExCallback;
  create: (
    title: string | ReactNode,
    initialValue?: any,
    schema?: FormSchema | FormSchemaArray,
  ) => FormModalExCallback;
  submit: () => void;
  close: () => void;
  stopLoading: () => void;
  setErrors: (errors: any) => void;
};

// eslint-disable-next-line react/display-name
const FormModal = forwardRef<FormModalExRef, FormModalExProps>((props, ref) => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [value, setValue] = useState<any>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string | ReactNode>();
  const [callback, setCallback] = useState<FormModalExCallback>();
  const [schema, setSchema] = useState<FormSchemaArray>();
  // eslint-disable-next-line react/prop-types
  const {
    schema: propSchema,
    adapters,
    cancelButtonProps,
    width,
    okButtonProps,
    showErrorPopup,
    banner,
  } = props;
  const [form] = SchemaForm.useForm();
  useEffect(() => {
    if (propSchema === undefined) {
      setSchema(undefined);
    } else {
      if ((propSchema as any)?.array) {
        setSchema(propSchema as any);
      } else {
        setSchema({ array: [propSchema] as any });
      }
    }
  }, [propSchema]);
  useImperativeHandle(
    ref,
    () => ({
      edit: (
        titlex: string | ReactNode,
        valuex: any,
        schema?: FormSchema | FormSchemaArray,
      ): FormModalExCallback => {
        //setValue(valuex);
        setTitle(titlex);
        setVisible(true);
        setLoading(false);
        form.resetFields();
        const vv = JSON.parse(JSON.stringify(valuex));
        form.setFieldsValue(valuex);
        setTimeout(() => form.setFieldsValue(vv), 1);
        const cb = new FormModalExCallback();
        setCallback(cb);
        if (schema) {
          if ((schema as any).array) {
            setSchema(schema as any);
          } else {
            setSchema({ array: [schema] as any });
          }
        }
        return cb;
      },
      stopLoading: () => setLoading(false),
      create: (
        titlex: string | ReactNode,
        initialValue?: any,
        schema?: FormSchema | FormSchemaArray,
      ): FormModalExCallback => {
        setTitle(titlex);
        setVisible(true);
        setLoading(false);
        //setValue(initialValue);
        form.resetFields();
        setTimeout(() => form.setFieldsValue(initialValue), 1);
        const cb = new FormModalExCallback();
        setCallback(cb);
        if (schema) {
          if ((schema as any).array) {
            setSchema(schema as any);
          } else {
            setSchema({ array: [schema] as any });
          }
        }
        return cb;
      },
      close: () => {
        setVisible(false);
        form.resetFields();
        setCallback(undefined);
        setLoading(false);
      },
      submit: form.submit,
      setErrors: (errors: any) => {
        if (form != null) {
          form.setFields(
            Object.keys(errors).map((key: string) => ({
              name: key,
              errors: [errors[key]],
            })),
          );
        }
        setLoading(false);
      },
    }),
    [form],
  );

  const onOk = useCallback(
    (values: any) => {
      setLoading(true);
      callback?.saveCallback?.(values);
    },
    [callback],
  );

  const onCancel = useCallback(() => {
    setLoading(false);
    if (callback?.cancelCallback) {
      callback?.cancelCallback?.();
    } else {
      setVisible(false);
      form.resetFields();
    }
  }, [callback, form]);
  const onError = useCallback(
    (v) => {
      if (showErrorPopup) {
        const description = [...(v.errorFields ?? [])]
          .map((c) => c.errors[0])
          .map((d) => (
            <>
              {d}
              <br />
            </>
          ));
        notification['error']({
          message: 'Validation Error',
          description,
        });
      }
    },
    [showErrorPopup],
  );
  console.log('FOOTERRR', props.footer);
  return (
    <ResponsiveModal
      title={title || ''}
      open={visible}
      onOk={form.submit}
      onCancel={onCancel}
      okText={props.okText ?? 'Save'}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      cancelText={props.cancelText ?? 'Cancel'}
      confirmLoading={loading}
      destroyOnClose
      maskClosable={!loading}
      footer={props.footer?.(loading)}
      closable={!loading}
      width={width || '600px'}>
      {banner && (
        <div
          style={{
            width: '100%',
            fontSize: '20px',
            fontWeight: 500,
            textAlign: 'center',
            padding: '10px',
            background: '#B7E3CD',
            marginBottom: '20px',
          }}>
          {banner}
        </div>
      )}

      <Form
        className={props.className}
        onFinishFailed={onError}
        labelCol={props.labelCol ?? (props.layout !== 'vertical' ? { span: 6 } : undefined)}
        form={form}
        scrollToFirstError={true}
        layout={'vertical'}
        onFinish={onOk}>
        {schema?.array.map((sch, i) => (
          <SchemaForm.Items
            key={i}
            schema={sch}
            adapters={adapters}
            layout={(props.layout ?? 'vertical') as any}
          />
        ))}
      </Form>
    </ResponsiveModal>
  );
});
export default FormModal;
