import React, { ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react';
import FormModal, { FormModalExRef } from '../../components/formModal/FormModal';
import { FormSchema } from '../../components/form/Form';
import { useConnection } from '../../connection/Application';
import { message } from 'antd';

export type ChangePasswordModalInstance = {
  open: () => void;
};

const schema: FormSchema = [
  {
    id: 'currentPassword',
    type: 'password',
    name: 'Current Password',
    props: { placeholder: 'Enter your current password' },
    options: {
      rules: [{ required: true, message: 'Current password is required' }],
    },
  },
  {
    id: 'password',
    type: 'password',
    name: 'New Password',
    props: { placeholder: 'Enter your new password' },
    options: {
      rules: [
        { required: true, message: 'New password is required' },
        {
          validator: 'passwordPolicy',
          message:
            'Password should contain more than 8 characters and at least 1 upper case character, ' +
            '1 lower case character, 1 number and 1 special character',
        },
      ],
    },
  },
  {
    id: 'confirmPassword',
    type: 'password',
    name: 'Confirm Password',
    props: { placeholder: 'Confirm your Password' },
    options: {
      dependencies: ['password'],
      rules: [
        { required: true, message: 'Please confirm your password.' },
        {
          validator: 'equals',
          to: 'password',
          message: 'Your password and confirm password does not match',
        },
      ],
    },
  },
];

const ChangePasswordModal: ForwardRefRenderFunction<ChangePasswordModalInstance, any> = (
  props,
  ref,
) => {
  const form = useRef<FormModalExRef>(null);
  const connection = useConnection();
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        form.current?.create('Change Password').onSave((value) => {
          const { currentPassword, password: newPassword } = value;
          connection
            .post('auth/password/change', { currentPassword, newPassword })
            .then(() => {
              message.success('Password Changed.');
              form.current?.close();
            })
            .catch(() => {
              form.current?.setErrors({ currentPassword: 'Invalid password' });
              form.current?.stopLoading();
            });
        });
      },
    }),
    [connection],
  );

  return <FormModal ref={form} schema={schema} />;
};

export default React.forwardRef<ChangePasswordModalInstance, any>(ChangePasswordModal);
