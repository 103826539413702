import React, { FC, useMemo, useState } from 'react';
import { Button, Grid, Tabs, Tooltip } from 'antd';
import classNames from 'classnames';
import Link from 'next/link';
import Utils from '../../../utils/Utils';
import { Text } from '../../../components/Typogrphy/Text';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Router from 'next/router';

type PopularSectionItem = {
  id: string;
  name: string;
  count: number;
};
type PopularSectionPanelProps = {
  name: string;
  items: PopularSectionItem[];
};
const PopularSectionPanel: FC<PopularSectionPanelProps> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const bp = Grid.useBreakpoint();
  const showCount = useMemo(() => {
    if (bp.xl) {
      return 15;
    }
    if (bp.md) {
      return 14;
    }
    return 10;
  }, [bp.md, bp.xl]);
  const items = useMemo(() => {
    if (show) {
      return props.items;
    }
    return props.items.slice(0, showCount);
  }, [props.items, show, showCount]);
  return (
    <div className="flex flex-col pt-4">
      <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-5 gap-x-6">
        {items.map((item) => (
          <Tooltip
            key={item.name}
            placement={'topLeft'}
            title={`${item.name} (${Utils.formatNumber(item.count, 0)})`}>
            <></>
            <div className="">
              <Link
                className="text-[18px]"
                href={{
                  // `sectors/${item.name
                  //   .trim()
                  //   .toLowerCase()
                  //   .replace(/[^a-zA-Z0-9\s]/g, '')
                  //   .replace(/\s+/g, '-')
                  //   .replace(/\//g, '-')}/${item.id}`
                  pathname:
                    props.name === 'sectors'
                      ? `sectors/${item.name
                          .trim()
                          .toLowerCase()
                          .replace(/[^a-zA-Z0-9\s]/g, '')
                          .replace(/\s+/g, '-')
                          .replace(/\//g, '-')}-jobs/${item.id}`
                      : '/jobs',
                  query:
                    props.name === 'sectors'
                      ? {}
                      : {
                          // [props.name === 'sectors'
                          //   ? ``
                          //   : props.name === 'employers'
                          //   ? 'company'
                          //   : 'jobFunction']: item.id ?? item.name,
                          // ...(props.name === 'sectors' ? {} : { results: 'show' }),

                          [props.name === 'employers' ? 'company' : 'jobFunction']:
                            item.id ?? item.name,
                          // ...(props.name === 'sectors' ? {} : { results: 'show' }),
                        },
                }}>
                <div className="flex gap-1">
                  <div className="text-[#5B6272] text-base leading-6 text-ellipsis whitespace-nowrap overflow-hidden">
                    {item.name}
                  </div>
                  <div>({Utils.formatNumber(item.count, 0)})</div>
                </div>
              </Link>
            </div>
          </Tooltip>
        ))}
      </div>
      {props.items.length > showCount && (
        <div className="flex justify-center md:justify-end gap-[20px] items-center mt-4">
          {(props.name === 'sectors' || props.name === 'employers') && (
            <Button
              size="small"
              className="border-primary text-[#5E6060]"
              onClick={() =>
                props.name === 'employers'
                  ? Router.push({ pathname: `recruiters` })
                  : props.name === 'sectors'
                  ? Router.push({ pathname: `sectors` })
                  : {}
              }
              style={{ paddingLeft: 16, paddingRight: 16 }}>
              View All
            </Button>
          )}
          <Button type={'text'} onClick={() => setShow(!show)} className={'px-0'}>
            {!show && (
              <p className={'text-[16px] text-[#5E6060] m-0'}>
                Show more <DownOutlined style={{ fontSize: '16px', fontWeight: 600 }} />
              </p>
            )}
            {show && (
              <p className={'text-[16px] text-[#5E6060] m-0'}>
                Show Less <UpOutlined style={{ fontSize: '16px', fontWeight: 600 }} />
              </p>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

type Props = {
  sectors: PopularSectionItem[];
  functions: PopularSectionItem[];
  employers: PopularSectionItem[];
};

export const PopularSection: FC<Props> = (props) => {
  const [activeKey, setActiveKey] = useState<string>('sectors');
  return (
    <section className="text-[#5E6060] lg:mt-6 lg:px-[20px] xl:px-0 py-[40px]">
      <Tabs activeKey={activeKey} onChange={setActiveKey} tabBarGutter={48}>
        <Tabs.TabPane
          tab={
            <span
              className={classNames('text-base leading-6 font-medium', {
                'text-[#5E6060]': activeKey !== 'sectors',
              })}>
              Popular Sectors
            </span>
          }
          key="sectors">
          <PopularSectionPanel items={props.sectors} name={'sectors'} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span
              className={classNames('text-base leading-6 font-medium', {
                'text-[#5E6060]': activeKey !== 'functions',
              })}>
              Popular Function
            </span>
          }
          key="functions">
          <PopularSectionPanel items={props.functions} name={'functions'} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <span
              className={classNames('text-base leading-6 font-medium', {
                'text-[#5E6060]': activeKey !== 'employers',
              })}>
              Popular Employers
            </span>
          }
          key="employers">
          <PopularSectionPanel items={props.employers} name={'employers'} />
        </Tabs.TabPane>
      </Tabs>
    </section>
  );
};
