import React, { FC, PropsWithChildren } from 'react';
import styles from '../RecruiterProfile.module.less';
import { Card } from 'antd';
import { HtmlView } from '../../../components/HtmlTemplate/HtmlView';

type Props = {
  title: string;
  value?: string;
  borderless?: boolean;
  className?: string;
};
const headStyle = { backgroundColor: '#EEEDED' };
export const RichTextDescCard: FC<PropsWithChildren<Props>> = (props) => {
  return (
    <Card
      headStyle={headStyle}
      className={props.className ?? styles.recruiterCard}
      size="small"
      title={props.title}
      bordered={!props.borderless}>
      {props.children ?? (
        <p className={styles.recruiterContent}>{props.value && <HtmlView value={props.value} />}</p>
      )}
    </Card>
  );
};
