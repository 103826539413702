import React, { FC, ReactNode } from 'react';
interface CustomHeadingProps {
  variant: 1 | 2 | 3 | 4;
  children: ReactNode;
  className?: string;
}
export const Heading: FC<CustomHeadingProps> = ({ variant, children, className }) => {
  let textStyle = '';

  switch (variant) {
    case 1:
      textStyle =
        'text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-5xl leading-9 font-semibold m-0 my-3';
      break;
    case 2:
      textStyle =
        'text-3xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl leading-9 font-medium m-0 my-2';
      break;
    case 3:
      textStyle =
        'text-xl sm:text-lg md:text-xl lg:text-2xl xl:text-3xl leading-9 font-semibold m-0 my-1';
      break;
    case 4:
      textStyle = 'text-4xl leading-10 font-medium';
      break;
    default:
      textStyle = '';
      break;
  }
  return <h1 className={`${textStyle} ${className ?? ''}`}>{children}</h1>;
};
