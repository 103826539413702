import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Grid } from 'antd';
import { CandidateNavBar } from './CandidateNavBar';
import styles from './CandidateLayout.module.less';
import { CandidateFooter } from './CandidateFooter';
import Utils from '../../utils/Utils';
import { NextSeo } from 'next-seo';
import Config from '../../config';
import { useUserInfo } from '../../connection/Application';
import dynamic from 'next/dynamic';

// const CandidateNavBar = dynamic(
//   () => import('./CandidateNavBar').then((value) => value.CandidateNavBar),
//   { ssr: false },
// );

type Props = {
  title: string | React.ReactNode;
  showSearch?: boolean;
  showBanner?: boolean;
  hideRecruiterButton?: boolean;
  navbarAddon?: React.ReactNode;
  className?: string;
};

export const DEFAULT_OG_IMAGES = [
  {
    url: `${Config.fileStoreUrl}/files/images/og/site.jpeg`,
    width: 1200,
    height: 630,
  },
];

export const SEO_COMMON = {
  twitter: {
    site: '@Careers360LK',
    cardType: 'summary_large_image',
  },
};

export const CandidateLayout: FC<PropsWithChildren<Props>> = (props) => {
  const user = useUserInfo();
  const [showLogin, setShowlogin] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(0);
  const bp = Grid.useBreakpoint();

  useEffect(() => {
    const handleScroll = () => {
      setPosition(window.pageYOffset);
      if (window.pageYOffset > 20 && showLogin) {
        setShowlogin(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showLogin]);

  // useEffect(() => {
  //   setShowBanner(props.showBanner ?? false);
  // }, [props.showBanner]);

  useEffect(() => {
    setShowlogin(!user);
  }, [user]);

  const onLoginShowHide = useCallback(() => {
    setShowlogin(!showLogin);
  }, [showLogin]);

  return (
    <div>
      {/*<Head>*/}
      {/*  <title>{props.title}</title>*/}
      {/*</Head>*/}
      {typeof props.title === 'string' ? (
        <NextSeo
          title={props.title}
          description="Careers360 is the most popular job portal for jobs in Sri Lanka, and to find out the best top job vacancies in Sri Lanka to your career next growth step"
          additionalMetaTags={[{ name: 'keywords', content: 'Jobs in Sri Lanka | Careers360' }]}
          openGraph={{
            title: `${props.title} | Jobs in Sri Lanka | Job Vacancies in ${Utils.moment().format(
              'YYYY',
            )} | Careers360`,
            description:
              'Careers360 is the most popular job portal for jobs in Sri Lanka, and to find out the best top job vacancies in Sri Lanka to your career next growth step',
            images: DEFAULT_OG_IMAGES,
          }}
          {...SEO_COMMON}
        />
      ) : (
        props.title
      )}
      <main className={styles.main}>
        <CandidateNavBar
          showBanner={props.showBanner}
          showSearch={props.showSearch}
          hideRecruiterButton={props.hideRecruiterButton}
          bordered={position > 0}
          showLogin={showLogin && !user}
          onShowLogin={onLoginShowHide}>
          {props.navbarAddon}
        </CandidateNavBar>
        <article
          className={Utils.mergeClasses({
            [showLogin ? styles.articleX : styles.article]: true,
            [props.className ?? '']: true,
            [styles.articleSearch]: props.showSearch && !showLogin,
            [styles.articleSearchLogin]: props.showSearch && showLogin,
            [styles.articleBanner]: showBanner,
            [styles.showLoginContainer]:
              showLogin && user === undefined && props.navbarAddon === undefined && !bp.lg,
            [styles.showLoginContainerX]:
              showLogin && user === undefined && props.navbarAddon !== undefined,
          })}>
          <div>{props.children}</div>
        </article>
        <CandidateFooter />
      </main>
    </div>
  );
};
